import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Group, Modal, Space, Stack, Text } from '@mantine/core';
import classNames from 'classnames';

import { RESULT_DISPLAY_CATEGORIES, TOAST_TWELVE } from 'constants/results';
import { allCrops } from 'constants/variables';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getAnalysisViewOptions } from 'util/results';
import { getString, useTranslation } from 'strings/translation';
import { ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import { createMapbook } from 'store/operation/requests';
import { Spinner } from 'common';

import styles from './PrintResultsModal.module.css';

interface PrintResultsProps {
  open: boolean;
  onClose: () => void;
  operationId: number;
  showMessage: (message: string, type?: ToastType, timeout?: number) => void;
  selectedYear: number;
  availableFieldIds: number[];
}

const PrintResultsModal = ({
  open,
  onClose,
  operationId,
  showMessage,
  selectedYear,
  availableFieldIds,
}: PrintResultsProps) => {
  const language = useBroswerLanguage();

  const { operation } = useSelector((state: RootState) => ({
    operation: state.operations.operationsById[operationId],
  }));

  // TODO: show Compaction when backend is ready
  const analysisViewOptions = getAnalysisViewOptions(
    language,
    RESULT_DISPLAY_CATEGORIES,
    true,
    true,
  );

  const [isLoadingMapbook, setIsLoadingMapbook] = useState<boolean>(false);
  const [overviewOnly, setOverviewOnly] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);

  const updateSelectedPages = (val: string) => {
    if (selectedPages.includes(val)) {
      setSelectedPages(selectedPages.filter((i) => i !== val));
    } else {
      setSelectedPages([...selectedPages, val]);
    }
  };

  const updateSelectedCrops = (val: string) => {
    if (selectedCrops.includes(val)) {
      setSelectedCrops(selectedCrops.filter((i) => i !== val));
    } else {
      setSelectedCrops([...selectedCrops, val]);
    }
  };

  const updateSelectedFields = (fieldId: number) => {
    if (selectedFieldIds.includes(fieldId)) {
      setSelectedFieldIds(selectedFieldIds.filter((i) => i !== fieldId));
    } else {
      setSelectedFieldIds([...selectedFieldIds, fieldId]);
    }
  };

  const downloadMapbook = async () => {
    try {
      setIsLoadingMapbook(true);
      showMessage(getString('generateMapbookMsg', language), 'success', TOAST_TWELVE);
      await createMapbook(operationId, {
        pages: selectedPages,
        overview_only: overviewOnly,
        field_ids: selectedFieldIds,
        crop_year: selectedYear,
        crops: selectedCrops,
      });
      onClose();
    } catch (e) {
      showMessage(getString('mapbookGenerationFailedMsg', language), 'error', TOAST_TWELVE);
    } finally {
      setIsLoadingMapbook(false);
    }
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={useTranslation('mapbookConfiguration')}
      data-test-id="generate-mapbook-modal-body"
      size="lg"
    >
      <Group justify="center" align="flex-start">
        <Stack align="flex-start" justify="flex-start">
          <div className={styles.BorderGroup}>
            <Checkbox
              checked={overviewOnly}
              onChange={(event) => setOverviewOnly(event.currentTarget.checked)}
              label={<b>{getString('overviewOnly', language)}</b>}
            />
          </div>
          <div className={styles.BorderGroup}>
            {analysisViewOptions.map((viewOption) => (
              <div key={viewOption.value}>
                <Checkbox
                  key={viewOption.value}
                  label={<b>{viewOption.displayName}</b>}
                  checked={selectedPages.includes(viewOption.value)}
                  onChange={() => updateSelectedPages(viewOption.value)}
                />
                <Space h="xs" />
              </div>
            ))}
          </div>
          <div className={styles.BorderGroup}>
            {allCrops.map((crop) => (
              <div key={crop}>
                <Checkbox
                  key={crop}
                  label={<b>{getString(crop, language)}</b>}
                  checked={selectedCrops.includes(crop)}
                  onChange={() => updateSelectedCrops(crop)}
                />
                <Space h="xs" />
              </div>
            ))}
            <Text size="xs" fs="italic">
              *{getString('PDFCropSelectMsg', language)}
            </Text>
          </div>
        </Stack>
        <Stack>
          <div className={classNames(styles.BorderGroup, styles.BorderField)}>
            <Group justify="flex-start" style={{ borderBottom: '1px solid #efefef' }}>
              <Text fw={500}>
                {getString('farm', language)}: {getString('fields', language)}
              </Text>
            </Group>
            <Space h="xs" />
            <div className={styles.FieldScroll}>
              <Text size="xs" fs="italic">
                *{getString('PDFFieldsDisabledMsg', language)}
              </Text>
              <Space h="xs" />
              {operation.fields.map((field) => (
                <div key={field.id}>
                  <Checkbox
                    key={field.id}
                    label={
                      <b>
                        {field.farm_name ? `${field.farm_name}:` : ''} {field.name}
                      </b>
                    }
                    checked={selectedFieldIds.includes(field.id)}
                    onChange={() => updateSelectedFields(field.id)}
                    disabled={!availableFieldIds.includes(field.id)}
                  />
                  <Space h="xs" />
                </div>
              ))}
            </div>
          </div>
        </Stack>
      </Group>
      <Space h="md" />
      <Group justify="flex-end">
        {isLoadingMapbook && <Spinner className={styles.SmallSpinner} />}
        <Button onClick={downloadMapbook}>{getString('generateMapbookResults', language)}</Button>
      </Group>
    </Modal>
  );
};

export default PrintResultsModal;
