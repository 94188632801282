import { COMPANY_SITE_URL, SUPPORT_EMAIL } from 'constants/urls';

const ENGLISH_STRINGS = {
  PDFCropSelectMsg: 'If no crops selected, only the upcoming crop will be shown for each field.',
  PDFFieldsDisabledMsg: 'Fields will be disabled if results do not exist yet.',
  aaAnalysis: 'AA Analysis',
  aaRerun: 'AA Rerun',
  abort: 'Abort',
  aboveGroundOnly: 'Above ground only',
  acAtRisk: 'Ac. at Risk',
  accepted: 'Accepted',
  account: 'Account',
  accounts: 'Accounts',
  accountName: 'Account Name',
  acre: 'Acre',
  acreCommit: 'Acre Commit',
  acres: 'Acres',
  acresPerZone: 'Acres per zone',
  action: 'Action',
  actions: 'Actions',
  activateTillMapper: 'Activate TillMapper for this field',
  activateTotalFarm: 'Activate Total Farm',
  active: 'Active',
  activeAndCompletedPlans: 'Active & Completed Plans',
  activeSamplingPlans: 'Active Sampling Plans (Acres)',
  activeTodayGenerateUnlimitedMsg:
    'Activate today, and generate unlimited scripts and plans as needed',
  activityDetails: 'Activity Details',
  adcp_needed_not_complete: 'ADCP Needed, Not Complete',
  add: 'Add',
  add590: 'Add 590 Analysis',
  addAccount: 'Add account',
  addAddress: 'Add Address',
  addAdjustment: 'Add Adjustment',
  addAdjustments: 'Add Adjustments',
  addAdjustmentsFor: 'Add Adjustment for',
  addAgency: 'Add Agency',
  addAgents: 'Add Agents',
  addAnAgency: 'Please add an agency.',
  addAnalytic: 'Add Analytic',
  addBatch: 'Add Batch',
  addBillingResponsibility: 'Add Billing Responsibility',
  addClus: 'Add CLUs',
  addControl: 'Add Control',
  addEditBoundaries: 'Add / Edit Boundaries',
  addExternalLab: 'Add external lab',
  addFieldInstructions: 'Provide a field name and click "save" to continue.',
  addGate: 'Add gate',
  addLabel: 'Add Label',
  addLocation: 'Add Location',
  addNewImage: 'Add New Image',
  addPlate: 'Add Plate',
  addPlateSuccess: 'Plate Added Successfully!',
  addPriority: 'Add Priority',
  addProduct: 'Add Product',
  addProductChart:
    'Select a product that will provide an adequate level of protection. Adding products to your Product Catalog will display their protection levels here.',
  addProducts: 'Add Products',
  addProductsAbove: 'Add Products Above',
  addReferencesOrResources: 'Add References or Resources',
  addSample: 'Add Sample',
  addSeed: 'Add Seed',
  addSeedChart:
    'Select a seed that will provide an adequate level of protection. Adding seeds to your Product Catalog will display their protection levels here.',
  addTargetYieldMsg: 'Please add a target yield to continue.',
  addZone: 'Add Zone',
  added: 'Added',
  addingBillingResponsibility: 'Adding Billing Responsibility',
  additionalInputSpend: 'Additional Input Spend',
  additionalLab: 'Additional lab',
  addressCreatedMsg: 'Address updated successfully!',
  adjustmentAmount: 'Adjustment Amount',
  adjustmentCreatedSuccessMsg: 'Adjustment created successfully!',
  adjustmentRemovedSuccessMsg: 'Adjustment removed successfully!',
  adjustments: 'Adjustments',
  admin: 'Admin',
  affectAllLabelsMsg: 'This will affect all labels in this group.',
  agencies: 'Agencies',
  agency: 'Agency',
  agencyConnectionAdded: 'Agency connection added successfully!',
  agencyConnectionRemoved: 'Agency connection removed successfully!',
  agencyContractWarningMsg: 'Billing agency contract is expired: orders will be invoiced at MSRP',
  agencyCost: 'Agency Cost',
  agencyDefaults: 'Agency Defaults',
  agencyName: 'Agency Name',
  agencyPrice: 'Agency Price',
  agencyPrimary: 'Agency Primary',
  agencyUpdatedSuccessMsg: 'Agency updated successfully!',
  agency_admin: 'Agency Admin',
  agent: 'Agent',
  agents: 'Agents',
  agentsMustBeAssignedToExisting:
    'Agents must be assigned to an existing Agency. Search for the Agency below. If you want to create a new Agency, you will need to assign the role of Agency Admin to this user',
  alertDoubleSamplePrinting:
    'A label for this sample has already been printed! Please make sure that you are working with the correct sample. There should not be a reason to print a second label unless the first one got damaged or did not print correctly! Are you sure you want to print this label again?',
  alertDoubleSamplePrintingTitle: 'Label Already Printed',
  alerts: 'Alerts',
  alertsAndNotReady: 'Alerts + Not Ready',
  alertsAndReady: 'Alerts + Ready',
  all: 'All',
  allAccounts: 'All Accounts',
  allAnalyses: 'All Analyses',
  allCatalogs: 'All Catalogs',
  allExtractions: 'All Extractions',
  allInputs: 'All Inputs',
  allPipelines: 'All Pipelines',
  allProjects: 'All projects',
  allResults: 'All Results',
  allRiskLevels: 'All Risk Levels',
  allStatuses: 'All Statuses',
  allTypes: 'All Types',
  allZones: 'All Zones',
  all_assignments: 'All Assignments',
  all_plans: 'All Plans',
  all_pro_or_till_rx: 'All Pro or TillMapper',
  all_readiness: 'All Readiness',
  all_statuses: 'All Statuses',
  allowMultiplePlans: 'Allow Multiple Open Plans Per Field',
  allowedValues: 'Allowed Values',
  ammoniumAcetate: 'Ammonium Acetate',
  ammoniumAcetateAnalysis: 'Ammonium Acetate Analysis',
  amount: 'Amount',
  amplicon: 'Amplicon',
  analysis: 'Analysis',
  analysis590Description:
    'This will include additional analysis in support of 590 Manure Management plans',
  analysis590Required:
    'Warning: 590 Analysis Required - Process through dry / grind to support downstream analysis',
  analysis590Selected: '590 Analysis Selected',
  analysis590Title: '590 Manure Management Analysis',
  analysisAndSampling: 'Analysis & Sampling',
  analysisBundles: 'Analysis Bundles',
  analysisOptions: 'Analysis Options',
  analysisOptionsMsg: 'Analysis options updated successfully!',
  analysisOverYear: 'Analysis over the next year',
  analysisPackage: 'Analysis Package',
  analysisStandalone: 'Analysis Standalone',
  analytic: 'Analytic',
  analyticAttributes: 'Analytic Attributes',
  analyticDetails: 'Analytic Details',
  analyticImagery: 'Analytic Imagery',
  analyticName: 'Analytic Name',
  analyticOrderUpdated: 'Analytic order updated!',
  analyticRiskMap: 'Analytic Risk Map',
  analyticTaxonomies: 'Analytic Taxonomies',
  analytics: 'Analytics',
  applicationRate: 'Application Rate',
  applied: 'Applied',
  appliedFieldAverage: 'Applied Field Average',
  applyDueDateToSamplingPlanConfirmation:
    'Confirm that all samples in the sampling plan should set the following due date',
  applyPriorityToSamplingPlanConfirmation:
    'Confirm that all samples in the sampling plan should have the priority set to',
  applyRecommendedInputs:
    "Apply recommended inputs based on Pattern 360 report on 'at risk' fields",
  applyToSamplingPlan: 'Apply to whole sampling plan',
  approveAnyway: 'Approve anyway',
  approved: 'Approved',
  approvedBy: 'Approved By',
  approveOpenTransactions: 'Approve Open Transactions',
  approveTransaction: 'Approve Transaction',
  areYouSure: 'Are you sure',
  arrived: 'Arrived',
  arrivedLab: 'Arrived Lab',
  assign: 'Assign',
  assignAllPlans: 'Assign all open sample plans on this account to this sampler',
  assignSamplePlan: 'Assign Sample Plan',
  assignSampler: 'Assign Sampler',
  assignSamplerError: 'There was an error assigning the sampler. Please refresh and try again.',
  assignSamplerNoBilling: 'Please assign a billing responsibility before the field can be sampled.',
  assignSamplerPar: 'Select the Sampler you would like to assign to this sample plan',
  assignSamplersSuccessMsg: 'Samplers/Scanners assigned successfully!',
  assignTo: 'Assign to ',
  assignToCollection: 'Assign to collection',
  assignUserToAgency: 'Assign User to Agency',
  assigned: 'Assigned',
  assignedBillingMsg: `The user assigned billing responsibility has not signed their agreement with EarthOptics. They can access and sign the agreement by logging in to their account at ${COMPANY_SITE_URL}. You will be able to create sample plans for this account, but will not be able collect samples in field until the agreement has been signed, or an alternative user is added for billing responsibility.`,
  assigned_not_accepted: 'Assigned, Not Accepted',
  assignment_accepted: 'Assignment Accepted',
  assignment_declined: 'Assignment Declined',
  assignment_not_accepted: 'Assignment Not Accepted',
  authFailed: 'Authentication Failed',
  autoSubmit: 'Auto-Submit',
  average: 'Average',
  averageAppliedToZone: 'Field average applied to zone',
  averageCompaction: 'Average Compaction',
  averageCompactionTooltip: 'Full Field Average PSI, 0-12"',
  averagePopulation: 'Average Population',
  avg: 'Avg',
  avgRate: 'Avg Rate',
  awaitingResults: 'Awaiting Results',
  awaitingWellInpuMsg: 'Awaiting Input For Well',
  back: 'Back',
  backToAllRx: 'Back to all Rx',
  backToAnalytics: 'Back to Analytics',
  backToFieldSummary: 'Back To Field Summary',
  backToList: 'Back To List',
  backToResults: 'Back to Results',
  backToTop: 'Back to top',
  backup: 'Backup',
  balance: 'Balance',
  balanceDueFromCustomer: 'Balance due from customer',
  barcode: 'Barcode',
  barcodes: 'Barcodes',
  basePackage: 'Base Package',
  baseSaturation: 'Base Saturation',
  baseTreatment: 'Base Treatment',
  batch: 'Batch',
  batchOrder: 'Batch Order',
  batchPoolStatus: 'Batch/Pool Status',
  batchSummary: 'Batch Summary',
  batched: 'Batched',
  batches: 'Batches',
  bcsr: 'BCSR',
  bdCoarseDryMass: 'BD Coarse Dry Mass',
  bdFineDryMass: 'BD Fine Dry Mass',
  bdTotalDryMass: 'BD Total Dry Mass',
  belowForMappedFields: ' below for any of your mapped fields:',
  benchmark: 'Benchmark',
  beneficialBioMarkers: 'Beneficial Biomarkers (higher is better)',
  benefits: 'Benefits',
  billing: 'Billing',
  billingAddress: 'Billing Address',
  billingAddressMissingMsg: 'Please fill out all billing address fields.',
  billingAddressRequired: 'Billing address required.',
  billingAgency: 'Billing Agency',
  billingInfoUpdated: 'Billing information updated!',
  billingResponsibility: 'Billing Responsibility',
  bioactive: 'Biofertility',
  biological: 'Biological',
  biologicalSubsample: 'Biological Subsample',
  biological_analysis: 'Biological Analysis',
  blendedLab: 'Blended Lab',
  boostYield: 'Boost yield',
  boostYieldPitch: 'Compaction above 250-300 PSI can reduce yield by 10-20%+',
  boundariesAndData: 'Boundaries and Data',
  bray: 'Bray',
  brazilPhosphorus: 'Brazil - Phosphorus',
  brazilPotassium: 'Brazil - Potassium',
  brazilSaturationMethod: 'Brazil - Saturation Method',
  buildup: 'Buildup',
  bulkAssign: 'Bulk Assign',
  bulkDelete: 'Bulk Delete',
  bulkDeleteRxMsg: 'This will remove all selected prescriptions. Press confirm to continue.',
  bulkDensity: 'Bulk Density',
  bulkExport: 'Bulk Export',
  bulkExportRxMsg: 'This will export all selected prescriptions. Press confirm to continue.',
  businessName: 'Business Name',
  by: 'by',
  calciumCarbonateEquivalent: 'Calcium Carbonate Equivalent',
  canScanProPlans: '360 Pro Scanning Enabled',
  canUpdateLater: 'You can update this later',
  cancel: 'Cancel',
  cancelAll: 'Cancel All',
  cancelAllAnalysis: 'Cancel All Analysis',
  cancelEntireSample: 'Cancel Entire Sample',
  cancelIngest: 'Cancel Ingest',
  cancelled: 'Cancelled',
  cancelLabel: 'Cancel Label',
  cancelMerge: 'Cancel Merge',
  cancelNitrate: 'Cancel Nitrate',
  cancelNutrient: 'Cancel Nutrient',
  cancelOrder: 'Cancel Order',
  cancelPerformance: 'Cancel Performance',
  cancelPressure: 'Cancel Pressure',
  cancelSample: 'Cancel Sample',
  cancelSamplePlan: 'Cancel Sample Plan',
  cancelSamplePlanFailureMsg: 'Failed to cancel sampling plan. Try again later.',
  cancelSamplePlanMsg: 'Are you sure you want to cancel this order?',
  cancelSamplingPlanMsg:
    'By cancelling this sample plan it will no longer be viewable on this operation.',
  cancelSplit: 'Cancel Split',
  cancelTotalFarm: 'Cancel Total Farm',
  cancelTotalFarmMsg:
    'Before you go, there may be a different Total Farm package that suits you better. If you want to discuss alternatives, call your Customer Service Lead anytime to discuss in more detail!',
  carbonDetails: 'Carbon Details',
  carbonPlanConfirmBody:
    'Once the plan is generated, you cannot adjust sample densities and other sampling details.\n\nFYI: Sampling plan generation will take ~15 minutes.',
  carbonPlanConfirmTitle: 'Are you sure you want to finalize plan generation?',
  carrier: 'Carrier',
  catalog: 'Catalog',
  catalogDetails: 'Catalog Details',
  category: 'Category',
  cationRatios: 'Cation Ratios',
  cellPhone: 'Cell Phone',
  changePassword: 'Change Password',
  checkBoxToContinue: 'check the box to continue',
  checkingForExistingPlan: 'Checking for an existing sampling plan',
  chemistryBackup: 'Chemistry Backup',
  chemistryResults: 'Chemistry Results',
  childrenPlates: 'Children Plates',
  choose: 'Choose',
  chooseAnalysisMsg: 'Choose the analysis you want ordered',
  chooseCollection: 'Choose collection',
  chooseNutrientPanels: 'Choose your nutrient panels',
  chooseRegistry: 'Choose registry',
  city: 'City',
  clear: 'Clear',
  click: 'Click',
  clickFieldsToAssignColln: 'Click field(s) to assign to a collection',
  clickFieldToEdit: 'Click a field to edit its boundary',
  clickToAssignPlans: 'Click To Assign Plans',
  clickToExpand: 'Click To Expand',
  clickToUndo: 'Click to undo',
  cluInstructions0: 'Click "Load CLUs" to show CLUs for the current map area.',
  cluInstructions1:
    'Select CLUs to begin mapping a boundary, or click "Reload CLUs" to refresh the map.',
  cluInstructions2: 'Click "Add CLUs" to edit selected boundaries.',
  cluInstructions3: 'Zoom in to enable CLUs.',
  cnhi: 'CNHI',
  codeIncorrectMsg: 'This code is incorrect or has expired. Please resend and try again.',
  codeSentFailMsg: 'There was an error sending the code. Please refresh and try again.',
  codeSentSuccessMsg: 'Code sent successfully!',
  coldOrRoomTemp: 'Cold or Room Temp',
  collection: 'collection',
  collectionCreated: 'Collection created successfully',
  collectionDate: 'Collection Date',
  collectionDeleted: 'Collection deleted.',
  collectionName: 'Collection Name',
  collectionToReview: 'collection to review',
  collections: 'collections',
  collectionsToReview: 'collections to review',
  comments: 'Comments',
  commercialTerritory: 'Commercial Territory',
  commission: 'Commission',
  commission_tillage_map_generation: 'Commission Tillage Map Generation',
  committedSamples: 'Committed Samples',
  committed_samples: 'Committed Samples',
  compaction: 'Compaction',
  compactionAnalysis: 'Compaction Analysis',
  compactionScore: 'Compaction Score',
  complete: 'Complete',
  completeBioMsg:
    "In addition to the Pressure Panel, map your soil's disease risk and biofertility opportunity, to optimize input spend",
  completeIngest: 'Complete Ingest',
  complete_bio: 'Complete Bio',
  completed: 'Completed',
  completedOrders: 'Completed Orders',
  completedPlan: 'Complete Plan',
  completedResults: 'Completed Results',
  completedSamples: 'Completed Samples',
  completeness: 'Completeness',
  comprehensiveFieldAnalysisMsg: 'Comprehensive field analysis on multi-year rotation',
  comprehensiveFieldBioMsg:
    'comprehensive field biology analysis looking at pests, pathogens and biofertility metrics',
  confirm: 'Confirm',
  confirmAnalysis: 'Confirm Analysis',
  confirmCollectionMsg:
    'This step will link the selected sample plans to the scan data listed underneath them. Please ensure that all of the necessary scan data has been uploaded for each sample plan before confirming and wait to confirm a sample plan if it has not been completely scanned.',
  confirmFieldAssignment: 'Confirm Field Assignment',
  confirmFieldRemoval: 'Confirm Field Removal',
  confirmMerge: 'Confirm Merge',
  confirmOrder: 'Confirm Order',
  confirmRemoveFieldMsg: 'Are you sure you want to remove this field?',
  confirmSplit: 'Confirm Split',
  connect: 'Connect',
  connectOutsideAccountRxLink: 'Connect an outside account',
  connectOutsideAccounts: 'Connect Outside Accounts',
  connectOutsideAccountsImport:
    'Connect to outside accounts to easily import field boundaries and data layers into EarthOptics',
  connectYourDataFrom: 'Connect your data from',
  connecting: 'Connecting',
  contactInfo: 'Contact Information',
  contactInfoUpdated: 'Contact information updated!',
  contactYourAgronomist:
    'Contact your agronomist to learn more, or order TillMapper directly below!',
  containsRegulatedSoilMsg: 'Contains Regulated Soil (Please include DSCA number)',
  contractDetails: 'Contract Details',
  contractEndedSuccessMsg: 'Contract Ended Successfully!',
  contractPricing: 'Contract Pricing',
  contractPricingUpdatedSuccess: 'Contract pricing updated successfully',
  contractStatus: 'Contract Status',
  contractType: 'Contract Type',
  contractUpdatedErrorMsg:
    'There was an error updating the contract. Please refresh and try again.',
  contractUpdatedSuccessMsg: 'Contract Updated Successfully!',
  controlCode: 'Control Code',
  controlType: 'Control Type',
  controls: 'Controls',
  conventionalTillage: 'Conventional Tillage',
  copyFromCorn: 'Copy from Corn',
  copyPlate: 'Copy Plate',
  corn: 'Corn',
  corn_silage: 'Corn Silage',
  cornAndSoybeanPathogens: 'Corn & Soybean Pathogens',
  cornFoliar: 'Corn Foliar',
  cornPathogens: 'Corn Pathogens',
  cornPlan: 'Corn Plan',
  cost: 'Cost',
  costPerBag: 'Cost Per Bag',
  cotton: 'Cotton',
  couldNotAddProduct: 'Could not add product. Please refresh and try again.',
  couldNotDownloadLayer: 'Could not download layer',
  couldNotMergeSelectedError: 'Could not merge selected zones.',
  countries: 'Countries',
  country: 'Country',
  county: 'County',
  countyFootNote: 'Used to estimate the risks for your operation',
  coverageRatings: 'Coverage Ratings',
  create: 'Create',
  createAccount: 'Create Account',
  createAdjustment: 'Create adjustment',
  createAndEditRx: 'Create and Edit Rx',
  createAttribute: 'Create Attribute',
  createBatch: 'Create Batch',
  createCatalogMsg: 'Enter a name for this Product Catalog and click save',
  createCatalogPlaceholderMsg: 'Example: Inputs for Smith Farms...',
  createCollection: 'Create collection',
  createContract: 'Create Contract',
  createControl: 'Create Control',
  createCustomProduct: 'Create Custom Product',
  createCustomSeed: 'Create Custom Seed',
  createEstimate: 'Create Estimate',
  createFarmAccount: 'Create a new farm account',
  createFarmPlan: 'Create Farm Plan',
  createNewGate: 'Create new gate',
  createIndexSet: 'Create Index Set',
  createLabIntakeForm: 'Create Lab Intake Form',
  createLabShipment: 'Create Lab Shipment',
  createNaptControl: 'Create NAPT Control',
  createNew: 'Create New',
  createNewAgency: 'Create New Agency',
  createNewAgent: 'Create New Agent',
  createNewContract: 'Create New Contract',
  createNewLabel: 'Create New Shipping Label',
  createNewProductCatalog: 'Create New Product Catalog',
  createNewProject: 'Create new project',
  createOperation: 'Create Operation',
  createOperationErrorMsg:
    'There was an error creating the operation. Please refresh and try again.',
  createOperationSuccessMsg: 'Operation Created Successfully!',
  createOrAddAgencyUnder: 'Create or add an agency under',
  createPassword: 'Agree and Create Password',
  createPaymentOnly: 'Create payment only',
  createPlate: 'Create Plate',
  createPrescription: 'Create Prescription',
  createProject: 'Create project',
  createProtocol: 'Create Protocol',
  createRx: 'Create Rx',
  createSalesOrders: 'Create sales orders',
  createSample: 'Create Sample',
  createSampleSheet: 'Create Sample Sheet',
  createSamplingPlan: 'Create sampling plan',
  createShippingAddress: 'Create Shipping Address',
  createShippingLabels: 'Create shipping labels',
  createUser: 'Create User',
  createYourPlan:
    '<b>Create your plan:</b> The Pattern 360 will help <b>recommend the inputs</b> that will boost yield and optimize spend.',
  createZonesInOrderToBegin: 'Create zones in order to begin selecting analysis!',
  createZonesUsing: 'Create zones using',
  created: 'Created',
  createdAt: 'Created At',
  createdBy: 'Created By',
  creatingLabIntakeForm: 'Creating Lab Intake Form',
  creatingLabIntakeFormError: 'Error creating lab intake form',
  crop: 'Crop',
  'crop-protection': 'Crop Protection',
  cropPlanningDisclaimer:
    'The information provided on the composition and degree of efficiency of the products is registered and provided exclusively by the suppliers of the products indicated on this platform and is their sole responsibility. The Pattern Ag / Earth Optics platform did not promote testing or any other validation mechanism for the results and effects indicated by suppliers. If you have any questions, we kindly suggest that you contact the Customer Service (SAC) indicated by the supplier.',
  cropProtectionCosts: 'Crop Protection Costs',
  crops: 'Crops',
  csvColumnsRequiredMsg: 'File must contain the following columns',
  csvDownloadMsg:
    'This can take a few minutes. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  csvGenerateErrorMsg: 'Error generating CSV. Please try again.',
  current: 'Current',
  custom: 'Custom',
  customProduct: 'Custom Product',
  customRx: 'Custom Rx',
  customRxMsg: 'Create prescription for any input, including crop protection products',
  customZoneSettings: 'Custom Zone Settings',
  customZones: 'Custom Zones',
  custom_points: 'Custom Points',
  customerPrice: 'Customer Price',
  customerTotal: 'Customer Total',
  cycleTreshold: 'Cycle Threshold',
  dashboard: 'Dashboard',
  dataConfirmationErrorMsg:
    'We were unable to assign the scan to the field - try again or contact EarthOptics tech support',
  dataConfirmationSuccess: 'Scan data was assigned to field(s)',
  dataReview: 'Data Review',
  date: 'Date',
  dateAdded: 'Date added',
  dateSigned: 'Date Signed',
  days_marked_ready: 'Days Marked Ready',
  dcpReading: 'DCP reading',
  dealer: 'Dealer',
  dealerFullService: 'Dealer Full-Service',
  dealerPortal: 'Dealer Portal',
  dealerPrice: 'Dealer Price',
  dealerSelfSample: 'Dealer Self-Sample',
  dealerSelfService: 'Dealer Self-Service',
  dealerTotal: 'Dealer Total',
  declined: 'Declined',
  default: 'Default',
  defaultLab: 'Default lab',
  defaultShippingLocation: 'Default Shipping Location',
  delete: 'Delete',
  deleteAdjustment: 'Delete Adjustment',
  deleteAgencyOperationConnection: 'Delete agency connection for',
  deleteCollection: 'Delete Collection',
  deleteCollectionConfirm: 'Are you sure? This collection cannot be recovered once deleted.',
  deleteGate: 'Delete gate',
  deleteLabelsGroupMsg: 'Are you sure you want to delete this group of labels?',
  deleteLabelsMsg: 'Are you sure you want to delete this label?',
  deletePDFErrorMsg: 'Error deleting PDF. Please refresh and try again.',
  deletePDFMsg: 'Are you sure you want to delete this PDF?',
  deletePDFSuccessMsg: 'PDF deleted successfully!',
  deleteSample: 'Delete sample',
  deleteSampleWarning:
    'Are you sure you want to delete this sample? This is permanent and can negatively impact the quality of the project.',
  deleteTerritory: 'Delete Territory',
  deleteTerritoryMsg: 'Are you sure you want to delete this territory?',
  deleteYourDataMsg: `For support or to request the deletion of your account and/or data, please send an email to ${SUPPORT_EMAIL}.`,
  deleteZonesSuccess: 'Successfully deleted zones!',
  deletingCollection: 'Deleting collection...',
  deliverCompletePar:
    'This operation has sample plans with results that have not been yet marked as delivered to the customer. If you have completed delivery of those results, you can mark this operation complete now.',
  delivered: 'Delivered',
  deliveredLabels: 'Delivered Labels',
  deliveryComplete: 'Delivery Complete for',
  deliveryDate: 'Delivery Date',
  demoAccount: 'Demo Account',
  density: 'Density',
  deprecated: 'Deprecated',
  depth: 'Depth',
  description: 'Description',
  detected: 'Detected',
  dilution: 'Dilution',
  dilutionConcentration: 'Dillution Concentration',
  disable: 'Disable',
  discard: 'Discard',
  discardPlate: 'Discard Plate',
  discardPlateWarningMsg:
    'Warning: If you discard this plate, it cannot be recovered. Are you sure you want to do this?',
  discarded: 'Discarded',
  discount: 'Discount',
  discount_panels: 'Discount Panels',
  discount_pressure_panel: 'Stine - Pressure Panel',
  discount_sampling_fee: 'Discount Sampling Fee',
  dismiss: 'Dismiss',
  dismissAlerts: 'Dismiss Alerts',
  dispatchNotes: 'Dispatch Notes',
  displayCategory: 'Display Category',
  displayOrder: 'Display Order',
  displayedAnalytics: 'Displayed Analytics',
  displayedRecommendationSet: 'Displayed Recommendation Set',
  dlBarcodeFile: 'DL Barcode File',
  dlFIARunSheet: 'DL Run Sheet -- FIA',
  dlICPRunSheet: 'DL Run Sheet -- ICP',
  dlMiSeqIndexSets: 'DL MiSeq Index Sets',
  dlNovaSeqIndexSets: 'DL NovaSeq Index Sets',
  dlRunSheet: 'DL Run Sheet',
  dlSampleSheet: 'DL Sample Sheet',
  'dna copies': 'DNA Copies',
  dnaConcentration: 'DNA Concentration',
  dnaConcentrationData: 'DNA Concentration Data',
  dnaExtr: 'DNA Extr',
  dnaExtraction: 'DNA Extraction',
  done: 'Done',
  donorPlate: 'Donor Plate',
  doNotBill: 'Do Not Bill',
  doNotEnd: 'Do Not End',
  doNotNavigateMsg: 'Do not navigate away from this page until upload is complete.',
  download: 'Download',
  download811msg: '811 CSV download request sent',
  downloadCsvResults: 'Download CSV Results',
  downloadDetails: 'Download Details',
  downloadLabIntakeForm: 'Download Lab Intake Form',
  downloadOptions: 'Download Options',
  downloadResults: 'Download Results',
  downloadRunSheet: 'Download Run Sheet',
  downloadScript: 'Download Script',
  downloadShpfile: 'Download Shapefile',
  downloadSoilCollectorExportMsg: 'SoilCollector Output download request sent',
  drawBoundariesOnMap: 'Draw boundaries on a map',
  drawPolygon: 'Draw Polygon',
  drawRectangle: 'Draw Rectangle',
  dry: 'Dry',
  dscaInShipment:
    "Shipments of regulated soil must also include the following packing slip, which contains EarthOptics' DSCA number",
  dueDate: 'Due Date',
  dueDateAndPriority: 'Due Date and Priority',
  dueDates: 'Due Dates',
  earliestFtCreation: 'Earliest FT Creation',
  earliestSample: 'Earliest Sample',
  earnings: 'Earnings',
  edit: 'Edit',
  editAccount: 'Edit Account',
  editBatch: 'Edit Batch',
  editContract: 'Edit Contract',
  editDiscountProgram: 'Edit Discount Program',
  editFieldBoundaries: 'Edit Field Boundaries',
  editFieldBoundary: 'Edit Field Boundary',
  editNotes: 'Edit Notes',
  editOperation: 'Edit Operation',
  editPoints: 'Edit Points',
  editProject: 'Edit project',
  editProjectName: 'Edit project name',
  editQpcrCycles: 'Edit qPCR Cycles',
  editSamplePlan: 'Edit Sample Plan',
  editZoneValues: 'Edit Zone Values',
  editZones: 'Edit Zones',
  effectOfEnvironment: 'Effect Of Environment',
  eggs: 'Eggs',
  ele: 'Ele',
  email: 'Email',
  emailAddress: 'Email address',
  emailAlreadyExistsMsg:
    'That email already has an account with EarthOptics. Use a different email, or contact support to have that user moved to this Agency.',
  emailCodeTo: 'Email code to',
  emiAdcp: 'EMI / ADCP',
  emi_needed_not_complete: 'EMI Needed, Not Complete',
  empty: 'Empty',
  enableMeterCoreDetails: 'Enable meter core details',
  endContract: 'End Contract',
  endOfResults: 'End of Results',
  endUserLicenseAgreement: 'End user license agreement',
  english: 'English',
  enlargeMap: 'Enlarge Map',
  enterAccountName: 'Enter account name',
  enterAgencyName: 'Enter an agency name.',
  enterAnEmail: 'Enter an email',
  enterBarcodeMsg: 'Enter barcode or UUID',
  enterCodeSentTo: 'Enter the 6-digit code sent to',
  enterCommaSeparatedValues: 'Enter Comma-Separated Values',
  enterDescription: 'Enter Description',
  enterEmailToSignIn: 'Enter your email to sign in',
  enterName: 'Enter Name',
  enterNameToSave: 'Enter name to save...',
  enterPasswordFor: 'Enter password for',
  enterValidEmail: 'Enter a valid email address.',
  enterValidPhone: 'Enter a valid phone number.',
  environmentalConsiderations: 'Environmental Considerations',
  eo_dispatch: 'EO Dispatch',
  error: 'Error',
  errorApprovingTransaction: 'An error occurred when trying to approve transaction.',
  errorBillingInfo: 'Error while updating billing information.',
  errorCancellingPlan: 'Error cancelling sampling plan. Please try again later.',
  errorContactInfo: 'Error while updating contact information.',
  errorCreatingShippingLabel: 'Error while creating shipping label.',
  errorDefaultShippingMsg:
    'There was an error setting the default shipping location. Please try again.',
  errorDeletingPrescription: 'An error occurred while deleting the prescription. Please Refresh.',
  errorDownloadingShpfile: 'Error downloading shapefile. Try again later.',
  errorLoadingExistingPlanMsg: 'There was an issue loading the existing plan. Please refresh.',
  errorLoggedInMsg: 'Your token expired. Please log in again.',
  errorLoggingIn: 'Error getting login url. There may be a backend issue.',
  errorLoggingInMsg: 'There was an issue logging in. Please try again.',
  errorMarkingOperationDiscount: 'Error Marking Operation as part of discount program. Try again.',
  errorMarkingPlanDelivered: 'Error Marking Sampling Plans as Delivered. Try again.',
  errorNewPassConf: 'The New Password Confirmation does not match the New Password.',
  errorNewPassRules: 'The New Password provided does not match the password rules below.',
  errorPasswordChange: 'Password change failed.',
  errorSavingFieldMsg: 'Error saving field. Please Refresh.',
  errorSavingOperationMsg: 'Error saving operation. Please Refresh',
  errorSettingBillingResMsg: 'Error setting billing responsibility. Please try again.',
  errorWithFieldGeometryMsg:
    'Please check your field boundaries. There may be unnecessary intersections or holes.',
  estAcresAtRisk: 'Est. Acres at Risk',
  estBenefit: 'Est. Benefit',
  estReturn: 'Est. Return',
  estTotalFarmPrice: 'Est. Total Farm Price',
  estYieldAtRisk: 'Est. Yield at Risk',
  estYieldImpact: 'Est. Yield Impact',
  estimateBasedOnFarmPlan:
    "This is an estimate based on your farm plan and EarthOptics' predicted risk for 2023 in your county.",
  estimateYourRisk: 'To estimate your risk, tell us about your crop plan:',
  estimatedCompletionDate: 'Estimated Completion Date',
  estimatedReturnAcross: 'Estimated return across your operation',
  eta: 'ETA',
  exc: 'Exc',
  excludeContractSignature: 'Exclude Contract Signature?',
  excludeFromAnalysis: 'Exclude from analysis',
  executeTargetAnalysisMsg: 'Execute target analysis over the next year',
  existingCollections: 'Existing collections',
  existingPassNoReuse: 'Existing password cannot be reused.',
  expectedYield: 'Expected yield',
  expirationDate: 'Expiration Date',
  expired: 'Expired',
  expiredOn: 'Expired On',
  export: 'Export',
  exportCsv: 'Export CSV',
  exportData: 'Export Data',
  exportInitiatedMsg: 'Your export was initiated!',
  exportProCsv: 'Export Pro CSV',
  exportProShapefile: 'Export Pro Shapefile',
  exportSelected: 'Export Selected',
  exportShapefile: 'Export Shapefile',
  externalLab: 'External Lab',
  externalLabResults: 'External lab results',
  extractionMethod: 'Extraction Method',
  failed: 'Failed',
  failedDeleteLabelsMsg: 'Failed to delete labels. Please refresh and try again.',
  failedGetRegulatedSoilMsg: 'Unable to get regulated soil PDF.',
  failedSalesOrderJobs: 'Failed sales order jobs',
  failedToDownloadRegSoilMsg: 'Failed to download regulated soil PDF.',
  failedToFetchMsg: 'Failed to fetch meta data. Please refresh.',
  failedToFetchProducts: 'Failed to fetch products.',
  failedToFetchSeeds: 'Failed to fetch seeds.',
  failedToFetchThresholdsMsg: 'Failed to fetch thresholds. Try again later.',
  failedToLoadClusMsg: 'Failed to load CLUs',
  failure: 'Failure',
  fair: 'Fair',
  farm: 'Farm',
  farmField: 'Farm: Field',
  farmName: 'Farm Name',
  featureType: 'Feature type',
  featureFlags: 'Feature Flags',
  fertility: 'Fertility',
  fertilityRx: 'Fertility Rx',
  field: 'Field',
  fieldAlreadyExists: 'A field with this name has already been created for your operation.',
  fieldAndSamplePlanDetails: 'Field & Sample Plan Details',
  fieldAvg: 'Field Avg',
  fieldComparison: 'Field Comparison',
  fieldCost: 'Field Cost',
  fieldCoverage: 'Field Coverage',
  fieldDeletedMsg: 'Field deleted successfully!',
  fieldName: 'Field name',
  fieldRate: 'Field Rate',
  fieldResultsOverview: 'Field Results - Overview',
  fieldRisk: 'Field Risk',
  fieldSavedMsg: 'Field saved successfully!',
  fieldSplitSuccessMsg: 'Field split successfully!',
  fieldWork: 'Field Work',
  field_view: 'Field View',
  fields: 'Fields',
  fieldsAddedToCollection: 'Fields added to collection',
  fieldsLower: 'field(s)',
  fieldsMergedSuccessMsg: 'Fields merged successfully!',
  fieldsWithErrors: 'Fields with an * are required.',
  fileSizeTooLarge: 'File size must be less than 2 MB.',
  filename: 'Filename',
  fillOutRequiredFields: 'Please fill out all required fields before submitting.',
  filteredResults: 'Filtered Results',
  financialTransactionUpdatedSuccessMsg: 'Financial transaction updated successfully!',
  firstName: 'First Name',
  firstSampleArrived: 'First Sample Arrived',
  foliar: 'Foliar',
  foliarFungicide: 'Foliar Fungicide',
  foliarMissing: 'Foliar protection options have not been added to crop plan. Click to review',
  foliarProducts: 'Foliar Products',
  forgotPassword: 'Forgot password?',
  formula: 'Formula',
  formulaDetails: 'Formula Details',
  forwardIndexSequence: 'Forward Index Sequence',
  forwardPrimer: 'Forward Primer',
  fourOFourMsg: 'It appears the page you are looking for is empty. Try one of the links above...',
  freezer: 'Freezer',
  from: 'From',
  full: 'Full',
  fullyAssigned: 'Fully Assigned',
  fungicide: 'Fungicide',
  fungicideInsecticide: 'Fungicide & Insecticide',
  fungicideNematicide: 'Fungicide + Nematicide',
  gate: 'Gate',
  gateCreatedMsg: 'Gate(s) created successfully',
  gates: 'Gates',
  generateCsvResults: 'Generate CSV Results',
  generateFrom: 'Generate From',
  generateMapbookMsg:
    'Generating mapbook... This will take a few minutes. The download will appear in the dropdown.',
  generateMapbookResults: 'Generate Mapbook Results',
  generatePoints: 'Generate Points',
  generateProCsvResults: 'Generate Pro CSV Results',
  generateProShapefileResults: 'Generate Pro Shapefile Results',
  generateRxFrom: 'Generate Rx from',
  generateSamplingPlan: 'Generate sampling plan',
  generateShapefileResults: 'Generate Shapefile Results',
  generateZones: 'Generate Zones',
  generating: 'Generating',
  generatingPrescription: 'Generating Prescription',
  generic: 'Generic',
  genomic: 'Genomic',
  genomicPlates: 'Genomic Plates',
  genomicProcessing: 'Genomic Processing',
  genomicSamples: 'Genomic Samples',
  getStartedCreateAccount: 'To get started, create your farming account',
  goBack: 'Go back',
  goToPlan: 'Go To Plan',
  gold: 'Gold',
  good: 'Good',
  gossWilt: "Goss's Wilt",
  grayLeafSpot: 'Gray Leaf Spot',
  grid_points: 'Grid Points',
  grid_zones: 'Grid Zones',
  groupBy: 'Group by',
  grower: 'Grower',
  harmfulBiomarkers: 'Harmful Biomarkers (lower is better)',
  hasGates: 'Has gates',
  haveReadAndAgreeToPatternAg:
    'By clicking “Agree,” I acknowledge that I have read and understand the EarthOptics End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the EarthOptics End User License Agreement:',
  haveReadAndAgreeToPatternAgIncludingTerms:
    'By clicking “Agree,” I acknowledge that I have read and understand the EarthOptics Terms and Conditions, End User License Agreement and Privacy Policy, and I accept and agree to be bound by all of the terms and conditions of the EarthOptics End User License Agreement:',
  height: 'Height',
  hideDetails: 'Hide Details',
  high: 'High',
  highContrast: 'High Contrast',
  highResNutrientScriptMsg: 'High resolution nutrient prescription',
  highResSoilFertMsg: 'high res soil fertility analysis with 10x10M resolution pixels',
  highResVariableRateScriptMsg: 'High resolution variable rate prescription',
  highRisk: 'High Risk',
  highRiskAcres: 'High Risk Acres',
  highRiskAreas: 'High Risk Areas',
  highRiskFields: 'High Risk Fields',
  highRiskRwFieldTooltip:
    'Detection of 6+ eggs indicates high risk for root feeding. Likely ranges from 0.5-2.5 node injury average without protection.',
  history: 'History',
  homogen: 'Homogen',
  homogenBarcode: 'Homogen Barcode',
  homogenization: 'Homogenization',
  homogenizationComplete: 'Homogen Complete',
  howItWorks: 'How It Works',
  howWouldyouLikeToSignIn: 'How would you like to sign in?',
  hybrid: 'Hybrid',
  hybridVariety: 'Hybrid/Variety',
  imageLabel: 'Image Label',
  imagery: 'Imagery',
  imageryMap: 'Imagery Map',
  imageryNotFound: 'Imagery Unavailable',
  imageryNotFoundDescription:
    'Imagery is unavailable for this field. Please use yield data to create a removal prescription. If needed, below are some options for bringing in yield data:',
  imageryZones: 'Imagery Zones',
  imminentDueDateHeader: 'Imminent Due Date',
  imminentDueDateMessage: 'This sample has an imminent due date on',
  importData: 'Import Data',
  inFurrow: 'In furrow',
  inFurrowProducts: 'In-Furrow Products',
  inPayment: 'In Payment',
  inProgress: 'In Progress',
  inShipment: 'In Shipment',
  in_furrow: 'In Furrow',
  in_furrowMissing:
    'In-furrow protection options have not been added to crop plan. Click to review',
  incentive: 'Incentive',
  include: 'Include',
  incomplete: 'Incomplete',
  incorrectImageDimensions: 'Image dimensions must be at least 1x1.',
  incorrectPassword: 'The Old Password you entered is incorrect.',
  indexSet: 'Index Set',
  indexSets: 'Index Sets',
  indicatePlateStorageMsg: 'Please indicate where and how the plate will be stored',
  ingested: 'Ingested',
  initiatedExportFailMsg: 'Failed to initiate export. Please refresh and try again.',
  input: 'Input',
  inputSavings: 'Input Savings',
  insecticide: 'Insecticide',
  insufficientDataFound: 'Insufficient Data Found',
  insufficientDataWarning:
    'More than 25% of your field has insufficient data and you will be receiving a refund. Zones marked N/A do not have sufficient data.',
  insufficient_data: 'Insufficient Data',
  insufficient_data_performance: 'Insufficient Data (Performance)',
  insufficient_layers_tillage: 'Insufficient Tillage Layers',
  interLabShipping: 'Inter-Lab Shipping',
  invalidCellPhoneMsg: 'Please enter a valid cell phone number.',
  invalidCornPriceMsg: 'Please make sure your corn price is between $2 and $10.',
  invalidCornYieldMsg: 'Please make sure your corn yield is between 100 and 600.',
  invalidFile: 'Invalid File Uploaded',
  invalidSoybeanPriceMsg: 'Please make sure your soybean price is between $5 and $20.',
  invalidSoybeanYieldMsg: 'Please make sure your soybean yield is between 20 and 100.',
  invalidZipcode: 'Invalid zip code',
  invalidZoneComboError: 'Invalid zone combination.',
  invite: 'Invite',
  inviteAgentCreateAcctMsg:
    'Invite an agent to create an account and work with your agency customers',
  inviteByEmail: 'Invite users by email',
  inviteNewUser: 'Invite New User',
  inviteSampler: 'Invite Sampler',
  inviteUserMsg:
    'The user you are inviting does not have an account with EarthOptics yet. Please provide some more information about them to complete the invite',
  invoice: 'Invoice',
  invoices: 'Invoices',
  isExistingAgency: 'Is this an existing agency?',
  isThisPartOfExisting: 'Is this part of an existing agency?',
  is_field_nd: 'Is Field ND',
  is_field_result_binary: 'Is Field Result Binary',
  is_overview_nd: 'Is Overview ND',
  is_sample_nd: 'Is Sample ND',
  isolates: 'Isolates',
  isuLimeTo6: 'ISU Lime to 6.0',
  isuLimeTo65: 'ISU Lime to 6.5',
  isuLimeTo69: 'ISU Lime to 6.9',
  isuPhosphorus: 'ISU Phosphorus',
  isuPotassium: 'ISU Potassium',
  john_deere: 'John Deere',
  kclAnalysis: 'KCL Analysis',
  kclRerun: 'KCL Rerun',
  keepTotalFarm: 'Keep Total Farm',
  keyOutcomeMsg: 'Key outcomes on every acre, every year',
  lab: 'Lab',
  labApp: 'Lab App',
  labId: 'Lab ID',
  labPackage: 'Lab Package',
  labShipmentId: 'Lab Shipment ID',
  label: 'Label',
  labelCount: 'Label Count',
  labelDeletedSuccessMsg: 'Labels successfully deleted!',
  labelDownloads: 'Label Downloads',
  labels: 'Labels',
  labs: 'Labs',
  lane: 'Lane',
  language: 'Language',
  lastEdited: 'Last edited',
  lastName: 'Last Name',
  lastSync: 'Last Sync',
  lastUpdated: 'Last Updated',
  latLon: 'Lat/Lon',
  latitude: 'Latitude',
  lbs: 'lbs',
  leaveOperation: 'Leave Operation',
  legend: 'Legend',
  length: 'Length',
  letsSetYourPassword: 'Let’s set your password',
  level: 'Level',
  levels: 'Levels',
  levelsIndicateHighRisk: 'levels indicate higher risk',
  levelsIndicateModerateRisk: 'levels indicate moderate risk',
  levelsIndicateYieldLoss: 'levels indicate risk for yield loss',
  lime: 'Lime',
  lineItemDetail: 'Line Item Detail',
  list: 'List',
  listPrice: 'List Price',
  loadClus: 'Load CLUs',
  loading: 'Loading',
  location: 'Location',
  locations: 'Locations',
  lockInAcresNow: 'Lock in acres now to ensure timely sampling',
  lockInOfferErrorMsg: 'There was an error locking in your offer. Please try again.',
  lockInOfferToday: 'Lock In Offer Today',
  lockPoints: 'Lock Points',
  lockZones: 'Lock Zones',
  locked: 'Locked',
  lockedInAcres: 'Locked In Acres',
  login: 'Log In',
  loginToQuickbooks: 'Login to Quickbooks',
  logout: 'Logout',
  longitude: 'Longitude',
  lossOnIgnition: 'Loss On Ignition',
  low: 'Low',
  lowRisk: 'Low Risk',
  lowRiskAcres: 'Low Risk Acres',
  lowRiskAreas: 'Low Risk Areas',
  lowRiskFields: 'Low Risk Fields',
  lowRiskRwFieldTooltip:
    'Detection of less than 0.2 eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  lowRiskRwZoneTooltip:
    'No detection of eggs indicates low risk of economic damage. Likely less than 0.25 node injury average.',
  lowScanCoverageWarning: 'Warning: low scan coverage',
  lowScanCoverageWarningMsg:
    'The selected scans are below the recommended threshold of 75% field coverage. This can impact resulting map quality. Consider re-scanning or uploading missing scan files.',
  m3Analysis: 'M3 Analysis',
  m3Rerun: 'M3 Rerun',
  macronutrients: 'Macronutrients',
  manage: 'Manage',
  manageAccount: 'Manage Account',
  manageAgencies: 'Manage Agencies',
  manageAgency: 'Manage Agency',
  manageAgencyLocations: 'Manage Agency Locations',
  manageAgents: 'Manage Agents',
  manageAnalytics: 'Manage Analytics',
  manageCatalogs: 'Manage Catalogs',
  manageCommercialUsers: 'Manage Commercial Users',
  manageControls: 'Manage Controls',
  manageFeatures: 'Manage features',
  manageFields: 'Manage Fields',
  manageGates: 'Manage gates',
  manageLocations: 'Manage Locations',
  manageOrders: 'Manage Orders',
  manageParentAgency: 'Manage Parent Agency',
  manageProjects: 'Manage Projects',
  manageUsers: 'Manage Users',
  manual: 'Manual',
  manualRecord: 'Manual Record',
  manualUpload: 'Manually upload yield data',
  manufacturer: 'Manufacturer',
  mapFields: 'Map Fields',
  mapFieldsToOrder: 'Map fields to place an order',
  mapSearchPlaceholder: 'Search city, county etc',
  mapYourPressure:
    '<b>Map your pressure:</b> EarthOptics measures your soil biology to create <b>sub-field maps of pest and disease pressure.</b>',
  mapbookConfiguration: 'Mapbook Configuration',
  mapbookDownloads: 'Mapbook Downloads',
  mapbookGenerationFailedMsg: 'Mapbook generation failed. Please try again.',
  maps: 'Maps',
  margin: 'Margin',
  markArrived: 'Mark Arrived',
  markAsDelivered: 'Mark as Delivered',
  markBatchConfirmMsg: 'Are you sure you want to mark this batch as sent?',
  markBatchSent: 'Mark Batch Sent',
  markBatchWarningMsg:
    'Please note this batch will no longer be editable and the sample sheets will be locked!',
  markNotArrived: 'Mark Not Arrived',
  markNotReady: 'Mark Not Ready',
  markNotReadyFail:
    'There was an error marking the plan as not ready. Please refresh and try again.',
  markNotReadySuccess: 'The plan was successfully marked as not ready.',
  markPlanDelivered: 'Mark Plans Delivered',
  markReady: 'Mark Ready',
  markReadyMsg: 'Clicking this button will mark this field as Ready to Sample',
  markRecorded: 'Mark Recorded',
  markSettled: 'Mark Settled',
  markSettledMsg:
    "By marking this payment as settled, you are aknowledging that this payment has been entered into EarthOptics' accounting records, and has been handled according to the details outlined below:",
  markSterilized: 'Mark Sterilized',
  markedReadyAssignedMsg: 'When marked ready, a sampler will be assigned',
  markedReadyFailureMsg: 'There was an error marking the plan ready. Please refresh and try again.',
  markedReadySuccessMsg: 'The plan was successfully marked ready.',
  marked_not_ready: 'Marked Not Ready',
  marked_ready: 'Marked Ready',
  marked_ready_days: 'Marked Ready (Days)',
  mass: 'Mass',
  max: 'Max',
  maxAcres: 'Max Acres',
  maxAcresForEnrollment: 'Max Acres for enrollment',
  maxDensity: 'Max Density',
  maxDepth: 'Max Depth',
  maxPsi: 'Max PSI',
  maxScanDensity: 'Max Scan Density',
  maxYield: 'Maximum Yield',
  maximumRate: 'Maximum rate',
  measurementTypes: 'Measurement types',
  mehlich: 'Mehlich',
  merge: 'Merge',
  mergeFields: 'Merge Fields',
  mergePlates: 'Merge Plates',
  mergeZones: 'Merge Zones',
  mergeZonesSuccess: 'Successfully merged zones!',
  meterCoreSampleDetails: '1m core sample details',
  method: 'Method',
  micronutrients: 'Micronutrients',
  min: 'Min',
  minDensity: 'Min Density',
  minDepth: 'Min Depth',
  minScanDensity: 'Min Scan Density',
  minYield: 'Minimum Yield',
  minimalNumberPointsMsg: 'Please add more points in order to submit the plan.',
  minimalProPointsMsg: 'Two scan points are required per field - please increase the sample count.',
  minimalProPointsWarningMsg:
    'Warning - scan quality can be impacted when plans contain less than 4 sample points',
  minimumMeterCoreSamples: 'Minimum 1m core samples per stratum',
  minimumRate: 'Minimum rate',
  minutesAbbreviated: 'min',
  missingCountyMsg: 'Please select a county.',
  missingCropDetailsMsg: 'Please fill out all crop information.',
  missingInfoMsg: 'Please fill out all information before saving.',
  missingOperationIdMsg: 'Please create or choose an existing operation.',
  missingSelectionOverlapMsg: 'Missing overlap between field and selection.',
  missingUrlMsg: 'Missing upload_url',
  mod: 'Mod',
  modRisk: 'Mod Risk',
  moderate: 'Moderate',
  moderateRisk: 'Moderate Risk',
  moderateRiskFields: 'Moderate Risk Fields',
  moderateRiskRwFieldTooltip:
    'Detection from 0.2-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  moderateRiskRwZoneTooltip:
    'Detection of 0-6 eggs indicates moderate risk of economic damage. Likely ranges from 0.25-0.7 node injury average without protection.',
  modifiedMehlich: 'Modified Mehlich',
  moisture: 'Moisture',
  moveSample: 'Move sample',
  msrp: 'MSRP',
  myAccounts: 'My Accounts',
  myAgencies: 'My Agencies',
  myAlerts: 'My Alerts',
  myCatalogs: 'My Catalogs',
  myFarmPlan: 'My Farm Plan',
  myReport: 'My Report',
  name: 'Name',
  nameAtoZ: 'Name A-Z',
  nameZtoA: 'Name Z-A',
  naptControls: 'NAPT Controls',
  ndvi: 'NDVI',
  ndviMap: 'NDVI Map',
  ndviZones: 'NDVI Zones',
  nematicide: 'Nematicide',
  netPayment: 'Net Payment',
  netValue: 'Net value',
  neutralization: 'Neutralization',
  new: 'New',
  newAgencyName: 'New Agency Name',
  newAnalytic: 'New Analytic',
  newBatch: 'New Batch',
  newFieldName: 'New Field Name',
  newOrParentContract: 'New or Parent Contract?',
  newPassConf: 'New Password Confirmation',
  newPassConfMissing: 'New password confirmation is missing.',
  newPassMissing: 'New password is missing.',
  newPassword: 'New Password',
  newPlate: 'New Plate',
  newPlateBarcode: 'New Plate Barcode',
  newRiskEstimate: 'New Risk Estimate',
  newTerritory: 'New Territory',
  newestFirst: 'Newest first',
  next: 'Next',
  nextStep: 'Next Step',
  nitrate: 'Nitrate',
  nitrateAnalysis: 'Nitrate Analysis',
  nitrateAnalysisMsg: 'Add nitrate testing to each zone where nutrient analysis will be completed',
  nitratePanel: 'Nitrate Panel',
  nitrate_panel: 'Nitrate Panel',
  no: 'No',
  noAgenciesExist: 'No agencies exist',
  noAnalysis: 'No analysis',
  noBatchesFound: 'No Batches Found',
  noBillingUserMsg: 'Please add a Billing Responsibility before placing orders',
  noChemistryResultsMsg: 'No chemistry results for this sample uuid',
  noContractsExistMsg: 'No contracts exist for this agency.',
  noData: 'No Data',
  noDataForFieldAnalytic: 'There is no data for this field/analytic.',
  noDataToReview: 'No Data To Review',
  noDealer: 'No dealer',
  noDiscount: 'No Discount',
  noFileChosen: 'No File Chosen',
  noFilesListMsg: 'No files to display.',
  noIndexSetsFound: 'No Index Sets Found',
  noInstructionsAvailable: 'No instructions available.',
  noLabelsListMsg: 'No labels found',
  noLogMeOut: 'No, log me out',
  noNitrate: 'No Nitrate',
  noOrderHistoryCompletedMsg:
    'No completed orders found for the selected year. Orders that are still in progress will be displayed on the "Active & Completed" tab.',
  noOrderHistoryMsg: 'No order history found for the selected year',
  noPlatesFoundMsg: 'No plates found',
  noPlatesListMsg: 'No plates to display.',
  noProductsToDisplay: 'No Products To Display',
  noResultsExistForField: 'No results exist for this field.',
  noResultsExistForOperation: 'No results exist for this operation.',
  noResultsForCategory: 'No results for this category.',
  noRiskCancel: 'No risk, cancel anytime before sampling',
  noSamplesInBatch: 'No samples in batch.',
  noSamplingHistoryMsg: 'No sampling history found for current year',
  noSeedToDisplay: 'No Seed To Display',
  noShipmentsFoundMsg: 'No Shipments Found',
  noUnits: 'no units',
  noUserFoundMsg: 'No user found with that email.',
  noUserWithBillingResMsg:
    'There is no user with billing responsibility for this account. You will be able to create sample plans for this account, but will not be able collect samples in field until a user has been added here.',
  noWeNeedToCreate: 'No, we need to create a new agency for them.',
  noZonesSelected: 'No zones selected for analysis',
  nonPrimary: 'Non-Primary',
  non_pro_till_rx: 'Non-Pro/TillMapper',
  none: 'None',
  normDna: 'Norm DNA',
  normalizedDna: 'Normalized DNA',
  northCornLeafBlight: 'North Corn Leaf Blight',
  notAnalyzed: 'Not Analyzed',
  notAssignedNotReady: 'Not Assigned + Not Ready',
  notDetected: 'Not Detected',
  notInvoiced: 'Not invoiced',
  notOrdered: 'Not Ordered',
  notReady: 'Not Ready',
  notSentToNetsuiteMsg: 'Operation sampling plans will not be billed in NetSuite.',
  notStarted: 'Not Started',
  notSyncedYet: 'Not Synced yet',
  notTerritoryOwner:
    'Your assigned territory was not created by you. Press clear to reset the map if you would like to draw your own.',
  notes: 'Notes',
  numberOfLabels: 'Number of labels',
  nutrient: 'Nutrient',
  nutrientDetails: 'Nutrient Details',
  nutrientPanel: 'Nutrient Panel',
  nutrientPoints: 'Nutrient Points',
  nutrientProcessing: 'Nutrient Processing',
  nutrientRx: 'Nutrient Rx',
  nutrientSamples: 'Nutrient Samples',
  nutrient_analysis: 'Nutrient Analysis',
  nutrient_panel: 'Nutrient Panel',
  nutrient_panel_description:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter',
  nutrient_panel_description_plus_nitrate:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter, plus Nitrate testing',
  nutrient_panel_list_high_msg: 'Pattern 360 list price is too high.',
  nutrient_panel_list_low_msg: 'Pattern 360 list price is too low.',
  nutrients: 'Nutrients',
  oats: 'Oats',
  of: 'of',
  off: 'Off',
  offer: 'Offer',
  oldPassMissing: 'Old password is missing.',
  oldPassword: 'Old Password',
  oldestFirst: 'Oldest first',
  olsen: 'Olsen',
  omAnalysis: 'OM Analysis',
  omRerun: 'OM Rerun',
  omniRevvityDescription: 'omni lysis and magbead with TBD method for chelating metal ions',
  omniRevvityPbWashDescription: 'omni lysis and magbead with PB wash',
  on: 'On',
  only: 'Only',
  open: 'Open',
  openPlate: 'Open Plate',
  openRisks: 'Open Risks',
  openTransactions: 'Open Transactions',
  operation: 'Operation',
  operationCountryMsg: 'This is the country where the operation is located.',
  operationDetails: 'Operation Details',
  operationName: 'Operation Name',
  operationNoDiscountsMsg: 'This operation has no discount programs.',
  operationSavedMsg: 'Operation Saved successfully!',
  operationSummary: 'Operation Summary',
  operations: 'Operations',
  operator: 'Operator',
  operatorEmail: 'Operator Email',
  operatorName: 'Operator Name',
  optOutText:
    'You have successfully opted out of receiving notifications from EarthOptics via text message. Please allow 24 hours for changes to take effect.',
  optimal: 'Optimal',
  optimizeInputs: "Optimize inputs based on Pattern 360 report on 'at risk' fields",
  optimizePlans: 'Optimize Plans',
  optimizeSpend: 'Optimize spend',
  optimizeTillage: 'Optimize tillage decisions and understand sub-field compaction with TillMapper',
  optimizedPlansErrorMsg: 'Error optimizing plans. Please refresh and try again.',
  optimizedPlansSuccessMsg: 'Plans optimized successfully!',
  optimizingPlans: 'Optimizing Plans',
  optional: 'optional',
  options: 'Options',
  or: 'Or',
  orYouCan: 'Or you can',
  order: 'Order',
  orderAdditionalSamplePlan: 'Add Additional Sample Plan',
  orderHistory: 'Order History',
  orderSample: 'Order Sample',
  orderSamplePlan: 'Order Sample Plan',
  orderSamplingPlan: 'Order Sampling Plan',
  orderTillMapperNow: 'Order TillMapper Now',
  ordered: 'Ordered',
  orders: 'Orders',
  other: 'Other',
  otherFields: 'Other fields',
  outputFormula: 'Output format',
  overrideInputsMsg: 'All input selections will be overridden on all crop plans!',
  overview: 'Overview',
  overviewOnly: 'Overview Only',
  owner: 'Owner',
  package: 'Package',
  'pagi-2024': 'PAGI',
  panel: 'Panel',
  panelsOrdered: 'Panels Ordered',
  parcelDimensions: 'Parcel Dimensions',
  parentAgency: 'Parent Agency',
  parentPlates: 'Parent Plates',
  parent_agency: 'Parent Agency',
  partial: 'Partial',
  partialResults: 'Partial Results',
  'partially received': 'Partially Received',
  'partially sampled': 'Partially Sampled',
  'partially shipped': 'Partially Shipped',
  partiallyFilled: 'Partially Filled',
  partiallyShipped: 'Partially Shipped',
  partially_sampled_long_duration: 'Partially Sampled (Long Duration)',
  partner: 'Partner',
  password: 'Password',
  passwordReqMsg: 'Must contain 8 characters, including an uppercase letter and a number',
  passwordRules: 'Note: Must contain an uppercase letter, lowercase letter, and a number',
  pastDueDateHeader: 'Past Due Date',
  pastDueDateMessage: 'This sample is past its due date on',
  pastFileUpload: 'Past file upload',
  pattern360Msg:
    'Combine Complete Bio with our comprehensive macro and micronutrient analysis to identify nutrient deficiencies and balance your soil (not available for regulated soil at this time)',
  patternAgDSCANumber: 'Packing slip with EarthOptics DSCA Number',
  patternAgPays: 'EarthOptics Pays',
  patternAgPersonalizedReport:
    'Your Pattern 360 report will provide personalized analysis and recommendations for your farm.',
  patternIsSyncing: 'EarthOptics is syncing with your account. (This can take a few minutes)',
  patternReportAnalyzes:
    'The Pattern 360 report analyzes your soil to <b>predict next year’s risk</b> from damaging pests & diseases.',
  pattern_360: 'Pattern 360',
  payAsYouGo: 'Pay as you go',
  payAsYouGoMsg: 'Only pay as the field analysis gets completed',
  payment: 'Payment',
  paymentTerms: 'Payment Terms',
  payment_due: 'Payment Due',
  payments: 'Payments',
  payor: 'Payor',
  payorPayee: 'Payor / Payee',
  paysPatternAg: 'Pays EarthOptics',
  pcrDnaMultiplier: 'PCR DNA Multiplier',
  pdfRegenerationInitiatedErrorMsg: 'Error initiating PDF regeneration. Please try again.',
  pdfRegenerationInitiatedMsg: 'Mapbook PDF regeneration initiated!',
  pending: 'Pending',
  pendingDelivery: 'Pending Delivery',
  per: 'Per',
  perAcreSkus: 'Per Acre SKUs',
  perSampleIndicates: 'per sample indicates',
  perSampleSkus: 'Per Sample SKUs',
  percentCompacted: '% Compacted',
  percentCompactedTooltip: '% > 300 PSI (0"-12")',
  percentOfFarmAnalyzedPerYear: 'Percent of farm analyzed per year',
  percentOfField: '% of Field',
  percentOfFieldsAtModerateOrHighRisk: 'Percent of Fields at Moderate or High Risk',
  percentTargetApply: 'Percent of target to apply',
  perfPanel: 'Perf Panel',
  performAnalysisSingleDensity: 'Perform all analysis at a single density',
  performance: 'Performance',
  performancePanel: 'Performance Panel',
  performance_panel: 'Performance Panel',
  performance_panel_description:
    "Map your soil's disease risk, and biofertility opportunity, to optimize input spend",
  performance_panel_list_high_msg: 'Complete Bio list price is too high.',
  performance_panel_list_low_msg: 'Complete Bio list price is too low.',
  perYear: 'Per year',
  phAnalysis: 'pH Analysis',
  phRerun: 'pH Rerun',
  phone: 'Phone',
  phosphate: 'Phosphate',
  phosphateAnalysis: 'Phosphate Analysis',
  phosphateRerun: 'Phosphate Rerun',
  phosphorus: 'Phosphorus',
  pipeline: 'Pipeline',
  planConfMsg:
    'By clicking "Confirm Order" you are agreeing to purchase the services outlined above for this operation. Payment will be due 2 weeks after results are made available.',
  planDetails: 'Plan Details',
  planMarkedDeliveredSuccess: 'Plans marked as delivered successfully!',
  planName: 'Plan Name',
  planNames: 'Plan Name(s)',
  planStatus: 'Plan Status',
  planSummary: 'Plan Summary',
  planZonesErrorDiffMsg: 'You may have uploaded a different field.',
  planZonesErrorNoZipMsg: 'You must upload a zipped file containing a ".shp" file.',
  planZonesErrorNoZonesMsg: 'This upload does not contain zones.',
  planZonesSuccessMsg: 'Zones have successfully been updated!',
  plannedCrop: 'Planned Crop',
  plannedSamples: 'Planned Samples',
  planning: 'Planning',
  plantingRate: 'Planting Rate',
  plate: 'Plate',
  plateAddedToBatch: 'Plate Added To Batch',
  plateArrived: 'Plate Arrived',
  plateBarcode: 'Plate Barcode',
  plateBarcodeId: 'Plate Barcode / ID',
  plateDetails: 'Plate Details',
  plateHasBatchErrorMsg:
    'Plate already exists in another batch. Adding the plate to this batch will remove it from the previous batch',
  plateNotAddedToBatch: 'Plate Not Added To Batch',
  plateType: 'Plate Type',
  plated: 'Plated',
  plates: 'Plates',
  platinum: 'Platinum',
  pleaseRefreshUnableToConnect: 'Please Refresh and try again. We were unable to connect you to',
  pointResults: 'Point Results',
  points: 'Points',
  poolBarcode: 'Pool Barcode',
  portuguese: 'Portuguese',
  postOperationCropInfoErrorMsg:
    'There was an error updating the crop information. Please refresh and try again.',
  potassium: 'Potassium',
  preTransit: 'Pre Transit',
  predictYourRisk:
    '<b>Predict your risks:</b> Pest & disease maps <b>predicts your risk of yield loss</b> next season.',
  predicted_ready: 'Predicted Ready',
  premiumFeatures: 'Premium Features',
  prep: 'Prep',
  preparedBy: 'Prepared by',
  preplant: 'Pre-plant',
  prescriptionCreatedFailMsg: 'Failed to create prescription. Please refresh and try again.',
  prescriptionCreatedMsg: 'Prescription created!',
  prescriptionDownloadFailMsg: 'Failed to download prescription. Please refresh and try again.',
  prescriptionDownloadSuccessMsg: 'Prescription downloaded successfully!',
  prescriptionSaved: 'Prescription saved',
  prescriptions: 'Prescriptions',
  pressure: 'Pressure',
  pressureAndPerformancePanelsZones: 'Pressure & Performance Panels Zones',
  pressureNutrientPanel: 'Pressure Panel + Nutrient Panel',
  pressureNutrientPanelsDescription:
    'Our Pressure Panel plus our comprehensive macro and micronutrient analysis',
  pressurePanel: 'Pressure Panel',
  pressure_nutrient_panels: 'Pressure Panel + Nutrient',
  pressure_panel: 'Pressure Panel',
  pressure_panel_description:
    'Predict and plan for Rootworm, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  pressure_panel_description_rkn:
    'Predict and plan for Root Knot Nematode, Soybean Cyst Nematode, and Sudden Death Syndrome by measuring your pressure before planting',
  pressure_panel_list_high_msg: 'Pressure Panel list price is too high.',
  pressure_panel_list_low_msg: 'Pressure Panel list price is too low.',
  pressure_panel_only: 'Pressure Panel',
  previousCrop: 'Previous Crop',
  price: 'Price',
  priceList: 'Price List',
  pricePerAcre: 'Price per Acre',
  pricePerTon: 'Price per ton',
  pricePerZone: 'Price per Zone',
  priceType: 'Price Type',
  primary: 'Primary',
  primaryAgent: 'Primary Agent',
  primaryCounty: 'Primary County',
  print: 'Print',
  printAll: 'Print All',
  printHeader: 'Your Soil Is Talking, It’s Time To Listen',
  printLabel: 'Print Label',
  printOptions: 'Print Options',
  printQRCode: 'Print QR Code',
  printSplitDensityBarcode: 'Print Split Density Barcode',
  printSummary: 'Print Summary',
  priority: 'Priority',
  prioritySampleHeader: 'Priority Sample',
  privacyPolicy: 'Privacy Policy',
  pro: 'Pro',
  pro10Res: 'Pro (10x10 meter resolution)',
  proResults: 'Pro Results',
  pro_map_generation: 'Pro Map Generation',
  pro_only: 'Pro Only',
  pro_sensor_scan: 'Pro Sensor Scan',
  process: 'Process',
  processed: 'Processed',
  processing: 'Processing',
  processingData: 'Processing Data',
  processingSummary: 'Processing Summary',
  product: 'Product',
  productAdmin: 'Product Admin',
  productDesc360Complete:
    'The Complete Package: TruNutrient, TruBio, and TruTill give you the most comprehensive analysis of your soil environment available today. Plan fertility, seed, crop protection, biofertility, and tillage activities for next season to optimize spend and profitability.',
  productDesc360ProTnTb:
    'The Original 360: A comprehensive 360 view of your soil including high-resolution fertility analysis, and the most complete biological profile of the pests, pathogens, and biofertility indicators you need to plan.',
  productDescCustom:
    'Ala carte options for your soil analysis (changing any of the options below will automtically set the product to "Custom").',
  productDescTbTt:
    'Plan seed, crop protection, biofertility and tillage, using the most advanced analytics to map the physical and biological components of the soil.',
  productDescTnTt:
    'Plan fertility and tillage activities using the most in-depth, high-resolution view of your key nutrient and compaction levels.',
  productDescTruBio:
    'In-depth biological analysis of the soil to understand risks and opportunities. Plan seed and crop protection based on pathogen risks, place biofertility products with confidence to boost yield.',
  productDescTruNutrient:
    'High-resolution nutrient analysis on 10x10M grids, enables you to create the most accurate and precise fertility application plans and prescriptions',
  productDescTruTill:
    'Track compaction for every inch of your soil down to 18 inches, on 10x10M grids. The only analysis of its kind, paired with a tillage prescription so you can amend compaction with precision.',
  productName: 'Product Name',
  productOrdered: 'Product Ordered',
  profileSettings: 'Profile Settings',
  programToEnrollMsg: 'Which program do you want to enroll?',
  project: 'Project',
  projectBuilder: 'Project builder',
  projectCreatedMsg: 'Project created successfully',
  projectName: 'Project name',
  projectUpdatedMsg: 'Project updated successfully',
  projects: 'Projects',
  protection: 'Protection',
  provideNotesMsg: 'Provide notes for the uploaded document',
  puppeteerRunningMsg: 'Generating Mapbook Results (Not all fields are ready)',
  purchase: 'Purchases',
  purchases: 'Purchases',
  qpcrAnalysis: 'qPCR Analysis',
  qpcrCycles: 'qPCR Cycles',
  quadrant: 'Quadrant',
  rack: 'Rack',
  range: 'Range',
  ranges: 'Ranges',
  ranging: 'ranging',
  rate: 'Rate',
  rating: 'Rating',
  rdAttributes: 'R&D Attribiutes',
  rdProtocols: 'R&D Protocols',
  readThresholdUpdateFailureMsg: 'Read Threshold Update Failed',
  readThresholdUpdateSuccessMsg: 'Read Threshold Update Successful',
  readiness: 'Readiness',
  reads: 'Reads',
  ready: 'Ready',
  readyToSample: 'Ready To Sample',
  readyToScan: 'Ready to Scan',
  reasonForAdjustments: 'What is the reason for the adjustment?',
  reassign: 'Reassign',
  recalculate: 'Recalculate',
  received: 'Received',
  receivingDataEntry: 'Receiving & Data Entry',
  recenter: 'Recenter',
  recommendation: 'Recommendation',
  recommendationDefaults: 'Recommendation Defaults',
  recommendationSets: 'Recommendation Sets',
  recordedBy: 'Recorded By',
  reducedNoTill: 'Reduced / No-Till',
  referenceLink: 'Reference Link',
  referencesAndResources: 'References and Resources',
  regenerateMapbookPDFsMsg: 'Regenerate Mapbook PDFs (Outdated resuslts showing)',
  region: 'Region',
  registry: 'Registry',
  regulated: 'Regulated',
  regulatedMaterial: 'Regulated Material',
  regulatedMaterialMsg:
    'If shipping regulated material, ensure that ONLY regulated materials are in the shipment',
  regulatedSoil: 'Regulated Soil',
  regulatedSoilMsg:
    'If this sample plan contains regulated soil, please review the required protocols and ensure that you are completing each step prior to shipment',
  regulatedSoilsSop: 'Regulated soils shipping SOP',
  rejected: 'Rejected',
  relatedAgencies: 'Related Agencies',
  relatedDocuments: 'Related Documents',
  relatedRsms: 'Related RSMs',
  relativeNeutralization: 'Relative Neutralization',
  reloadClus: 'Reload CLUs',
  removal: 'Removal',
  removalKIsu: 'Iowa State Removal - K',
  removalKTriState: 'Tri State Removal - K',
  removalPIsu: 'Iowa State Removal - P',
  removalPSdsu: 'SDSU Removal - P',
  removalKSdsu: 'SDSU Removal - K',
  removalPTriState: 'Tri State Removal - P',
  removalRate: 'Removal Rate',
  remove: 'Remove',
  removeAccess: 'Remove Access',
  removeAgencyConnectionMsg:
    'This will remove the agency access from this operation. However, any agents directly connected to this operation will still have access.',
  removeAgencyLocation: 'Remove Agency/Location?',
  removeAgencyLocationWarning: 'This will remove the agency/location connection. Are you sure?',
  removeAnalyticFromView: 'Remove this analytic from view?',
  removeBillingPartyMsg:
    'Are you sure you want to remove this user from billing responsibility for this account? Without a user responsible for billing on the account, you will not be able to collect samples in the field.',
  removeBillingResponsibility: 'Remove Billing Responsibility',
  removeDueDate: 'Remove Due Date',
  removeFieldMsg:
    'Removing this field from your operation will mean the field and its associated data is no longer available to you. This cannot be undone.',
  removeFieldsFromProject: 'Remove fields from project',
  removePriority: 'Remove Priority',
  removeSample: 'Remove Sample',
  removeSearchBounds: 'Remove search bounds',
  removeSelected: 'Remove Selected',
  removeSelectedFields: 'Remove selected fields',
  removed: 'removed',
  removedFromPlan: 'Removed from plan',
  replicaType: 'Replica Type',
  report: 'Report',
  requestFailed: 'Request failed. Please try again later.',
  requiredField: 'Required Field',
  rerun: 'Rerun',
  reselectDensity: 'Reselect Density',
  resendCode: 'Resend code',
  reset: 'Reset',
  resetMap: 'Reset Map',
  resetPoints: 'Reset Points',
  residue: 'Residue',
  resistanceOptions: 'Resistance Options',
  restrictedGeography: 'Restricted Geography',
  restrictedGeographyFromMsg: 'Sample is from',
  restrictedGeogrpahyProtocolsMsg: 'Follow proper handling protocols',
  restricted_panel_description:
    'Nutrient analysis is not available for restricted soil at this time',
  resultInPatternInvoiceMsg: 'This will result in EarthOptics invoicing',
  resultInPatternPaymentMsg: 'This will result in EarthOptics making payment to',
  results: 'Results',
  resultsDownloadFailure: 'Error downloading results. Try again later.',
  resultsDownloadSuccess: 'Results download has been completed successfully.',
  resultsUploaded: 'Results Uploaded',
  retry: 'Retry',
  return: 'Return',
  returned: 'Returned',
  revenue: 'Revenue',
  reverseIndexSequence: 'Reverse Index Sequence',
  reversePrimer: 'Reverse Primer',
  reviewOrder: 'Review order',
  rhizosphere: 'Rhizosphere',
  risk: 'Risk',
  riskEstimate: 'Risk Estimate',
  riskMap: 'Risk Map',
  rndAttributes: 'R&D Attributes',
  rndProtocols: 'R&D Protocols',
  rndSample: 'R&D Sample',
  role: 'Role',
  rootBall: 'Root Ball',
  rootTissue: 'Root Tissue',
  rootworm: 'Rootworm',
  rotate: 'Rotate',
  runPlanned: 'Run Planned',
  runSheetDownloadFailed: 'Failed to download run sheet. Try again later.',
  rwSingle: 'RW Single',
  rwStack: 'RW Stack',
  rx: 'Rx',
  rxBase: 'Rx Base',
  rxBaseLayer: 'Rx Base Layer',
  rxDetails: 'Rx Details',
  rxName: 'Rx Name',
  salesOrderCreatedMsg: 'Sales order creation initiated!',
  sample: 'Sample',
  sampleAlreadyExistsMsg:
    "That barcode is associated with an existing sample. If this is meant to be a replicate sample, please go to 'Add Sample'. If you intend to create a new sample in the system, please choose a different barcode.",
  sampleAlreadyInWellMsg: 'Sample already exists in another well, please use a replicate',
  sampleBarcode: 'Sample Barcode',
  sampleCancelledFailureMsg: 'Cancelling the sample failed',
  sampleCancelledSuccessMsg: 'Sample has been cancelled successfully',
  sampled: 'Sampled',
  sampleDate: 'Sample Date',
  sampleDeletedMsg: 'Sample deleted successfully',
  sampleExecution: 'Sample Execution',
  sampleIdMap: 'Sample ID Map',
  sampleMass: 'Sample Mass',
  sampleMustBeOnField: 'Sample must be on the field',
  sampleNotFound: 'Sample Not Found',
  sampleNotFoundMsg: 'No sample instance matching this barcode was found',
  sampleOnly: 'Sample Only',
  samplePlan: 'Sample Plan',
  samplePlanCancelledSuccess: 'Sampling plan cancelled!',
  samplePlanDetails: 'Sample Plan Details',
  samplePlanReadThreshold: 'Sample Plan Read Threshold',
  samplePlanStatus: 'Sample Plan Status',
  samplePlanTracking: 'Sample Plan Tracking',
  samplePlans: 'Sample Plans',
  samplePlansAwaiting: 'Sample Plans Awaiting Shipment',
  samplePlusScan: 'Sample + Scan',
  samplePricing: 'Sample Pricing',
  sampleRisk: 'Sample Risk',
  sampleSheet: 'Sample Sheet',
  sampleStatus: 'Sample Status',
  sampleSummary: 'Sample Summary',
  sampleTiming: 'Sample timing',
  sampleType: 'Sample Type',
  sampleUpdated: 'Sample updated',
  sampledBy: 'Sampled By',
  sampledByExternalLabMsg: 'Plans with external lab samples cannot be sampled by EarthOptics',
  sampled_not_shipped: 'Sampled, not shipped',
  sampler: 'Sampler',
  samplerInstructions: 'Sampler Instructions',
  samplerNotes: 'Sampler Notes',
  samplerOnly: 'Sampler Only',
  samples: 'Samples',
  samplesInPool: 'Samples in Pool',
  samplesTaken: 'Samples Taken',
  samples_not_complete: 'Samples Not Complete',
  sampling: 'Sampling',
  samplingAt: 'Sampling At',
  samplingHistory: 'Sampling History',
  samplingPlan: 'Sampling Plan',
  samplingPlanCreated: 'Sampling plan created',
  samplingPlanErrors: 'Sampling plan errors',
  samplingPlanInProgress: 'Sampling plan creation in progress',
  samplingPlanUpdatedFailureMsg: 'Sampling plan update failed. Please refresh and try again.',
  samplingPlanUpdatedMsg: 'Sampling plan updated successfully!',
  samplingPoints: 'Sampling Points',
  samplingTiming: 'Sampling Timing',
  samplingZones: 'Sampling Zones',
  sampling_at_risk: 'Sampling at Risk',
  sampling_overdue: 'Sampling Overdue',
  save: 'Save',
  saveBillingInfo: 'Save Billing Info',
  saveChanges: 'Save Changes',
  saveContactInfo: 'Save Contact Info',
  saveContractChanges: 'Save Contract Changes',
  saveCostsByTilling: 'Save costs by tilling only where you need to',
  saveZoneConfig: 'Save Zone Configuration?',
  saved: 'Saved',
  savedZoneConfigs: 'Saved Zone Configs',
  scan: 'Scan',
  scanBarcodeMsg: 'Scan Plate Barcode',
  scanDataConfirmed: 'Scan data confirmed',
  scanDataReceived: 'Scan data have been received',
  scanNewBarcodeMsg: 'Scan New Plate Barcode',
  scanOnly: 'Scan Only',
  scanTimeframe: 'Once scans are confirmed, results should be available within an hour',
  scanned: 'Scanned',
  scannedBy: 'Scanned By',
  scanner: 'Scanner',
  scanningAt: 'Scanning At',
  scanningTime: 'Scanning Time',
  scn: 'SCN',
  score: 'Score',
  sds: 'SDS',
  sdsFungicide: 'SDS + Fungicide',
  sdsFungicideNematicide: 'SDS + Fungicide + Nematicide',
  sdsNematicide: 'SDS + Nematicide',
  sdsuLime: 'SDSU Lime',
  sdsuPhosphorusBray: 'SDSU/MN Phosphorus (Bray)',
  sdsuPhosphorusOlsen: 'SDSU/MN Phosphorus (OLSEN)',
  sdsuPotassium: 'SDSU/MN Potassium',
  search: 'Search',
  searchAccount: 'Search for An Account',
  searchAccountOrViewAll: 'Search for an Account or View All',
  searchAgency: 'Search Agency',
  searchAgencyName: 'Search Agency Name',
  searchAnalytic: 'Search Analytic',
  searchByAccount: 'Search By Account',
  searchByBarcode: 'Search By Barcode',
  searchByName: 'Search by name',
  searchByUser: 'Search by user',
  searchByUserAgency: 'Search by user/agency',
  searchCounty: 'Search County',
  searchCountyError: 'An error occurred while searching for the specified county. Please Refresh.',
  searchCurrentBounds: 'Search current bounds',
  searchForDealer: 'Search for a dealer',
  searchForGrower: 'Search for a grower',
  searchOperation: 'Search Operation',
  searchOperationOrAgency: 'Search Operation or Agency',
  searchPayments: 'Search Payments',
  searchSampleMsg: 'Search for a sample by barcode or UUID',
  searchSampler: 'Search sampler',
  searchSamplerScanner: 'Search sampler/scanner',
  searchUsers: 'Search Users',
  searching: 'Searching',
  season: 'Season',
  secondaryAnalytic: 'Secondary Analytic',
  secondaryAnalyticMsg:
    'This analytic will be displayed if the current analytic does not have results.',
  seeHowItWorks: 'See How It Works',
  seed: 'Seed',
  seedCatalog: 'Seed Catalog',
  seeDetailsBelow: 'See details below.',
  seedMissing: 'Hybrid options have not been added to crop plan. Click to review',
  seedResistance: 'Seed Resistance',
  seedRisks: 'Seed Risks',
  seedRx: 'Seed Rx',
  seedTreatment: 'Seed Treatment',
  seedTreatments: 'Seed Treatments',
  seed_treatment: 'Seed Treatment',
  seeds: 'seeds',
  seeDealerPrice: 'See dealer price',
  select: 'Select',
  selectAccount: 'Select account',
  selectAgencyMsg: 'Select An Agency To View',
  selectAgencyReassignMsg: 'Select the Agency they should belong to or create a new one',
  selectAgencyToBeginMsg: 'To Start, Select An Agency From the Dropdown Above',
  selectAlertsToDismiss: 'Select Alerts to Dismiss',
  selectAlertsToSnooze: 'Select Alerts to Snooze',
  selectAll: 'Select All',
  selectAllOrNone: 'Select all or none',
  selectClus: 'Select CLUs',
  selectDiscountProgram: 'Select a Discount Program',
  selected: 'selected',
  selectedField: 'Selected field',
  selectedForAssignment: 'Selected for assignment',
  selectExternalLab: 'Select an external lab',
  selectField: 'select field',
  selectFieldsToMerge: 'Select field boundaries to merge that have not had previous sample plans.',
  selectFieldsToSplit: 'Select field boundaries to split that have not had previous sample plans.',
  selectFileToUploadMsg: 'Please select a file to upload',
  selectOne: 'Select One',
  selectProductCatalog: 'Select Product Catalog',
  selectZoneDensity: 'Select Zone Density',
  selectZones: 'Select Zones',
  selectZonesToMerge: 'Select zones to merge.',
  selfSampling: 'Self Sampling',
  selfScanning: 'Self Scanning',
  seller: 'Seller',
  sellingPrice: 'Selling Price',
  sendCodeTo: 'Send code to',
  sendRequestInstructions: 'Send Reset Instructions',
  sendTo: 'Send To',
  sequencedData: 'Sequenced Data',
  serverErrorMsg: 'An error occurred with the server, please refresh and try again.',
  setEqualAreaSplit: 'Set Equal Area Split',
  setEqualRanges: 'Set Equal Ranges',
  setPassword: 'Set Password',
  setSamplingPoints: 'Set Sampling Points',
  setSamplingZones: 'Set Sampling Zones',
  setSplitDensity: 'Set Split Density',
  settings: 'Settings',
  settled: 'Settled',
  setupASamplePlan:
    'To set up a sample plan, you need to map field boundaries. You can do this a number of ways:',
  setupZonesInstructionsMsg:
    'Set up zones for Bio analysis, then generate points for Nutrient analysis and Pro scanning',
  shapefile: 'Shapefile',
  shapefileDownloadMsg:
    'This can take 20 minutes or more. Feel free to leave the page. (Link will appear in Download dropdown when available.)',
  shapefileDownloadSuccess: 'Shapefile download has been completed successfully',
  shelf: 'Shelf',
  shipmentCreatedMsg: 'Shipment Created Successfully',
  shipmentUpdatedMsg: 'Shipment Updated Successfully',
  shipped: 'Shipped',
  shippedDate: 'Shipped Date',
  shippedLabels: 'Shipped Labels',
  shipping: 'Shipping',
  shippingFrom: 'Shipping From',
  shippingLabel: 'Shipping Label',
  shippingLabels: 'Shipping Labels',
  shippingLabelsInProgress: 'Shipping Labels in Progress',
  shippingRegulatedSoil: 'Shipping regulated soil? View or download these documents',
  shippingTo: 'Shipping To',
  shotgun: 'Shotgun',
  shotgunAnalysis: 'Shotgun Analysis',
  shotgunQpcr: 'Shotgun + qPCR',
  show: 'Show',
  showDcpPoints: 'Show DCP points',
  showInUI: 'Show in UI',
  showMe: 'Show Me',
  showPastPlans: 'Show Past Sample Plans',
  showing: 'Showing',
  sidedress: 'Sidedress',
  signIn: 'Sign In',
  signInDifferentWay: 'Sign in a different way',
  signInWithPassword: 'Sign in with password',
  signed: 'Signed',
  signedAt: 'Signed At',
  sikora: 'Sikora',
  silver: 'Silver',
  sla_at_risk: 'SLA at Risk',
  sla_overdue: 'SLA Overdue',
  smallDepositTodayMsg: 'Small deposit today, remainder as you complete field analysis',
  snoozeAlerts: 'Snooze Alerts',
  soil: 'Soil',
  soilAnalysis: 'Soil Analysis',
  soilAppliedProducts: 'Soil Applied Products',
  soilCollection: 'Soil Collection',
  soilCollectorOutput: 'SoilCollector Output',
  soilDataNotFound: 'Test Results Unavailable',
  soilDataNotFoundDescription:
    'Analysis has not been completed for this field. Removal prescriptions can be created with yield data or imagery.',
  soilDilution: 'Soil Dilution',
  soilProcessing: 'Soil Processing',
  soilScan: 'Soil Scan',
  soilTest: 'Soil Test',
  soilTexture: 'Soil Texture',
  soilTestValue: 'Soil Test Value',
  soilTextureAnalysis: 'Soil Texture Analysis',
  soil_applied: 'Soil Applied',
  soil_appliedMissing:
    'Soil applied protection options have not been added to crop plan. Click to review',
  sorghum: 'Sorghum',
  sort: 'Sort',
  source: 'Source',
  sourcePlateBarcode: 'Source Plate Barcode',
  sourcePlates: 'Source Plates',
  sourcePools: 'Source Pools',
  soybeanFoliar: 'Soybean Foliar',
  soybeanPathogens: 'Soybean Pathogens',
  soybeanPlan: 'Soybean Plan',
  soybeans: 'Soybeans',
  soybeansPlan: 'Soybeans Plan',
  split: 'Split',
  splitCore: 'Split core',
  splitDensity: 'Split Density',
  splitDensityBarcode: 'Split Density Barcode',
  splitDensityDesc:
    'Split density allows you to run biological analysis at a lower density than nutrient analysis. Both Pressure Panel and Performance Panel will be included for any selected biological zones.',
  splitDensityForBio: 'Split density for biological + nutrient analysis',
  splitDensityGroup: 'Split Density Group',
  splitEveryZoneError: 'Could not split every zone.',
  splitField: 'Split Field',
  splitFields: 'Split Fields',
  splitParentFieldAgain: 'Split parent field again',
  splitZones: 'Split Zones',
  splitZonesSuccess: 'Successfully split zones!',
  splits: 'Splits',
  ssurgo: 'Soil Type (SSURGO)',
  ssurgoOmDisclaimer:
    'In the event a zone is missing a value for OM, a weighted average from the SSURGO soil survery will be used.',
  startedWhichOperation: 'To get started, which operation are we working with?',
  starter: 'Starter',
  state: 'State',
  stateCounty: 'State & County',
  status: 'Status',
  sterilized: 'Sterilized',
  storage: 'Storage',
  storePlate: 'Store Plate',
  stratification: 'Stratification',
  stratify: 'Stratify',
  street: 'Street',
  submit: 'Submit',
  submitting: 'Submitting',
  subscriptionCoversAnalysisMsg:
    'Subscription covers analysis for the target percent of farm ordered. If additional acres are added to the account, they will be included in the Total Farm Acres, and the corresponding Analysis Acres. Any additional acres or analysis ordered above and beyond the subscribed analysis on the Analysis Acres will incur additional charges at prices associated with the Subscription.',
  subscriptionRenewal: 'Subscription Renewal',
  success: 'Success',
  successCreatedShippingLabelBatch:
    'Successfully created shipping label batch. Your download will be ready in a few minutes.',
  successfullyCreatedInvoice: 'Successfully created invoice in Quickbooks.',
  successfullyCreatedNoQB: 'Successfully created invoice without sending it to Quickbooks.',
  successfullyRemovedPrescription: 'Successfully removed prescription',
  'sugar-beets': 'Sugar Beets',
  sugarBeetPestsDiseases: 'Sugar Beet Pests & Disease',
  sugarBeets: 'Sugar Beets',
  sugar_beets: 'Sugar Beets',
  summary: 'Summary',
  summaryOfSamplePlanMsg:
    'This is a summary of the sample plan as ordered. The final invoice will reflect any potential adjustments.',
  summarySheet: 'Summary Sheet',
  superadmin: 'Super Admin',
  support: 'Support',
  supportUnderTwoAcres: 'Our system does not support fields under 2.1 acres. Please resize.',
  sureCancelPlan: 'Are you sure you want to cancel this plan?',
  sureMarkNotReady: 'Are you sure you want to mark the plan "Not Ready"?',
  surface: 'Surface',
  switchUserToAgent: 'Switch User to Agent',
  tags: 'Tags',
  tarSpot: 'Tar Spot',
  target: 'Target',
  targetBaseSaturation: 'Target Base Saturation',
  targetDilution: 'Target Dilution',
  targetPhosphorus: 'Target Phosphorus',
  targetPotassium: 'Target Potassium',
  targetYield: 'Target Yield',
  tbd: 'TBD',
  tcRerun: 'TC Rerun',
  technical: 'Technical',
  temp: 'Temp',
  temperature: 'Temperature',
  termsAndConditions: 'Terms and Conditions',
  territory: 'Territory',
  territoryCreated: 'Commercial Territory Saved!',
  territoryDeletedMsg: 'Territory Deleted Successfully!',
  territoryName: 'Territory Name',
  territorySavingErrMsg: 'There was an error saving the territory. Please refresh and try again.',
  territoryUsers: 'Territory Users',
  testResults: 'Test Results',
  texture: 'Texture',
  textureAndPh: 'Texture and pH',
  texturePhSampleDetails: 'Texture and pH sample details',
  thanksForJoiningPatter: 'Thanks for joining EarthOptics!',
  thatEmailHasAcctMsg:
    'That email already has an account with EarthOptics. Use a different email, or contact support to have that user moved to this Agency.',
  theTypicalMidwestFarm:
    'The typical <b>Midwest</b> farm working with EarthOptics can <b>boost profits by</b>',
  thisFieldIsRequired: 'This field is required.',
  thisMayTakeSeconds: 'This may take several seconds.',
  tillMapper: 'TillMapper',
  tillMapperActivated: 'TillMapper activated successfully',
  tillMapperAvailable: 'This field has TillMapper available - activate it below for',
  tillMapperAvailableForField: 'TillMapper is available for this field!',
  tillMapperDensityMsg: 'TillMapper density must be greater than 8 and less than 10.5.',
  tillMapperIsLoading: 'TillMapper is loading - check back soon for your custom results',
  tillMapperOrdered: 'An order for TillMapper has been placed for this field',
  tillMapperPoints: 'TillMapper Points',
  till_rx_only: 'TillMapper Only',
  tillage: 'Tillage',
  tillageCosts: 'Tillage costs',
  tillage_map_generation: 'Tillage Map Generation',
  tillage_measurement: 'Tillage Measurement',
  timing: 'Timing',
  to: 'To',
  toBeSampledBy: 'To be sampled by',
  toBeScannedBy: 'To be scanned by',
  toCollection: 'to collection',
  toOrderFirstPlan: 'To order your first sample plan, click ',
  todaysDate: "Today's date",
  toggleFieldSelection: 'toggle field selection',
  tooHighMsg: 'Value is too high',
  tooLowMsg: 'Value is too low',
  tooManyLabelsMsg: 'Please generate fewer than 80 labels at a time.',
  total: 'Total',
  totalAcres: 'Total Acres',
  totalAdjustments: 'Total adjustments',
  totalApplied: 'Total Applied',
  totalCarbon: 'Total Carbon',
  totalCarbonAnalysis: 'Total Carbon Analysis',
  totalCompletedSamples: 'Total Completed Samples',
  totalDna: 'Total DNA',
  totalDnaMultiplier: 'Total DNA Multiplier',
  totalFarm: 'Total Farm',
  totalFarmAcres: 'Total Farm Acres',
  totalFarmActivatedMsg: 'Total Farm activated successfully!',
  totalFarmDismissedMsg: 'Total Farm dismissed successfully!',
  totalFarmCancelledMsg: 'Total Farm cancelled successfully!',
  totalFarmOperationMsg: 'Fertility Rx, Seed Rx, and Crop Planning on every acre, every year.',
  totalInorganicCarbon: 'Total Inorganic Carbon',
  totalInorganicCarbonAnalysis: 'Total Inorganic Carbon Analysis',
  totalOrders: 'Total orders',
  totalOrganicCarbon: 'Total Organic Carbon',
  totalPcrDna: 'Total PCR DNA',
  totalPlannedSamples: 'Total Planned Samples',
  totalPrice: 'Total Price',
  totalReads: 'Total Reads',
  totalReturn: 'Total Return',
  totalSamplesTaken: 'Total Samples Taken',
  totalScanCoverage: 'Total Scan Coverage',
  totals: 'Totals',
  trackPackage: 'Track Package',
  traits: 'Traits',
  transactionApprovalMsg:
    'By approving these transaction, they will be sent to accounting for processing.',
  transit: 'Transit',
  treatment: 'Treatment',
  treatmentMissing: 'Seed treatment options have not been added to crop plan. Click to review',
  triStateLimeTo6: 'Tri State Lime to 6.0',
  triStateLimeTo65: 'Tri State Lime to 6.5',
  triStateLimeTo68: 'Tri State Lime to 6.8',
  triStateOhLimeTo6: 'Tri State (OH) Lime to 6.0',
  triStateOhLimeTo65: 'Tri State (OH) Lime to 6.5',
  triStateOhLimeTo68: 'Tri State (OH) Lime to 6.8',
  triStatePhosphorus: 'Tri State Phosphorus',
  triStatePotassium: 'Tri State Potassium',
  turnOnTillMapper:
    "Turn on TillMapper today - view your compaction maps and generate custom tillage Rx's",
  twoDayCollnWarnMsg:
    'Collections for this field were collected over 48 hours apart. This can negatively impact map quality if scanning conditions were inconsistent. Consider re-scanning.',
  twoDayCollnWarnTitle: 'Long collection timeframe',
  twoWaysToUpload: 'Two ways to upload field boundaries and data',
  type: 'Type',
  unableGenerateZoneGroupings: 'Unable to generate zone groupings. Please refresh.',
  unableToConnectAgency: 'Unable to connect agency. Please refresh and try again.',
  unableToDisconnectAgency: 'Unable to disconnect agency. Please refresh and try again.',
  unableToDisplayZoneGroupingError: 'Unable to display zone groupings.',
  unableToLoadZonesMsg: 'Unable to load zones. Try again later.',
  unableToSavePlanMsg: 'Unable to save plan. Please refresh and try again.',
  unassigned: 'Unassigned',
  unassignedPlans: 'Unassigned Plans',
  unassigned_all: 'Unassigned - All',
  unassigned_eo: 'Unassigned - EO',
  unassigned_pattern: 'Unassigned - Pattern',
  unassigned_ready_to_sample: 'Unassigned, Ready to Sample',
  unavailable: 'Unavailable',
  uncheckAll: 'Uncheck All',
  unconfirmed: 'Unconfirmed',
  understandCompaction: 'Understand compaction on a sub-field level',
  understandCompactionPitch: "Generate 10m maps and custom Rx's",
  unit: 'Unit',
  units: 'Units',
  unknown: 'Unknown',
  upcomingCrop: 'Upcoming Crop',
  update: 'Update',
  updateAddressFailMsg: 'Failed to update address. Please refresh and try again.',
  updateConcentrationDataMsg:
    'There is already concentration data associated with this plate. If you upload a new file it will overwrite the concentration data associated with each well. Are you sure you want to continue?',
  updateData: 'Update Data',
  updateFieldCropErrorMsg: 'Error updating field crop plan. Please refresh and try again.',
  updateFieldCropSuccessMsg: 'Field crop plan updated successfully!',
  updatePrescriptionErrorMsg: 'Error updating prescription. Please refresh and try again.',
  updatePrescriptionZoneErrorMsg: 'Error updating prescription zone. Please refresh and try again.',
  updatePrescriptionZoneSuccessMsg: 'Prescription zone updated successfully!',
  updateUserInfoFailMsg: 'Failed to update user info. Please refresh and try again.',
  updated: 'Updated',
  updatedUserRole: 'Updated User Role!',
  updatingAgencyErrorMsg: 'There was an error updating the agency. Please refresh and try again.',
  updatingAgencySuccessMsg: 'The agency was successfully updated!',
  updatingAlertError: 'There was an error updating the alert',
  updatingUserRoleErrorMsg:
    'There was an error updating the user role. Please refresh and try again.',
  upload: 'Upload',
  uploadAbortedMsg: 'File upload was aborted.',
  uploadAnalyticImagery: 'Upload Analytic Imagery',
  uploadBoundaries: 'Upload Boundaries',
  uploadBulkPlate: 'Upload Bulk Plate',
  uploadCsv: 'Upload CSV File',
  uploadDocument: 'Upload Document',
  uploadErrorMsg: 'There was an error uploading file.',
  uploadFailedMsg: 'Upload Failed.',
  uploadFile: 'Upload file',
  uploadFileInstrs: 'Supported file types: .json, .geojson, .zip',
  uploadLogo: 'Upload Logo',
  uploadPoints: 'Upload Points',
  uploadProgress: 'Upload Progress',
  uploadResults: 'Upload Results',
  uploadSamples: 'Upload Samples',
  uploadSamplesCsvMsg: 'Upload Sample Wells from CSV File',
  uploadShapefiles: 'Upload Shapefiles',
  uploadShapefilesData: 'Upload shapefiles or planting / harvest data',
  uploadShapefilesOfYourFields:
    'Upload shapefiles of your fields here - make sure you include all files (.shp, .shx, .dbf, etc/) and group those files in a single zip file',
  uploadSuccessMsg: 'Upload Successful!',
  uploadZipFile: 'Upload Zip File',
  uploadZipTip: 'The uploaded file must be in a .zip or .gz format.',
  upload_zones: 'Upload Zones',
  uploadedResults: 'Uploaded Results',
  uploading: 'Uploading',
  upsLabels: 'UPS Labels',
  useImagery: 'Create a prescription from imagery',
  user: 'User',
  userAgreements: 'User Agreements',
  userCreatedMsg: 'User created successfully!',
  userLocaleUpdated: 'User locale updated!',
  userRoleUpdated: 'User role updated!',
  userScanningUpdated: 'User scanning capability updated!',
  userWillBeAssignedTip:
    "Note: The user being created will be assigned as the 'Primary Agent' for this Agency which means their contact information will be used for billing purposes.",
  users: 'Users',
  value: 'Value',
  viewAdditionalResults: 'View Additional Results',
  viewAllAccounts: 'View All Accounts',
  viewBenefits: 'View Benefits',
  viewDemo: 'View Demo',
  viewDemoAccount: 'View Demo Account',
  viewDetails: 'View Details',
  viewEdit: 'View/Edit',
  viewEditLabel: 'View/Edit Label',
  viewField: 'View field',
  viewMyAccounts: 'View My Accounts',
  viewMyReport: 'View My Report',
  viewNewField: 'View new field',
  viewOffer: 'View Offer',
  viewOrderSummary: 'View Order Summary',
  viewProject: 'View project',
  viewResults: 'View Results',
  viewSampleReport: 'View Sample Report',
  viewSampleStatus: 'View Sample Status',
  viewSamples: 'View samples',
  viewScanCoverage: 'View Scan Coverage',
  waiting: 'Waiting',
  'waiting to sample': 'Waiting to Sample',
  waitingToSample: 'Waiting to Sample',
  waitingToScan: 'Waiting to Scan',
  webApp: 'Web App',
  weighedAndGround: 'Weighed & Ground',
  weight: 'Weight',
  welcomeBack: 'Welcome back',
  well: 'Well',
  wells: 'Wells',
  wet: 'Wet',
  whatAfterSplit: 'What would you like to do after splitting the field?',
  whatsTheNameOfAccount: "What's the name of the farming account?",
  whatsTheNameOfAccountTooltip: 'This could be a farm name, or an individual.',
  wheat: 'Wheat',
  whereSendInvoiceMsg: 'Let us know where we should send any outstanding invoices for this account',
  width: 'Width',
  willReceiveLinkMsg: 'You will receive an email with a link to reset your password',
  with: 'With',
  wontSample: "Won't Sample",
  worms: 'Worms',
  year: 'Year',
  yes: 'Yes',
  yield: 'Yield',
  yieldData: 'Yield Data',
  yieldDataNotFound: 'Yield Data Not Found',
  yieldDataNotFoundDescription: 'Here are a few options:',
  yieldImpact: 'Yield Impact',
  yieldMap: 'Yield Map',
  yieldZones: 'Yield Zones',
  youHaveNoApprovedMsg:
    "You have no 'Approved' payments. To view 'Open' payments, click the 'Open' selector above.",
  yourPasswordChanged: 'Your password has been updated!',
  zipcode: 'Zipcode',
  zone: 'Zone',
  zoneAnalysisDefaultBioMsg:
    'Set the zones that will be used for TruBio analysis. Target 20 ac density.',
  zoneAnalysisDefaultScanPointMsg:
    'Set the points that will be used for TruNutrient analysis. Target 5 ac density.',
  zoneAnalysisTNTTScanPointMsg:
    'Set the points that will be used for TruNutrient and TruTill analysis. Target 5 ac density.',
  zoneAnalysisTruTillScanPointMsg:
    'Set the points that will be used for TruTill analysis. Target 5 ac density.',
  zoneDetails: 'Zone Details',
  zones: 'Zones',
  zonesAtRisk: 'zones at risk',
  zymo: 'Zymo',
  zymoDilution: 'Zymo Dilution',
  zymoRevvityDescription: 'zymo lysis and magbead with TBD method for chelating metal ions',
  zymoSilicaDescription: 'zymo lysis and silica filter isolation',
  zymoSoil: 'Zymo Soil',
} as const;

export default ENGLISH_STRINGS;
