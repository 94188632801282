import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Group } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { featureCollection } from '@turf/helpers';
import { useOrderFormContext } from 'apps/ZoneAnalysisV3/orderFormContext';
import {
  useAllSkusQuery,
  useNumericRouteParams,
  useOperationUsersQuery,
  usePriceEstimateQuery,
} from 'apps/ZoneAnalysisV3/queries';

import { EXTERNAL_LAB_SKU_ID, STANDARD_NUTRIENT_SKU_ID } from 'constants/pricing';
import { NITRATE_PANEL } from 'constants/products';
import { MINIMUM_SCAN_POINTS_COUNT } from 'constants/samplePlanning';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { getBeforeAndAfterDecimal } from 'util/numUtils';
import { checkSkuHasProductFromList, getSkuById } from 'util/pricing';
import { operationAllowsMultiplePlans } from 'util/samplePlan';
import { getSampleGroupings, setupSamplesToSubmitV3 } from 'util/samplePlanV3';
import { dollarsPerAcreToRealPerHectare } from 'util/units';
import showToast from 'actions/toastActions';
import { RootState } from 'store';
import { getFieldGeometry } from 'store/fields/thunks';
import { FieldType } from 'store/fields/types';
import { deleteSamplePlan, postSamplePlan } from 'store/samplePlans/requests';
import { SamplePlanType } from 'store/samplePlans/types';
import { userIsSuperAdmin } from 'store/user/selectors';

import AnalysisProductPrice from './AnalysisProductPrice';
import { getNavButtonsText } from './utils';

type Props = {
  activeStepNumber: number;
  onStepClick: (nextStep: number) => void;
  fieldFeatureCollection: FieldType;
};

const StepperFooter = ({ activeStepNumber, onStepClick, fieldFeatureCollection }: Props) => {
  const { operationId, fieldId, planId } = useNumericRouteParams();
  const navigate = useNavigate();
  const language = useBroswerLanguage();
  const dispatch = useDispatch();
  const { isSuperAdmin } = useSelector((state: RootState) => ({
    isSuperAdmin: userIsSuperAdmin(state),
  }));

  const { data: allSkus } = useAllSkusQuery();
  const { data: operationUsers } = useOperationUsersQuery();
  const { priceEstimateQuery } = usePriceEstimateQuery();
  const form = useOrderFormContext();
  const formValues = form.getValues();
  const transformValues = form.getTransformedValues();
  const prevNext = getNavButtonsText(formValues, form.getTransformedValues(), language);
  const { previous, next } = prevNext[activeStepNumber];
  const isFinalStep = activeStepNumber === Object.keys(prevNext).length - 1;
  const isMultiPlanCapable = operationAllowsMultiplePlans(operationUsers?.users);
  const isExternalLab = formValues.nutrientAnalysisPackageId === EXTERNAL_LAB_SKU_ID;
  const price = dollarsPerAcreToRealPerHectare(
    priceEstimateQuery.data?.estimated_list_price_per_acre || 0,
    language,
  );
  const formattedPrice = getBeforeAndAfterDecimal(Number(price));

  const orderMutation = useMutation({
    onError: (error) => {
      showToast(error.message, 'error');
    },
    mutationFn: async () => {
      const productizedFeatures = setupSamplesToSubmitV3(
        formValues,
        transformValues,
        fieldFeatureCollection,
        allSkus || [],
      );

      const is590Analysis = getSkuById(
        allSkus || [],
        formValues.nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
      )?.is_590_analysis;

      const body: Partial<SamplePlanType> = {
        agency_id: formValues.agencyId,
        assigned_to_id: formValues.sampledById || null,
        assigned_to_scan_id: formValues.scannedById || null,
        field_id: fieldFeatureCollection.features[0].properties.id,
        is_590_analysis: is590Analysis,
        is_pro: formValues.isProScan,
        is_till_rx: formValues.isTillRx,
        name: formValues.samplePlanName,
        notes: formValues.samplerInstructions,
        nutrient_external_lab_identifier: formValues.externalLabKey || undefined,
        ready_to_sample: formValues.readyToSample,
        rnd: formValues.rnd,
        sample_timing: formValues.sampleTiming || undefined,
        sku_prices: priceEstimateQuery.data?.price_summary, // 👈 causes 500
        zone_type: formValues.creationOption,
        // @ts-ignore
        samples: featureCollection(productizedFeatures),
        is_not_billed: formValues.isNotBilled,
      };

      if (formValues.isSplitDensity) {
        const nutrientSku = getSkuById(
          allSkus || [],
          formValues.nutrientAnalysisPackageId || STANDARD_NUTRIENT_SKU_ID,
        );
        body.sample_groupings = getSampleGroupings(
          productizedFeatures,
          checkSkuHasProductFromList(nutrientSku, [NITRATE_PANEL]),
        );
      }
      if (planId && isMultiPlanCapable) {
        await postSamplePlan(body);
        return deleteSamplePlan(planId);
      }
      return postSamplePlan(body);
    },
    onSuccess: () => {
      dispatch(getFieldGeometry(fieldId));
      navigate(`/orders/${operationId}`);
    },
  });

  const isSampleAndScanMissing =
    transformValues.isSampleAndScan &&
    (!transformValues.zonesCount || !transformValues.scanPointsCount);
  const isScanPointsOnlyMissing =
    transformValues.isScanPointsOnly && !transformValues.scanPointsCount;
  const isSampleOnlyMissing = transformValues.isSampleOnly && !transformValues.zonesCount;
  const iScanPointsBelowMinimum =
    transformValues.isProOrTillRx && transformValues.scanPointsCount < MINIMUM_SCAN_POINTS_COUNT;
  const isExternalLabMissing = isExternalLab && !formValues.externalLabKey;
  // Superadmins can assign external labs to any EO -- otherwise they must assign to a specific user
  const isExternalLabAssignedToMissing =
    !activeStepNumber && isExternalLab && !formValues.sampledById && !isSuperAdmin;

  const isConfirmDisabled = () =>
    !!priceEstimateQuery.error ||
    priceEstimateQuery.isPending ||
    isSampleAndScanMissing ||
    isScanPointsOnlyMissing ||
    isSampleOnlyMissing ||
    isExternalLabMissing ||
    iScanPointsBelowMinimum;

  const disableNext =
    (isFinalStep && isConfirmDisabled()) ||
    !transformValues.hasProducts ||
    isExternalLabAssignedToMissing;
  return (
    <Group
      bg="var(--mantine-color-body)"
      justify="space-between"
      py="md"
      mx="auto"
      w="100%"
      maw={1000}
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: 'var(--mantine-color-gray-3)',
      }}
    >
      <AnalysisProductPrice
        formattedPrice={formattedPrice}
        isPending={priceEstimateQuery.isPending}
        error={priceEstimateQuery.error}
      />
      <Group>
        {!!previous && (
          <Button
            size="lg"
            leftSection={<FaArrowLeft width={32} style={{ padding: 0 }} />}
            variant="default"
            onClick={() => onStepClick(activeStepNumber - 1)}
          >
            {previous}
          </Button>
        )}
        <Button
          size="lg"
          rightSection={isFinalStep ? null : <FaArrowRight width={32} style={{ padding: 0 }} />}
          loading={orderMutation.isPending}
          disabled={disableNext}
          onClick={() => {
            if (isFinalStep) {
              orderMutation.mutate();
            } else {
              onStepClick(activeStepNumber + 1);
            }
          }}
        >
          {next}
        </Button>
      </Group>
    </Group>
  );
};

export default StepperFooter;
