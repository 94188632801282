import {
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestPost,
  SERVICE_URL,
} from 'util/request';

import {
  CollectionConfirmationPayloadType,
  GroupedCollectionsListPayload,
  GroupedCollectionsOneFieldPayload,
  GroupedCollectionsResponse,
  OneFieldCollectionsResponse,
} from './types';

export const getGroupedCollections = async (
  query: GroupedCollectionsListPayload,
): Promise<GroupedCollectionsResponse> => {
  const params = new URLSearchParams({
    ...(query.desc ? { desc: 'True' } : {}),
    ...(query.name ? { name: query.name } : {}),
    ...(query.status ? { status: query.status } : {}),
    ...(query.sortBy ? { sortBy: query.sortBy } : {}),
    ...(query.groupBy ? { groupBy: query.groupBy } : {}),
    page: (query.page || 1).toString(),
  }).toString();

  return handleJsonResponse(await requestGet(`${SERVICE_URL}/eo_collections_grouped?${params}`));
};

export const getGroupedCollectionsForOneField = async (
  query: GroupedCollectionsOneFieldPayload,
  fieldId: number,
): Promise<OneFieldCollectionsResponse> => {
  const params = new URLSearchParams({
    ...(query.status ? { status: query.status } : {}),
  }).toString();

  return handleJsonResponse(
    await requestGet(`${SERVICE_URL}/field_eo_collections/${fieldId}?${params}`),
  );
};

/** Technically _disables_ the collection, but is a `DELETE` as far as API and UI are concerned. */
export const deleteCollection = async (id: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/eo_collection/${id}`));

export const requestConfirmCollections = async (body: CollectionConfirmationPayloadType) =>
  handleJsonResponse(
    await requestPost(`${SERVICE_URL}/eo_confirm_collections_assignment/`, { body }),
  );
