import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Center,
  Flex,
  Group,
  Input,
  Loader,
  Modal,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FeatureCollection, Geometry, GeometryCollection } from '@turf/helpers';

import {
  CUSTOM_FORMULA,
  CUSTOM_PRODUCT_ID,
  getCustomAgronomicProduct,
  IMAGERY,
  inputNames,
  LIME_FORMULAS,
  MAX_PRESCRIPTION_NAME_LENGTH,
  PHOSPHORUS_BRAZIL,
  POTASSIUM_BRAZIL,
  RAW_VALUE_KEY,
  REMOVAL_REC_FORMULAS,
  RX_TYPE_BUILDUP,
  RX_TYPE_REMOVAL,
  rxUnitToSymbol,
  SATURATION_BRAZIL,
  SOIL_TEST,
  YIELD,
} from 'constants/prescription';
import { allPhIds, PRO_EXPORT_ACRE_DENSITY_OPTIONS } from 'constants/results';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { convertDecimalToPercent, isNumber, roundTwoDecimal } from 'util/numUtils';
import {
  convertCCEForEditing,
  convertCCEForSubmission,
  convertNumericCustomZonesToString,
  convertStringCustomZonesToNumeric,
  convertTargetValueForEditing,
  convertTargetValueForSubmission,
  getCropOptions,
  getExportTypes,
  getFormulaOptions,
  getInputOptions,
  getSampleValueForPrescriptionAnalytic,
  getTillageOptions,
  getTimingOptions,
} from 'util/prescription';
import { getProNutrientMapLayer } from 'util/samplePlan';
import {
  getUnitBuAc,
  getUnitLbsAc,
  KgHaToLbsAc,
  realMetricTonToDollarsTon,
  sacaHaToBuAc,
} from 'util/units';
import { getString } from 'strings/translation';
import showToast, { ToastType } from 'actions/toastActions';
import { RootState } from 'store';
import { AnalyticType } from 'store/analytics/types';
import { AgronomicProductType, SeedType } from 'store/cropPlans/types';
import { getFieldGeometry } from 'store/fields/thunks';
import { FieldType } from 'store/fields/types';
import { InputType } from 'store/inputs/types';
import {
  downloadPrescriptionShapefile,
  postPrescriptionExternalAccount,
  putPrescription,
} from 'store/prescriptions/requests';
import getZonesForPrescription, { getProPrescriptions } from 'store/prescriptions/thunks';
import {
  PrescriptionType,
  PrescriptionZoneType,
  RxCustomZoneFormType,
} from 'store/prescriptions/types';
import { SampleFeatureType } from 'store/samples/types';
import { Accordion, Selector } from 'common';

import CustomZoneEditor from '../ScriptCreator/CustomZoneEditor';
import ProductSearch from '../ScriptCreator/ProductSearch';
import SeedSearch from '../ScriptCreator/SeedSearch';

import styles from './Settings.module.css';

type SettingsProps = {
  existingZones: PrescriptionZoneType[];
  field: FieldType;
  inputs: InputType[];
  prescription: PrescriptionType;
  samples: SampleFeatureType[];
  setIsSubmitting: (value: boolean) => void;
};

export type RxSettingsStateType = {
  activeDensity: string;
  activeFormula: string;
  agronomicProduct: AgronomicProductType | null;
  analytic: AnalyticType | null;
  cropIndex: number;
  crop: string;
  customConcentration: string;
  customMapLayer: string | null;
  customZones: RxCustomZoneFormType[];
  expectedYield: string;
  exportIndex: number;
  exportingScript: boolean;
  generateFrom: string;
  harvestLayer: string | null;
  imageryLayer: string | null;
  inputIndex: number;
  maxYield: string;
  minYield: string;
  maximumRate: string;
  minimumRate: string;
  name: string;
  percentApplication: string;
  pricePerTon: string;
  reloadPrescription: boolean;
  rxType: string;
  savedConfigName?: string;
  seed: SeedType | null;
  shouldSaveConfig?: boolean;
  showProModal: boolean;
  showSettings: boolean;
  sourceLayerGeojson: FeatureCollection<
    Geometry | GeometryCollection,
    { [RAW_VALUE_KEY]: number }
  > | null;
  targetValue: string;
  tillageIndex: number;
  timingIndex: number;
  triggerSave: boolean;
};

const Settings = ({
  existingZones,
  field,
  inputs,
  prescription,
  samples,
  setIsSubmitting,
}: SettingsProps) => {
  const dispatch = useDispatch();
  const language = useBroswerLanguage();
  const { acreage_unit } = field.features[0].properties;

  const isRemoval = prescription.composite_imagery_id || prescription.machine_data_id;

  const { analytics, currentUser, operation } = useSelector((state: RootState) => ({
    analytics: state.analytics.analytics,
    currentUser: state.user.currentUser,
    operation: state.operations.operationsById[field.features[0].properties.operation_id],
  }));

  const activeAnalytic = analytics.find((analytic) => analytic.id === prescription.analytic_id);
  const samplingPlanId = samples[0]?.properties.sampling_plan_id;
  const selectedPlan = field.features[0].properties.sampling_plans.find(
    (plan) => plan.id === samplingPlanId,
  );
  const proLayer =
    selectedPlan && activeAnalytic ? getProNutrientMapLayer(selectedPlan, activeAnalytic) : null;

  const formulaOptionsByCategory = getFormulaOptions(
    language,
    field,
    prescription.input?.nutrient,
    currentUser?.id,
  );
  const { NUTRIENTS: nutrientOptions, REMOVAL: removalOptions } = formulaOptionsByCategory;
  const allFormulaOptions = isRemoval ? removalOptions : nutrientOptions;

  const settingsForm = useForm<RxSettingsStateType>({
    mode: 'uncontrolled',
    initialValues: {
      activeDensity: '',
      activeFormula: String(allFormulaOptions?.[0]?.value || ''),
      agronomicProduct: null,
      analytic: null,
      cropIndex: 0,
      crop: '',
      customConcentration: '0',
      customMapLayer: null,
      customZones: [],
      expectedYield: '0',
      exportIndex: 0,
      exportingScript: false,
      generateFrom: '',
      harvestLayer: null,
      imageryLayer: null,
      inputIndex: 0,
      maxYield: '0',
      minYield: '0',
      maximumRate: '0',
      minimumRate: '0',
      name: '',
      percentApplication: '0',
      pricePerTon: '0',
      reloadPrescription: true,
      rxType: '',
      seed: null,
      showProModal: false,
      showSettings: true,
      sourceLayerGeojson: null,
      targetValue: '0',
      tillageIndex: 0,
      timingIndex: 0,
      triggerSave: false,
    },
  });

  const {
    activeDensity,
    activeFormula,
    analytic,
    cropIndex,
    customConcentration,
    customZones,
    expectedYield,
    exportIndex,
    exportingScript,
    inputIndex,
    maxYield,
    minYield,
    maximumRate,
    minimumRate,
    name,
    percentApplication,
    pricePerTon,
    reloadPrescription,
    showProModal,
    showSettings,
    targetValue,
    tillageIndex,
    timingIndex,
    triggerSave,
  } = settingsForm.getValues();

  const samplingPlan = field.features[0].properties.sampling_plans.find(
    (plan) => plan.id === prescription.sampling_plan_id,
  );

  const { composite_imagery_layers, harvest_data_files } = field.features[0].properties;
  const validHarvestYears = harvest_data_files.filter(
    (file) => file.start_time !== null && file.processed_geojson_uri !== null,
  );
  const validImageryYears = composite_imagery_layers.filter((file) => file.geojson_uri !== null);

  const proDensityOptions = useMemo(() => {
    if (
      REMOVAL_REC_FORMULAS.includes(prescription.formula_name) &&
      (validHarvestYears.length || validImageryYears.length)
    ) {
      return PRO_EXPORT_ACRE_DENSITY_OPTIONS;
    }
    return samplingPlan?.pro_densities.length
      ? PRO_EXPORT_ACRE_DENSITY_OPTIONS.filter((option) =>
          samplingPlan.pro_densities.includes(option.value),
        )
      : [];
  }, [validHarvestYears, samplingPlan, prescription]);

  const listOutputFormats = getExportTypes(language, operation?.external_connected_accounts);
  const timingOptions = getTimingOptions(language);
  const cropOptions = getCropOptions(language, activeFormula);
  const tillageOptions = getTillageOptions(language);

  const inputOptions = useMemo(
    () =>
      prescription.input
        ? getInputOptions(
            inputs,
            prescription.input.nutrient,
            activeFormula || prescription.formula_name,
          )
        : [],
    [inputs, prescription, allFormulaOptions, activeFormula],
  );

  useEffect(() => {
    if (reloadPrescription && prescription) {
      const newInputOptions = prescription.input
        ? getInputOptions(inputs, prescription.input.nutrient, prescription.formula_name)
        : [];
      const getGenerateFrom = () => {
        if (prescription.composite_imagery) {
          return IMAGERY;
        }
        if (prescription.machine_data) {
          return YIELD;
        }
        return SOIL_TEST;
      };
      const getRxType = () => {
        if (prescription.formula_name === CUSTOM_FORMULA) {
          return prescription.composite_imagery || prescription.machine_data
            ? RX_TYPE_REMOVAL
            : RX_TYPE_BUILDUP;
        }
        if (REMOVAL_REC_FORMULAS.includes(prescription.formula_name)) {
          return RX_TYPE_REMOVAL;
        }
        return RX_TYPE_BUILDUP;
      };
      settingsForm.setValues({
        activeDensity: prescription.pro_density || '',
        activeFormula:
          allFormulaOptions.find((formula) => formula.value === prescription.formula_name)?.value ||
          prescription.formula_name,
        // to handle i18n of custom product name, perform translation if prescription product is custom
        agronomicProduct:
          prescription.agronomic_product?.id === CUSTOM_PRODUCT_ID
            ? getCustomAgronomicProduct(language)
            : prescription.agronomic_product,
        analytic: prescription.analytic,
        cropIndex: cropOptions.findIndex((option) => option.value === prescription.crop),
        crop: prescription.crop,
        customConcentration: prescription.input
          ? convertCCEForEditing(
              prescription.input,
              prescription.custom_input_concentration || 0,
            ).toString()
          : undefined,
        customZones: prescription.custom_zone_meta
          ? convertNumericCustomZonesToString(prescription.custom_zone_meta)
          : [],
        expectedYield: (prescription.expected_yield || 0).toString(),
        generateFrom: getGenerateFrom(),
        harvestLayer: prescription.machine_data?.processed_geojson_uri || null,
        imageryLayer: prescription.composite_imagery?.geojson_uri || null,
        inputIndex: newInputOptions.findIndex((option) => option.id === prescription.input_id),
        maxYield: (prescription.imagery_calculation_max || 0).toString(),
        minYield: (prescription.imagery_calculation_min || 0).toString(),
        maximumRate: (prescription.maximum_rate || 0).toString(),
        minimumRate: (prescription.minimum_rate || 0).toString(),
        name: prescription.name,
        percentApplication: convertDecimalToPercent(
          prescription.percent_target_application ? prescription.percent_target_application : 1,
        ).toString(),
        pricePerTon: (prescription.cost_per_ton || 0).toString(),
        reloadPrescription: false,
        rxType: getRxType(),
        seed: prescription.seed,
        targetValue: convertTargetValueForEditing(
          prescription.formula_name,
          prescription.target_value || 0,
        ).toString(),
        tillageIndex: prescription.no_till ? 1 : 0,
        timingIndex: timingOptions.findIndex((option) => option.value === prescription.timing),
      });
    }
  }, [prescription, reloadPrescription, timingOptions, cropOptions, allFormulaOptions]);

  const showMessage = (message: string, type?: ToastType, timeout = 5000) =>
    showToast(message, type, timeout);

  const getZones = useCallback(() => {
    if (!prescription.geojson_uri) {
      dispatch(getZonesForPrescription(prescription.id, field.features[0].properties.acreage_unit));
    }
  }, [dispatch, prescription, field]);

  useEffect(() => {
    if (triggerSave) {
      submit();
      settingsForm.setFieldValue('triggerSave', false);
    }
  }, [
    triggerSave,
    cropIndex,
    activeFormula,
    inputIndex,
    pricePerTon,
    percentApplication,
    minimumRate,
    maximumRate,
    timingIndex,
    expectedYield,
    tillageIndex,
  ]);

  const submit = async () => {
    setIsSubmitting(true);
    try {
      // const isPro = proDensityIndex >= 0;
      if (activeDensity) {
        settingsForm.setFieldValue('showProModal', true);
      }
      const { id: fieldId } = field.features[0].properties;

      // skip zone collection for payload if editing pro prescription
      const zones = activeDensity
        ? undefined
        : existingZones.map((zone) => {
            const zoneSample = samples.find(
              (sample) => sample.properties.sample_uuid === zone.properties.sample_uuid,
            );

            return {
              ...zone,
              properties: {
                id: zone.properties.id,
                sample_uuid: zoneSample?.properties.sample_uuid,
                amount:
                  getSampleValueForPrescriptionAnalytic(prescription, zoneSample)?.quantity || null,
              },
            };
          });

      // Make sure to convert to dollars, acres, lbs, tons before sending
      const payload = {
        name: name.slice(0, MAX_PRESCRIPTION_NAME_LENGTH),
        crop: cropOptions[cropIndex].value,
        formula_name: activeFormula,
        input_id: prescription.input_id ? inputOptions[inputIndex]?.id : null,
        cost_per_ton: realMetricTonToDollarsTon(Number(pricePerTon), acreage_unit),
        percent_target_application: Number(percentApplication) / 100,
        minimum_rate: KgHaToLbsAc(Number(minimumRate), acreage_unit),
        maximum_rate: isNumber(Number(maximumRate))
          ? KgHaToLbsAc(Number(maximumRate), acreage_unit)
          : undefined,
        timing: timingOptions[timingIndex].value,
        expected_yield: sacaHaToBuAc(Number(expectedYield), acreage_unit),
        no_till: tillageIndex === 1,
        custom_input_concentration: prescription.input_id
          ? convertCCEForSubmission(inputOptions[inputIndex].value, Number(customConcentration))
          : null,
        target_value: convertTargetValueForSubmission(activeFormula, Number(targetValue)),
        zones,
        pro_density: activeDensity || null,
        imagery_calculation_min: Number(minYield) || null,
        imagery_calculation_max: Number(maxYield) || null,
        custom_zone_meta: customZones?.length
          ? convertStringCustomZonesToNumeric(customZones)
          : null,
      };
      // @ts-ignore
      await putPrescription(prescription.id, payload);
      await Promise.all([
        dispatch(getFieldGeometry(fieldId)),
        dispatch(getProPrescriptions(fieldId)),
        getZones(),
      ]);
      showMessage(`${getString('prescriptionSaved', language)}: ${name}`);
      settingsForm.setFieldValue('reloadPrescription', true);
    } catch (error) {
      showToast(getString('updatePrescriptionErrorMsg', language), 'error');
    } finally {
      setIsSubmitting(false);
      settingsForm.setFieldValue('showProModal', false);
    }
  };

  const exportPrescription = async () => {
    const selectedExternal = listOutputFormats[exportIndex];
    if (selectedExternal.leaf_user_uuid === null) {
      try {
        settingsForm.setFieldValue('exportingScript', true);
        await downloadPrescriptionShapefile(prescription.id, selectedExternal.value);
        showMessage(getString('prescriptionDownloadSuccessMsg', language));
      } catch (err) {
        showMessage(getString('prescriptionDownloadFailMsg', language), 'error');
      }
    } else {
      try {
        settingsForm.setFieldValue('exportingScript', true);
        await postPrescriptionExternalAccount(prescription.id, {
          external_user_uuid: selectedExternal.leaf_user_uuid,
          provider: selectedExternal.value,
        });
        showMessage(getString('exportInitiatedMsg', language));
      } catch (err) {
        showMessage(getString('initiatedExportFailMsg', language), 'error');
      }
    }
    settingsForm.setFieldValue('exportingScript', false);
  };

  const updateSelector = (key: keyof RxSettingsStateType, idx: number) => {
    settingsForm.setValues({
      [key]: idx,
      triggerSave: true,
    });
  };

  const submitPercentApplication = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.percent_target_application && e.target.value) {
      settingsForm.setFieldValue('triggerSave', true);
    } else if (
      prescription.percent_target_application &&
      e.target.value !== String(convertDecimalToPercent(prescription.percent_target_application))
    ) {
      settingsForm.setFieldValue('triggerSave', true);
    }
  };

  const submitTargetValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.target_value && e.target.value) {
      settingsForm.setFieldValue('triggerSave', true);
    } else if (
      prescription.target_value &&
      e.target.value !==
        String(convertTargetValueForEditing(prescription.formula_name, prescription.target_value))
    ) {
      settingsForm.setFieldValue('triggerSave', true);
    }
  };

  const submitMaxYield = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.imagery_calculation_max && e.target.value) {
      settingsForm.setFieldValue('triggerSave', true);
    } else if (
      prescription.imagery_calculation_max &&
      e.target.value !==
        String(
          convertTargetValueForEditing(
            prescription.formula_name,
            prescription.imagery_calculation_max,
          ),
        )
    ) {
      settingsForm.setFieldValue('triggerSave', true);
    }
  };

  const submitMinYield = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!prescription.imagery_calculation_min && e.target.value) {
      settingsForm.setFieldValue('triggerSave', true);
    } else if (
      prescription.imagery_calculation_min &&
      e.target.value !==
        String(
          convertTargetValueForEditing(
            prescription.formula_name,
            prescription.imagery_calculation_min,
          ),
        )
    ) {
      settingsForm.setFieldValue('triggerSave', true);
    }
  };

  const updateInput = (
    key: keyof RxSettingsStateType,
    e: React.ChangeEvent<HTMLInputElement>,
    allowNull?: boolean,
  ) => {
    if (Number(e.target.value) >= 0 || (allowNull && e.target.value === '')) {
      settingsForm.setFieldValue(key, e.target.value);
    }
  };

  const handleSetFormula = (value: string) => {
    const selectedFormula = allFormulaOptions.find((formula) => formula.value === value);
    if (selectedFormula) {
      const selectedInput = inputOptions[inputIndex]?.value;
      const newInputOptions = prescription.input
        ? getInputOptions(inputs, prescription.input.nutrient, selectedFormula.value)
        : [];
      const newInputIndex = newInputOptions.findIndex((i) => i.value.id === selectedInput?.id);
      if (newInputIndex < 0) {
        settingsForm.setFieldValue('inputIndex', 0);
      } else if (newInputIndex !== inputIndex) {
        settingsForm.setFieldValue('inputIndex', newInputIndex);
      }
      settingsForm.setValues({
        activeFormula: value,
        triggerSave: true,
      });
    }
  };

  // TODO: abstract this into a common utility when rest of page is mantinified
  // the above updateSelector function expects index based selectors instead of mantine string
  // selects
  const changeDensity = (value: string) => {
    settingsForm.setValues({
      activeDensity: value,
      triggerSave: true,
    });
  };

  const setTriggerSave = () => settingsForm.setFieldValue('triggerSave', true);

  const currentInput = inputOptions[inputIndex]?.value;

  const isLimePrescription =
    LIME_FORMULAS.includes(activeFormula) || (analytic && allPhIds.includes(analytic.id));
  const isCustomPrescription = customZones.length > 1;
  const isSeedPrescription = prescription.seed !== null;
  const isProductPrescription = prescription.agronomic_product !== null;
  const isConventionalInputPrescription = !isSeedPrescription && !isProductPrescription;

  return (
    <>
      <Accordion
        key="prescription-settings"
        open={showSettings}
        title={getString('settings', language)}
        toggleOpen={() => settingsForm.setFieldValue('showSettings', !showSettings)}
      >
        <Stack gap="xs">
          <Group justify="space-between">
            <Text>{getString('name', language)}:</Text>
            <Input
              data-test-id="rx-name-single"
              className={styles.Selector}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                settingsForm.setFieldValue('name', e.target.value)
              }
              onBlur={(e) => e.target.value !== prescription.name && setTriggerSave()}
              value={name}
            />
          </Group>
          {!isSeedPrescription && (
            <Group justify="space-between">
              <Text>{getString('timing', language)}:</Text>
              <Selector
                activeIndex={timingIndex}
                className={styles.Selector}
                onChange={(idx) => updateSelector('timingIndex', idx)}
                options={timingOptions}
              />
            </Group>
          )}
          {!isCustomPrescription && (
            <Group justify="space-between">
              <Text>{getString(isRemoval ? 'previousCrop' : 'crop', language)}:</Text>
              <Selector
                activeIndex={cropIndex}
                className={styles.Selector}
                onChange={(idx) => updateSelector('cropIndex', idx)}
                options={cropOptions}
              />
            </Group>
          )}
          {proDensityOptions.length ? (
            <Group justify="space-between">
              <Text>{getString('density', language)}:</Text>
              <Select
                value={activeDensity}
                onChange={(val) => val && changeDensity(val)}
                data={proDensityOptions}
              />
            </Group>
          ) : null}
          {!isCustomPrescription && (
            <Group justify="space-between">
              <Text>{getString('formula', language)}:</Text>
              <Select
                className={styles.Selector}
                data={allFormulaOptions}
                onChange={(value) => value && handleSetFormula(value)}
                value={activeFormula}
              />
            </Group>
          )}
          {prescription.composite_imagery_id && (
            <>
              <Group justify="space-between">
                <Text>{getString('maxYield', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('maxYield', e)}
                  onBlur={submitMaxYield}
                  value={maxYield}
                />
              </Group>
              <Group justify="space-between">
                <Text>{getString('minYield', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('minYield', e)}
                  onBlur={submitMinYield}
                  value={minYield}
                />
              </Group>
            </>
          )}
          {[SATURATION_BRAZIL, PHOSPHORUS_BRAZIL, POTASSIUM_BRAZIL].includes(activeFormula) && (
            <Group justify="space-between">
              {activeFormula === SATURATION_BRAZIL && (
                <Text>{getString('targetBaseSaturation', language)}:</Text>
              )}
              {activeFormula === PHOSPHORUS_BRAZIL && (
                <Text>{getString('targetPhosphorus', language)}:</Text>
              )}
              {activeFormula === POTASSIUM_BRAZIL && (
                <Text>{getString('targetPotassium', language)}:</Text>
              )}
              <Input
                className={styles.Input}
                type="number"
                onChange={(e) => updateInput('targetValue', e)}
                onBlur={submitTargetValue}
                value={targetValue}
              />
            </Group>
          )}
          {isLimePrescription && isConventionalInputPrescription && (
            <Group justify="space-between">
              <Text>{getString('tillage', language)}:</Text>
              <Selector
                activeIndex={tillageIndex}
                className={styles.Selector}
                onChange={(idx) => updateSelector('tillageIndex', idx)}
                options={tillageOptions}
              />
            </Group>
          )}
          {isConventionalInputPrescription && (
            <Group justify="space-between">
              <Text>{getString('input', language)}:</Text>
              <Selector
                activeIndex={inputIndex}
                className={styles.Selector}
                onChange={(idx) => updateSelector('inputIndex', idx)}
                options={inputOptions}
              />
            </Group>
          )}
          {isSeedPrescription && (
            <Flex gap="xs" justify="space-between">
              <SeedSearch
                defaultSeed={prescription.seed}
                language={language}
                onSave={setTriggerSave}
                rxForm={settingsForm}
              />
            </Flex>
          )}
          {isProductPrescription && (
            <Flex gap="xs" justify="space-between">
              <ProductSearch
                defaultProduct={prescription.agronomic_product}
                language={language}
                onSave={setTriggerSave}
                rxForm={settingsForm}
              />
            </Flex>
          )}
          {prescription.input && currentInput?.customizeable && (
            <Group justify="space-between">
              <Text>
                {`${getString(inputNames[currentInput.id], language)} (${
                  currentInput.unit && rxUnitToSymbol[currentInput.unit]
                })`}
                :
              </Text>
              <Input
                className={styles.Input}
                type="number"
                onChange={(e) => updateInput('customConcentration', e)}
                onBlur={(e) =>
                  // the above prescription.input check isn't satisfying typescript for the below function call
                  // unclear why, so checking in more than one to satisfy
                  prescription.input &&
                  e.target.value !==
                    String(
                      convertCCEForEditing(
                        prescription.input,
                        prescription.custom_input_concentration || prescription.input.concentration,
                      ),
                    ) &&
                  setTriggerSave()
                }
                value={customConcentration}
              />
            </Group>
          )}
          {!isSeedPrescription && (
            <>
              <Group justify="space-between">
                <Text>{getString('pricePerTon', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('pricePerTon', e)}
                  onBlur={(e) =>
                    e.target.value !== String(prescription.cost_per_ton) && setTriggerSave()
                  }
                  value={pricePerTon}
                />
              </Group>
              <Group justify="space-between" data-test-id="percent-target">
                <Text>{getString('percentTargetApply', language)}:</Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('percentApplication', e)}
                  onBlur={submitPercentApplication}
                  value={percentApplication}
                />
              </Group>{' '}
              <Group justify="space-between">
                <Text>
                  {getString('minimumRate', language)} ({getUnitLbsAc(acreage_unit)}):
                </Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('minimumRate', e)}
                  onBlur={(e) =>
                    e.target.value !== String(prescription.minimum_rate) && setTriggerSave()
                  }
                  value={minimumRate}
                />
              </Group>
              <Group justify="space-between">
                <Text>
                  {getString('maximumRate', language)} ({getUnitLbsAc(acreage_unit)}):
                </Text>
                <Input
                  className={styles.Input}
                  type="number"
                  onChange={(e) => updateInput('maximumRate', e, true)}
                  onBlur={(e) =>
                    e.target.value !== String(prescription.maximum_rate) && setTriggerSave()
                  }
                  value={maximumRate || ''}
                />
              </Group>
            </>
          )}
          <Flex justify="space-between" my="md">
            <Text>
              {getString(
                isSeedPrescription ? 'averagePopulation' : 'appliedFieldAverage',
                language,
              )}
            </Text>
            <Text>{roundTwoDecimal(prescription.field_rate_average)}</Text>
          </Flex>
          {prescription.custom_zone_meta && (
            <CustomZoneEditor
              currentUser={currentUser}
              field={field}
              fullWidth
              language={language}
              onSave={setTriggerSave}
              proLayer={proLayer}
              rxForm={settingsForm}
              samples={samples}
              saveLayerZones={false}
            />
          )}
          {!(isRemoval || prescription.custom_zone_meta) && (
            <Group justify="space-between">
              <Text>
                {getString('expectedYield', language)} ({getUnitBuAc(acreage_unit)}):
              </Text>
              <Input
                className={styles.Input}
                type="number"
                onChange={(e) => updateInput('expectedYield', e)}
                onBlur={(e) =>
                  e.target.value !== String(prescription.expected_yield) && setTriggerSave()
                }
                value={expectedYield}
              />
            </Group>
          )}
          <Group justify="space-between">
            <Text>{getString('outputFormula', language)}:</Text>
            <Selector
              activeIndex={exportIndex}
              className={styles.Selector}
              onChange={(idx) => settingsForm.setFieldValue('exportIndex', idx)}
              options={listOutputFormats}
              menuClassName={styles.SelectorMenu}
            />
          </Group>
          <Group justify="flex-end">
            <Button
              data-test-id="export-rx"
              disabled={exportingScript}
              onClick={exportPrescription}
            >
              {getString('export', language)}
            </Button>
          </Group>
        </Stack>
      </Accordion>
      <Modal
        opened={showProModal}
        onClose={() => settingsForm.setFieldValue('showProModal', false)}
        title={getString('generatingPrescription', language)}
        withCloseButton={false}
      >
        <Center>
          <Loader my="lg" />
        </Center>
      </Modal>
    </>
  );
};

export default Settings;
