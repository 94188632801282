import { COMPANY_SITE_URL, SUPPORT_EMAIL } from 'constants/urls';

const PORTUGUESE_STRINGS = {
  PDFCropSelectMsg:
    'Se nenhuma cultura for selecionada, apenas a próxima colheita será mostrada para cada campo.',
  PDFFieldsDisabledMsg: 'Os campos serão desabilitados caso os resultados ainda não existam.',
  aaAnalysis: 'Análise de AA',
  aaRerun: 'AA Renum',
  abort: 'Abortar',
  aboveGroundOnly: 'Apenas o solo',
  acAtRisk: 'HA em risco',
  accepted: 'Aceitaram',
  account: 'Conta',
  accounts: 'Contas',
  accountName: 'Nome da conta',
  acre: 'Hectare',
  acreCommit: 'COMPRIMENTO ACRE',
  acres: 'Hectares',
  acresPerZone: 'Hectares por zona',
  action: 'Ação',
  actions: 'Ações',
  activateTillMapper: 'Ative o TillMapper para este campo',
  activateTotalFarm: 'Ativar Fazenda Total',
  active: 'Ativo',
  activeAndCompletedPlans: 'Planos ativos e concluídos',
  activeSamplingPlans: 'Planos de amostragem ativos (hectares)',
  activeTodayGenerateUnlimitedMsg: 'Ative hoje e gere scripts e planos ilimitados',
  activityDetails: 'Detalhes da atividade',
  adcp_needed_not_complete: 'ADCP necessário, não completo',
  add: 'Adicionar',
  add590: 'Adicionar Análise 590',
  addAccount: 'Adicionar conta',
  addAddress: 'Adicione endereço',
  addAdjustment: 'Adicione ajuste',
  addAdjustments: 'Adicionar ajustes',
  addAdjustmentsFor: 'Adicione ajuste para',
  addAgency: 'Adicionar agência',
  addAgents: 'Adicionar agentes',
  addAnAgency: 'Por favor, adicione uma agência.',
  addAnalytic: 'Adicionar Analítico',
  addBatch: 'Adicione Lote',
  addBillingResponsibility: 'Adicione responsabilidade de cobrança',
  addClus: 'Adicionar CLUs',
  addControl: 'Adicione controle',
  addEditBoundaries: 'Adicionar / Editar Limites',
  addExternalLab: 'Adicionar laboratório externo',
  addFieldInstructions: 'Forneça um nome de campo e clique em "salvar" para continuar.',
  addGate: 'Adicionar portão',
  addLabel: 'adicionar etiqueta de envio',
  addLocation: 'Adicionar localização',
  addNewImage: 'Adicionar nova imagem',
  addPlate: 'Adicione a placa',
  addPlateSuccess: 'Plate adicionado com sucesso!',
  addPriority: 'Adicionar prioridade',
  addProduct: 'Adicionar produto',
  addProductChart:
    'Selecione um produto que forneça um nível adequado de proteção. Adicionar produtos ao seu Catálogo de Produtos exibirá seus níveis de proteção aqui.',
  addProducts: 'Adicionar Produtos',
  addProductsAbove: 'Adicionar produtos acima',
  addReferencesOrResources: 'Adicionar Referências ou Recursos',
  addSample: 'Adicione amostra',
  addSeed: 'Adicionar semente',
  addSeedChart:
    'Selecione um Semente que forneça um nível adequado de proteção. Adicionar sementes ao seu Catálogo de Produtos exibirá seus níveis de proteção aqui.',
  addTargetYieldMsg: 'Adicione um produtividade alvo para continuar.',
  addZone: 'Adicionar Zona',
  added: 'Adicionado',
  addingBillingResponsibility: 'Adicionando responsabilidade de cobrança',
  additionalInputSpend: 'Gasto de entrada adicional',
  additionalLab: 'Laboratório adicional',
  addressCreatedMsg: 'Endereço atualizado com sucesso!',
  adjustmentAmount: 'Quantidade de ajuste',
  adjustmentCreatedSuccessMsg: 'Ajuste criado com sucesso!',
  adjustmentRemovedSuccessMsg: 'Ajuste removido com sucesso!',
  adjustments: 'Ajustes',
  admin: 'Admin',
  affectAllLabelsMsg: 'Isso afetará todos os rótulos deste grupo.',
  agencies: 'Agências',
  agency: 'Agência',
  agencyConnectionAdded: 'Conexão de agência adicionada com sucesso!',
  agencyConnectionRemoved: 'Conexão de agência removida com sucesso!',
  agencyContractWarningMsg:
    'O contrato da agência de cobrança expirou: os pedidos serão faturados no MSRP',
  agencyCost: 'Custo de agencia',
  agencyDefaults: 'Padrões da agência',
  agencyName: 'Nome da Agência',
  agencyPrice: 'Preço da agência',
  agencyPrimary: 'Agência Primária',
  agencyUpdatedSuccessMsg: 'Agência atualizada com sucesso!',
  agency_admin: 'Administrador da Cluster',
  agent: 'Agente',
  agents: 'Agentes',
  agentsMustBeAssignedToExisting:
    'Os agentes devem ser atribuídos a uma agência existente. Procure a agência abaixo. Se você deseja criar uma nova agência, precisará atribuir o papel de administração da agência a este usuário',
  alertDoubleSamplePrinting:
    'Uma etiqueta para esta amostra já foi impressa! Certifique-se de estar trabalhando com a amostra correta. Não deve haver motivo para imprimir uma segunda etiqueta, a menos que a primeira tenha sido danificada ou não tenha sido impressa corretamente! Tem certeza de que deseja imprimir esta etiqueta novamente?',
  alertDoubleSamplePrintingTitle: 'Etiqueta já impressa',
  alerts: 'Alertas',
  alertsAndNotReady: 'Alertas + Não Pronto',
  alertsAndReady: 'Alertas + Pronto',
  all: 'Todas',
  allAccounts: 'Todas as contas',
  allAnalyses: 'Todas as análises',
  allCatalogs: 'Todas as Catálogos',
  allExtractions: 'Todas as Extrações',
  allInputs: 'Todas as Entradas',
  allPipelines: 'Todos os Pipelines',
  allProjects: 'Todos os projetos',
  allResults: 'Todos os resultados',
  allRiskLevels: 'Todos Os Níveis De Risco',
  allStatuses: 'Todos os status',
  allTypes: 'Todos os tipos',
  allZones: 'Todas as zonas',
  all_assignments: 'Todas as tarefas',
  all_plans: 'Todos os planos',
  all_pro_or_till_rx: 'Todos os Pro/TillMapper',
  all_readiness: 'Toda a prontidão',
  all_statuses: 'Todos os status',
  allowMultiplePlans: 'Permitir vários planos abertos por campo',
  allowedValues: 'Valores permitidos',
  ammoniumAcetate: 'Acetato de amónio',
  ammoniumAcetateAnalysis: 'Análise de acetato de amônio',
  amount: 'Quantia',
  amplicon: 'Amplicon',
  analysis: 'Análise',
  analysis590Description:
    'Isto incluirá análises adicionais em apoio a 590 planos de Gestão de Estrume',
  analysis590Required:
    'Aviso: 590 Análise Necessária - Processo por meio de secagem/moagem para apoiar a análise downstream',
  analysis590Selected: '590 Análise Selecionada',
  analysis590Title: '590 Análise de Gestão de Estrume',
  analysisAndSampling: 'Análise e Amostragem',
  analysisBundles: 'Pacotes de Análise',
  analysisOptions: 'Opções de análise',
  analysisOptionsMsg: 'Opções de análise atualizadas com sucesso!',
  analysisOverYear: 'Análise para o próximo ano',
  analysisPackage: 'Pacote de análise',
  analysisStandalone: 'Análise Autônoma',
  analytic: 'Análise',
  analyticAttributes: 'Atributos Analíticos',
  analyticDetails: 'Detalhes analíticos',
  analyticImagery: 'Imagens Analíticas',
  analyticName: 'Nome Analítico',
  analyticOrderUpdated: 'Ordem analítica atualizada com sucesso!',
  analyticRiskMap: 'Mapa De Risco Analítico',
  analyticTaxonomies: 'Taxonomias Analíticas',
  analytics: 'Análises',
  applicationRate: 'Taxa de Aplicação',
  applied: 'Aplicado',
  appliedFieldAverage: 'Média de Campo Aplicada',
  applyDueDateToSamplingPlanConfirmation: 'Aplicar esta data de vencimento ao plano de amostragem',
  applyPriorityToSamplingPlanConfirmation: 'Aplicar esta prioridade ao plano de amostragem',
  applyRecommendedInputs:
    "Aplique insumos recomendados com base no relatório do padrão 360 sobre campos 'em risco'",
  applyToSamplingPlan: 'Aplicar ao plano de amostragem',
  approveAnyway: 'Aprovar de qualquer maneira',
  approved: 'Aprovado',
  approvedBy: 'Aprovado por',
  approveOpenTransactions: 'Aprovar transações abertas',
  approveTransaction: 'Aprovar a transação',
  areYouSure: 'Tem certeza',
  arrived: 'Chegado',
  arrivedLab: 'Laboratório de Chegada',
  assign: 'Atribuir',
  assignAllPlans: 'Atribua todos os planos de amostra abertos nesta conta a este amostrador',
  assignSamplePlan: 'Atribuir um plano de amostra',
  assignSampler: 'Atribua amostrador',
  assignSamplerError:
    'Houve um erro de atribuição do amostrador. Por favor, atualize e tente novamente.',
  assignSamplerNoBilling:
    'Atribua uma responsabilidade de cobrança antes que o campo possa ser amostrado.',
  assignSamplerPar: 'Selecione o amostrador que você gostaria de atribuir a este plano de amostra',
  assignSamplersSuccessMsg: 'Amostradores/Digitalizador atribuídos com sucesso!',
  assignTo: 'Atribuir a',
  assignToCollection: 'Atribuir à coleção',
  assignUserToAgency: 'Atribuir Usuário à Agência',
  assigned: 'Atribuído',
  assignedBillingMsg: `A responsabilidade de cobrança atribuída ao usuário não assinou seu contrato com o padrão. Eles podem acessar e assinar o contrato fazendo login na sua conta em ${COMPANY_SITE_URL}. Você poderá criar planos de amostra para esta conta, mas não poderá coletar amostras em campo até que o contrato seja assinado ou um usuário alternativo seja adicionado para cobrar responsabilidade.`,
  assigned_not_accepted: 'Atribuído, não aceito',
  assignment_accepted: 'Atribuição aceita',
  assignment_declined: 'A tarefa recusou',
  assignment_not_accepted: 'Tarefa não aceita',
  authFailed: 'Autenticação falhou',
  autoSubmit: 'Submissão automática',
  average: 'Média',
  averageAppliedToZone: 'Média de campo aplicada à zona',
  averageCompaction: 'Compactação média',
  averageCompactionTooltip: 'PSI Médio de Campo Completo, 0-12"',
  averagePopulation: 'População Média',
  avg: 'Média',
  avgRate: 'Taxa média',
  awaitingResults: 'Aguardando resultados',
  awaitingWellInpuMsg: 'Aguardando a contribuição para bem',
  back: 'De volta',
  backToAllRx: 'Voltar para todos os RX',
  backToAnalytics: 'Voltar para Analíticos',
  backToFieldSummary: 'Voltar ao resumo do campo',
  backToList: 'De volta à lista',
  backToResults: 'De volta aos resultados',
  backToTop: 'Voltar ao topo',
  backup: 'Backup',
  balance: 'Equilíbrio',
  balanceDueFromCustomer: 'Saldo devido do cliente',
  barcode: 'Códigos de barras',
  barcodes: 'Códigos de barras',
  basePackage: 'Pacote Base',
  baseSaturation: 'Saturação Base',
  baseTreatment: 'Tratamento de Base',
  batch: 'Lote',
  batchOrder: 'Pedido em lote',
  batchPoolStatus: 'Status de lote/pool',
  batchSummary: 'Resumo do lote',
  batched: 'Em lote',
  batches: 'Lotes',
  bcsr: 'Saturação de Base',
  bdCoarseDryMass: 'Massa Seca Grossa de Densidade a Granel',
  bdFineDryMass: 'Massa Seca Fina de Densidade a Granel',
  bdTotalDryMass: 'Massa Seca Total de Densidade a Granel',
  belowForMappedFields: 'Abaixo para qualquer um dos seus campos mapeados:',
  benchmark: 'Benchmark',
  beneficialBioMarkers: 'Biomarcadores benéficos (mais alto é melhor)',
  benefits: 'Benefícios',
  billing: 'Faturamento',
  billingAddress: 'Endereço de Cobrança',
  billingAddressMissingMsg: 'Por favor, adicione um endereço de cobrança.',
  billingAddressRequired: 'Endereço de cobrança necessário.',
  billingAgency: 'Agência de Cobrança',
  billingInfoUpdated: 'Informações de cobrança atualizadas!',
  billingResponsibility: 'Responsabilidade de cobrança',
  bioactive: 'Biofertilidade',
  biological: 'Biológico',
  biologicalSubsample: 'Subamostra Biológica',
  biological_analysis: 'Análise Biológica',
  blendedLab: 'Laboratório de Liquidificador',
  boostYield: 'Aumentar o rendimento',
  boostYieldPitch: 'Compactação acima de 250-300 PSI pode reduzir o rendimento em 10-20%+',
  boundariesAndData: 'Limites e dados',
  bray: 'Zurro',
  brazilPhosphorus: 'Brasil - Fósforo',
  brazilPotassium: 'Brasil - Potássio',
  brazilSaturationMethod: 'Brasil - Método de Saturação',
  buildup: 'Acumular',
  bulkAssign: 'Atribuição em massa',
  bulkDelete: 'Excluir em Massa',
  bulkDeleteRxMsg:
    'Isso removerá todas as prescrições selecionadas. Pressione confirmar para continuar.',
  bulkDensity: 'Densidade a Granel',
  bulkExport: 'Exportar em Massa',
  bulkExportRxMsg:
    'Isto exportará todas as prescrições selecionadas. Pressione confirmar para continuar.',
  businessName: 'Nome da empresa',
  by: 'por',
  calciumCarbonateEquivalent: 'Equivalente de Carbonato de Cálcio',
  canScanProPlans: 'Digitalização 360 Pro Ativada',
  canUpdateLater: 'Você pode atualizar isso mais tarde',
  cancel: 'Cancelar',
  cancelAll: 'Cancelar todas',
  cancelAllAnalysis: 'Cancelar todas as análises',
  cancelEntireSample: 'Cancelar amostra inteira',
  cancelIngest: 'Cancelar ingestão',
  cancelled: 'Cancelado',
  cancelLabel: 'Cancelar rótulo',
  cancelMerge: 'Cancelar mesclagem',
  cancelNitrate: 'Cancelar Nitrato',
  cancelNutrient: 'Cancelar Nutrientes',
  cancelOrder: 'Cancelar pedido',
  cancelPerformance: 'Cancelar o Desempenho',
  cancelPressure: 'Cancelar Pressão',
  cancelSample: 'Cancelar amostra',
  cancelSamplePlan: 'Cancelar o plano de amostra',
  cancelSamplePlanFailureMsg: 'Falha ao cancelar o plano de amostragem. Tente mais tarde.',
  cancelSamplePlanMsg: 'Tem certeza de que deseja cancelar este pedido?',
  cancelSamplingPlanMsg:
    'Ao cancelar esse plano de amostra, ele não poderá mais ser visível nesta operação.',
  cancelSplit: 'Cancelar divisão',
  cancelTotalFarm: 'Cancelar Fazenda Total',
  cancelTotalFarmMsg:
    'Antes de você ir, pode haver um pacote Total Farm diferente que melhor se adapte a você. Se você quiser discutir alternativas, ligue para seu líder de atendimento ao cliente a qualquer momento para discutir com mais detalhes!',
  carbonDetails: 'Detalhes do Carbono',
  carbonPlanConfirmBody:
    'Uma vez que o plano for gerado, você não poderá ajustar as densidades de amostragem nem outros detalhes de amostragem.\n\nPara sua informação: a geração do plano de amostragem levará cerca de 15 minutos.',
  carbonPlanConfirmTitle: 'Tem certeza de que deseja finalizar a geração do plano?',
  carrier: 'Transportadora',
  catalog: 'Catálogo',
  catalogDetails: 'Detalhes do catálogo',
  category: 'Categoria',
  cationRatios: 'Taxas de cátions',
  cellPhone: 'Celular',
  changePassword: 'Alterar a senha',
  checkBoxToContinue: 'caixa de seleção para continuar',
  checkingForExistingPlan: 'Verificando um plano de amostragem existente',
  chemistryBackup: 'Backup de Química',
  chemistryResults: 'Resultados da química',
  childrenPlates: 'Placas de crianças',
  choose: 'Escolher',
  chooseAnalysisMsg: 'Escolha uma análise para continuar',
  chooseCollection: 'Escolha coleção',
  chooseNutrientPanels: 'Escolha seus painéis de nutrientes',
  chooseRegistry: 'Escolha o registro',
  city: 'Cidade',
  clear: 'Claro',
  click: 'Clique',
  clickFieldsToAssignColln: 'Clique no(s) campo(s) para atribuir a uma coleção.',
  clickFieldToEdit: 'Clique em um campo para editar seu limite',
  clickToAssignPlans: 'Clique Para Atribuir Planos',
  clickToExpand: 'Clique para expandir',
  clickToUndo: 'Clique para desfazer',
  cluInstructions0: 'Clique em "Carregar CLUs" para exibir os CLUs da área atual do mapa.',
  cluInstructions1:
    'Selecione os CLUs para começar a mapear um limite ou clique em "Recarregar CLUs" para atualizar o mapa.',
  cluInstructions2: 'Clique em "Adicionar CLUs" para editar os limites selecionados.',
  cluInstructions3: 'Aproxime o zoom para ativar os CLUs.',
  cnhi: 'CNHI',
  codeIncorrectMsg: 'Este código está incorreto ou expirou. Por favor, reenvie e tente novamente.',
  codeSentFailMsg: 'Houve um erro enviando o código. Por favor, tente novamente.',
  codeSentSuccessMsg: 'Código enviado com sucesso!',
  coldOrRoomTemp: 'Temperatura fria ou da sala',
  collection: 'Coleção',
  collectionCreated: 'Coleta criada com sucesso',
  collectionDate: 'Date de Coleção',
  collectionDeleted: 'Coleção excluída.',
  collectionName: 'Nome da coleção',
  collectionToReview: 'coleção para revisar',
  collections: 'Coleções',
  collectionsToReview: 'coleções para revisar',
  comments: 'Comentários',
  commercialTerritory: 'Território comercial',
  commission: 'Comissão',
  commission_tillage_map_generation: 'Comissão de Geração de Mapa de Preparo',
  committedSamples: 'Amostras comprometidas',
  committed_samples: 'Amostras comprometidas',
  compaction: 'Compactação',
  compactionAnalysis: 'Análise de Compactação',
  compactionScore: 'Pontuação de Compactação',
  complete: 'Completo',
  completeBioMsg:
    'Além do painel de pressão, mapeie o risco de doenças do solo e a oportunidade de biofertilidade, para otimizar os gastos com insumos',
  completeIngest: 'Ingestão completa',
  complete_bio: 'Biografia completa',
  completed: 'Concluído',
  completedOrders: 'Pedidos concluídos',
  completedPlan: 'Plano concluído',
  completedResults: 'Resultados concluídos',
  completedSamples: 'Amostras concluídas',
  completeness: 'Completude',
  comprehensiveFieldAnalysisMsg: 'Análise de campo abrangente sobre rotação plurianual',
  comprehensiveFieldBioMsg:
    'análise abrangente de biologia de campo observando pragas, patógenos e métricas de biofertilidade',
  confirm: 'confirme',
  confirmAnalysis: 'Confirme a análise',
  confirmAndViewNewField: 'Confirme e veja o novo campo',
  confirmCollectionMsg:
    'Esta etapa vinculará os planos de amostra selecionados aos dados de varredura listados abaixo deles. Por favor, certifique-se de que todos os dados de digitalização necessários foram carregados para cada plano de amostra antes de confirmar e aguarde para confirmar um plano de amostra se ele não tiver sido completamente digitalizado.',
  confirmFieldAssignment: 'Confirmar Atribuição de Campo',
  confirmFieldRemoval: 'Confirme a remoção de campo',
  confirmMerge: 'Confirmar mesclagem',
  confirmOrder: 'Confirmar pedido',
  confirmRemoveFieldMsg: 'Tem certeza de que deseja remover este campo?',
  confirmSplit: 'Confirmar divisão',
  connect: 'Conectar',
  connectOutsideAccountRxLink: 'Conectar uma conta externa',
  connectOutsideAccounts: 'Conectar contas externas',
  connectOutsideAccountsImport:
    'Conecte -se a contas externas para importar facilmente limites de campo e camadas de dados em EarthOptics',
  connectYourDataFrom: 'Conecte seus dados de',
  connecting: 'Conectando',
  contactInfo: 'Informações de contato',
  contactInfoUpdated: 'Informações de contato atualizadas!',
  contactYourAgronomist:
    'Entre em contato com seu agrônomo para saber mais ou solicite o TillMapper diretamente abaixo!',
  containsRegulatedSoilMsg: 'Contém solo regulado (inclua o número da DSCA)',
  contractDetails: 'Detalhes do contrato',
  contractEndedSuccessMsg: 'O contrato terminou com sucesso!',
  contractPricing: 'Preço de contrato',
  contractPricingUpdatedSuccess: 'Preço do contrato atualizado com sucesso',
  contractStatus: 'Status do contrato',
  contractType: 'tipo de contrato',
  contractUpdatedErrorMsg:
    'Houve um erro atualizando o contrato. Por favor, atualize e tente novamente.',
  contractUpdatedSuccessMsg: 'Contrato atualizado com sucesso!',
  controlCode: 'Código de Controle',
  controlType: 'Tipo de controlo',
  controls: 'Controles',
  conventionalTillage: 'Lavoura convencional',
  copyFromCorn: 'Copiar de milho',
  copyPlate: 'Copiar placa',
  corn: 'Milho',
  corn_silage: 'Silagem de Milho',
  cornAndSoybeanPathogens: 'Pragas e doenças do milho e da soja',
  cornFoliar: 'Milho foliar',
  cornPathogens: 'Pragas e doenças do milho',
  cornPlan: 'Plano de milho',
  cost: 'Custo',
  costPerBag: 'Custo por Mala',
  cotton: 'Algodão',
  couldNotAddProduct: 'Não foi possível adicionar o produto. Atualize e tente novamente.',
  couldNotDownloadLayer: 'Não foi possível baixar a camada',
  couldNotMergeSelectedError: 'Não foi possível mesclar zonas selecionadas.',
  countries: 'Países',
  country: 'País',
  county: 'Condado',
  countyFootNote: 'Usado para estimar os riscos para sua operação',
  coverageRatings: 'Nível de Controle',
  create: 'Criar',
  createAccount: 'Criar uma conta',
  createAdjustment: 'Criar ajuste',
  createAndEditRx: 'Criar reocumendaçao de nutrientes',
  createAttribute: 'Criar atributo',
  createBatch: 'Crie lote',
  createCatalogMsg: 'Digite um nome para este catálogo de produtos e clique em salvar',
  createCatalogPlaceholderMsg: 'Exemplo: Entradas para Smith Farms...',
  createCollection: 'Criar coleção',
  createContract: 'Criar contrato',
  createControl: 'Criar controle',
  createCustomProduct: 'Criar Produto Personalizado',
  createCustomSeed: 'Criar Semente Personalizado',
  createEstimate: 'Criar estimativa',
  createFarmAccount: 'Crie uma nova conta agrícola',
  createFarmPlan: 'Criar plano de fazenda',
  createNewGate: 'Criar novo portão',
  createIndexSet: 'Criar conjunto de índice',
  createLabIntakeForm: 'Criar formulário de admissão de laboratório',
  createLabShipment: 'Criar remessa de laboratório',
  createNaptControl: 'Criar Controle NAPT',
  createNew: 'Criar novo',
  createNewAgency: 'Crie nova agência',
  createNewAgent: 'Crie um novo agente',
  createNewContract: 'Criar novo contrato',
  createNewLabel: 'Crie nova etiqueta de remessa',
  createNewProductCatalog: 'Criar novo catálogo de produtos',
  createNewProject: 'Criar novo projeto',
  createOperation: 'Criar operação',
  createOperationErrorMsg:
    'Houve um erro criando a operação. Por favor, atualize e tente novamente.',
  createOperationSuccessMsg: 'Operação criada com sucesso!',
  createOrAddAgencyUnder: 'Crie ou adicione uma agência abaixo',
  createPassword: 'Concordar e Criar Senha',
  createPaymentOnly: 'Criar pagamento apenas',
  createPlate: 'Crie placa',
  createPrescription: 'Criar Prescrição',
  createProject: 'Criar projeto',
  createProtocol: 'Criar protocolo',
  createRx: 'Crie Rx',
  createSalesOrders: 'Criar pedidos de venda',
  createSample: 'Crie amostra',
  createSampleSheet: 'Criar Folha de Amostra',
  createSamplingPlan: 'Criar plano de amostragem',
  createShippingAddress: 'Crie endereço de entrega',
  createShippingLabels: 'Crie rótulos de remessa',
  createUser: 'Criar usuário',
  createYourPlan:
    '<b> Crie seu plano: </b> O padrão 360 ajudará <b> Recomendar as entradas </b> que aumentarão o rendimento e otimizarão os gastos.',
  createZonesInOrderToBegin: 'Crie zonas para começar a selecionar a análise!',
  createZonesUsing: 'Tipo de amostragem',
  created: 'Criada',
  createdAt: 'Criado em',
  createdBy: 'Criado por',
  creatingLabIntakeForm: 'Criando formulário de admissão de laboratório',
  creatingLabIntakeFormError: 'Erro ao criar formulário de entrada do laboratório',
  crop: 'Cultura',
  'crop-protection': 'Doenças de Solo',
  cropPlanningDisclaimer:
    'A informação prestada sobre a composição e grau de eficiência dos produtos é registada e fornecida exclusivamente pelos fornecedores dos produtos indicados nesta plataforma e é da sua exclusiva responsabilidade. A plataforma Pattern Ag/Earth Optics não promoveu testes ou qualquer outro mecanismo de validação dos resultados e efeitos indicados pelos fornecedores. Caso tenha alguma dúvida, sugerimos que entre em contato com o Serviço de Atendimento ao Consumidor (SAC) indicado pelo fornecedor.',
  cropProtectionCosts: 'Custos de proteção de culturas',
  crops: 'Culturas',
  csvColumnsRequiredMsg: 'O arquivo deve conter as seguintes colunas',
  csvDownloadMsg:
    'Isso pode levar alguns minutos. Sinta -se à vontade para sair da página. (O link aparecerá no download suspenso, quando disponível.)',
  csvGenerateErrorMsg: 'Erro ao gerar CSV. Atualize e tente novamente.',
  current: 'Atual',
  custom: 'Personalizado',
  customProduct: 'Produto Personalizado',
  customRx: 'Rx personalizado',
  customRxMsg:
    'Crie uma prescrição para qualquer insumo, incluindo produtos de proteção de cultivos',
  customZoneSettings: 'Configurações de zona personalizada',
  customZones: 'Zonas Personalizados',
  custom_points: 'Pontos personalizados',
  customerPrice: 'Preço do cliente',
  customerTotal: 'Total do cliente',
  cycleTreshold: 'Limiar de ciclo',
  dashboard: 'Painel',
  dataConfirmationErrorMsg:
    'Não foi possível atribuir a varredura ao campo. Tente novamente ou entre em contato com o suporte técnico da EarthOptics',
  dataConfirmationSuccess: 'Os dados de digitalização foram atribuídos aos campos',
  dataReview: 'Rrevisão de Dados',
  date: 'Data',
  dateAdded: 'Data adicionada',
  dateSigned: 'Data assinada',
  days_marked_ready: 'Dias marcados prontos',
  dcpReading: 'Leitura DCP',
  dealer: 'Revendedor',
  dealerFullService: 'Revendedor de serviço completo',
  dealerPortal: 'Portal do Revendedor',
  dealerPrice: 'Preço do revendedor',
  dealerSelfSample: 'Revendedor de autoamostragem',
  dealerSelfService: 'Revendedor de autoatendimento',
  dealerTotal: 'Total do revendedor',
  declined: 'Recusou',
  default: 'Padrão',
  defaultLab: 'Laboratório padrão',
  defaultShippingLocation: 'Local de envio padrão',
  delete: 'Excluir',
  deleteAdjustment: 'Excluir ajuste',
  deleteAgencyOperationConnection: 'Excluir conexão agência para',
  deleteCollection: 'Excluir coleção',
  deleteCollectionConfirm: 'Tem certeza? Esta coleção não pode ser recuperada depois de excluída.',
  deleteGate: 'Excluir portão',
  deleteLabelsGroupMsg: 'Tem certeza de que deseja excluir este grupo de etiquetas?',
  deleteLabelsMsg: 'Tem certeza de que deseja excluir este rótulo?',
  deletePDFErrorMsg: 'Erro ao excluir PDF. Atualize e tente novamente.',
  deletePDFMsg: 'Tem certeza de que deseja excluir este PDF?',
  deletePDFSuccessMsg: 'PDF excluído com sucesso!',
  deleteSample: 'Excluir amostra',
  deleteSampleWarning:
    'Tem certeza de que deseja excluir esta amostra? Isso é permanente e pode impactar negativamente a qualidade do projeto.',
  deleteTerritory: 'Excluir Território',
  deleteTerritoryMsg: 'Tem certeza de que deseja excluir este território?',
  deleteYourDataMsg: `Para obter suporte ou solicitar a exclusão de sua conta e/ou dados, envie um email para ${SUPPORT_EMAIL}.`,
  deleteZonesSuccess: 'Zonas excluídas com sucesso!',
  deletingCollection: 'Excluindo Coleção...',
  deliverCompletePar:
    'Esta operação possui planos de amostragem com resultados que ainda não foram marcados como entregues ao cliente. Se você concluiu a entrega desses resultados, poderá marcar esta operação como concluída agora.',
  delivered: 'Entregue',
  deliveredLabels: 'Etiquetas entregues',
  deliveryComplete: 'Entrega completa para',
  deliveryDate: 'Nenhum marcador encontrado',
  demoAccount: 'Conta de demonstração',
  density: 'Densidade',
  deprecated: 'Obsoleto',
  depth: 'Profundidade',
  description: 'Descrição',
  detected: 'Detectado',
  dilution: 'Diluição',
  dilutionConcentration: 'Concentração de endro',
  disable: 'Desativar',
  discard: 'Descartar',
  discardPlate: 'Descarte a placa',
  discardPlateWarningMsg:
    'Aviso: se você descartar esta placa, ela não poderá ser recuperada. Você tem certeza de que quer fazer isso?',
  discarded: 'Descartado',
  discount: 'Desconto',
  discount_panels: 'Painéis com desconto',
  discount_pressure_panel: 'Stine - Painel de pressão',
  discount_sampling_fee: 'Taxa de amostragem com desconto',
  dismiss: 'Dispensar',
  dismissAlerts: 'Dispensar Alertas',
  dispatchNotes: 'Notas de Despacho',
  displayCategory: 'Categoria de exibição',
  displayOrder: 'Ordem de exibição',
  displayedAnalytics: 'Analíticos de Exibição',
  displayedRecommendationSet: 'Conjunto de recomendações exibido',
  dlBarcodeFile: 'Arquivo de código de barras DL',
  dlFIARunSheet: 'Folha de execução DL -- FIA',
  dlICPRunSheet: 'Folha de execução DL -- ICP',
  dlMiSeqIndexSets: 'Conjuntos de índice DL MiSeq',
  dlNovaSeqIndexSets: 'Conjuntos de índice DL NovaSeq',
  dlRunSheet: 'Folha de execução DL',
  dlSampleSheet: 'Folha de amostra de DL',
  'dna copies': 'Cópias de DNA',
  dnaConcentration: 'Concentração de DNA',
  dnaConcentrationData: 'Dados de concentração de DNA',
  dnaExtr: 'Extr',
  dnaExtraction: 'Extração de DNA',
  done: 'Feito',
  doNotBill: 'Não faturar',
  doNotEnd: 'Não termine',
  doNotNavigateMsg: 'Não navegue para longe desta página até que o upload seja concluído.',
  donorPlate: 'Placa Doadora',
  download: 'Download',
  download811msg: 'Solicitação de download de CSV 811 enviada',
  downloadCsvResults: 'Baixe os resultados do CSV',
  downloadDetails: 'Baixar detalhes',
  downloadLabIntakeForm: 'Baixar formulário de admissão de laboratório',
  downloadOptions: 'Baixar downloads',
  downloadResults: 'Baixar resultados',
  downloadRunSheet: 'Download da folha de execução',
  downloadScript: 'Faça o download do script',
  downloadShpfile: 'Faça o download do Shapefile',
  downloadSoilCollectorExportMsg: 'Solicitação de download de saída do SoilCollector enviada',
  drawBoundariesOnMap: 'Desenhe limites em um mapa',
  drawPolygon: 'Desenhe polígono',
  drawRectangle: 'Desenhe retângulo',
  dry: 'Seca',
  dscaInShipment:
    'As remessas de solo regulado também devem incluir o seguinte deslizamento de embalagem, que contém o número da DSCA da EarthOptics',
  dueDate: 'Data de vencimento',
  dueDateAndPriority: 'Data de vencimento e prioridade',
  dueDates: 'Datas de vencimento',
  earliestFtCreation: 'Criação mais antiga',
  earliestSample: 'Amostra mais antiga',
  earnings: 'Ganhos',
  edit: 'Editar',
  editAccount: 'Editar conta',
  editBatch: 'Editar Lote',
  editContract: 'Editar contrato',
  editDiscountProgram: 'Editar programa de desconto',
  editFieldBoundaries: 'Editar limites do campo',
  editFieldBoundary: 'Editar o limite do campo',
  editNotes: 'Editar notas',
  editOperation: 'Operação de edição',
  editPoints: 'Editar Pontos',
  editProject: 'Editar projeto',
  editProjectName: 'Editar nome do projeto',
  editQpcrCycles: 'Editar ciclos qPCR',
  editSamplePlan: 'Editar plano de amostra',
  editZoneValues: 'Editar valores de zona',
  editZones: 'Zonas de edição',
  effectOfEnvironment: 'Efeito do ambiente',
  eggs: 'Ovos',
  ele: 'Ele',
  email: 'E-mail',
  emailAddress: 'Endereço de email',
  emailAlreadyExistsMsg:
    'Esse email já tem uma conta com o EarthOptics. Use um email diferente ou suporte de contato para que esse usuário mova para esta agência.',
  emailCodeTo: 'Código de e-mail para',
  emiAdcp: 'EMI / ADCP',
  emi_needed_not_complete: 'EMI necessário, não concluído',
  empty: 'Vazio',
  enableMeterCoreDetails: 'Ativar detalhes do núcleo do medidor',
  endContract: 'Contrato final',
  endOfResults: 'Fim dos resultados',
  endUserLicenseAgreement: 'Contrato de Licença de Usuário Final',
  english: 'Inglês',
  enlargeMap: 'Ampliar Mapa',
  enterAccountName: 'Digite o nome da conta',
  enterAgencyName: 'Digite um nome de agência.',
  enterAnEmail: 'Digite um email',
  enterBarcodeMsg: 'Digite código de barras ou uuid',
  enterCodeSentTo: 'Digite o código de 6 dígitos enviado para',
  enterCommaSeparatedValues: 'Digite valores separados por vírgula',
  enterDescription: 'Digite a descrição',
  enterEmailToSignIn: 'Digite seu e-mail para entrar',
  enterName: 'Insira o nome',
  enterNameToSave: 'Digite o nome para salvar...',
  enterPasswordFor: 'Digite sua senha para',
  enterValidEmail: 'Digite um endereço de e-mail válido.',
  enterValidPhone: 'Digite um número de telefone válido.',
  environmentalConsiderations: 'Considerações Ambientais',
  eo_dispatch: 'Despacho de EO',
  error: 'Erro',
  errorApprovingTransaction: 'Ocorreu um erro ao tentar aprovar a transação.',
  errorBillingInfo: 'Erro ao atualizar informações de cobrança.',
  errorCancellingPlan:
    'Erro cancelamento de plano de amostragem. Por favor, tente novamente mais tarde.',
  errorContactInfo: 'Erro ao atualizar as informações de contato.',
  errorCreatingShippingLabel: 'Erro ao criar o rótulo de remessa.',
  errorDefaultShippingMsg:
    'Houve um erro definindo o local de envio padrão. Por favor, tente novamente.',
  errorDeletingPrescription: 'Ocorreu um erro ao excluir a prescrição. Por favor atualize.',
  errorDownloadingShpfile: 'Erro do download do shapfile. Tente mais tarde.',
  errorLoadingExistingPlanMsg:
    'Havia um problema carregando o plano existente. Por favor atualize.',
  errorLoggedInMsg: 'Seu token expirou. Por favor faça login novamente.',
  errorLoggingIn: 'Erro obtendo URL de login. Pode haver um problema de back -end.',
  errorLoggingInMsg: 'Havia um problema de login. Por favor, tente novamente.',
  errorMarkingOperationDiscount:
    'Erro ao marcar a operação como parte do programa de descontos. Tente novamente.',
  errorMarkingPlanDelivered:
    'Erro marcação de planos de amostragem conforme entregue. Tente novamente.',
  errorNewPassConf: 'A nova confirmação de senha não corresponde à nova senha.',
  errorNewPassRules: 'A nova senha fornecida não corresponde às regras de senha abaixo.',
  errorPasswordChange: 'Falha na mudança de senha.',
  errorSavingFieldMsg: 'Erro Salvando o campo. Por favor atualize.',
  errorSavingOperationMsg: 'Operação de economia de erro. Por favor atualize',
  errorSettingBillingResMsg:
    'Erro ao definir a responsabilidade de cobrança. Por favor, tente novamente.',
  errorWithFieldGeometryMsg:
    'Please check your field boundaries. There may be unnecessary intersections or holes.',
  estAcresAtRisk: 'Husa. HA em risco',
  estBenefit: 'Husa. Beneficiar',
  estReturn: 'Husa. Retornar',
  estTotalFarmPrice: 'Husa. Preço total da fazenda',
  estYieldAtRisk: 'Husa. Rendimento em risco',
  estYieldImpact: 'Husa. Rendimento de impacto',
  estimateBasedOnFarmPlan:
    'Esta é uma estimativa baseada no seu plano agrícola e no risco previsto da EarthOptics para 2023 em seu município.',
  estimateYourRisk: 'Para estimar seu risco, conte -nos sobre seu plano de colheita:',
  estimatedCompletionDate: 'Data prevista da conclusão',
  estimatedReturnAcross: 'Estimated return across your operation',
  eta: 'ETA',
  exc: 'Exc',
  excludeContractSignature: 'Excluir a assinatura do contrato?',
  excludeFromAnalysis: 'Excluir da análise',
  executeTargetAnalysisMsg: 'Execute a análise de metas no próximo ano',
  existingCollections: 'Coleções existentes',
  existingPassNoReuse: 'A senha existente não pode ser reutilizada.',
  expectedYield: 'Rendimento esperado',
  expirationDate: 'Data de validade',
  expired: 'Expirado',
  expiredOn: 'Expirou em',
  export: 'Exportar',
  exportCsv: 'Exportar CSV',
  exportData: 'Exportar dados',
  exportInitiatedMsg: 'Sua exportação foi iniciada!',
  exportProCsv: 'Exportar Pro CSV',
  exportProShapefile: 'Exportar Pro Shapefile',
  exportSelected: 'Exportar Selecionado',
  exportShapefile: 'Exportar Shapefile',
  externalLab: 'Laboratório externo',
  externalLabResults: 'Resultados do laboratório externo',
  extractionMethod: 'Método de Extração',
  failed: 'Fracassado',
  failedDeleteLabelsMsg: 'Falhou em excluir rótulos. Por favor, atualize e tente novamente.',
  failedGetRegulatedSoilMsg: 'Não é possível obter pdf do solo regulado.',
  failedSalesOrderJobs: 'Trabajos de órdenes de venta fallidos',
  failedToDownloadRegSoilMsg: 'Falha ao baixar o PDF regulamentado do solo.',
  failedToFetchMsg: 'Falhou em buscar meta dados. Por favor atualize.',
  failedToFetchProducts: 'Falha ao buscar produtos.',
  failedToFetchSeeds: 'Falha ao buscar sementes.',
  failedToFetchThresholdsMsg: 'Falhou em buscar limiares. Tente mais tarde.',
  failedToLoadClusMsg: 'Falha ao carregar CLUs',
  failure: 'Falha',
  fair: 'Justa',
  farm: 'Fazenda',
  farmField: 'Fazenda: Campo',
  farmName: 'Nome da fazenda',
  featureType: 'Tipo de recurso',
  featureFlags: 'Sinalizadores de recursos',
  fertility: 'Fertilidade',
  fertilityRx: 'Rx de Fertilidade',
  field: 'Talhão',
  fieldAlreadyExists: 'Um campo com esse nome já foi criado para sua operação.',
  fieldAndSamplePlanDetails: 'Detalhes do plano de campo e amostra',
  fieldAvg: 'Campo média',
  fieldComparison: 'Comparação de campo',
  fieldCost: 'Custo de campo',
  fieldCoverage: 'Cobertura de Campo',
  fieldDeletedMsg: 'Campo excluído com sucesso!',
  fieldName: 'Nome do campo',
  fieldRate: 'Taxa de campo',
  fieldResultsOverview: 'Resultados de campo - Visão geral',
  fieldRisk: 'Risco de Campo',
  fieldSavedMsg: 'Campo economizado com sucesso!',
  fieldSplitSuccessMsg: 'Campo dividido com sucesso!',
  fieldWork: 'Trabalho de campo',
  field_view: 'Field View',
  fields: 'Talhões',
  fieldsAddedToCollection: 'Campos adicionados à coleção',
  fieldsLower: 'Campos)',
  fieldsMergedSuccessMsg: 'Campos mesclados com sucesso!',
  fieldsWithErrors: 'Os campos com * são obrigatórios.',
  fileSizeTooLarge: 'O tamanho do arquivo deve ser inferior a 2 MB.',
  filename: 'Nome do arquivo',
  fillOutRequiredFields: 'Por favor, preencha todos os campos necessários antes de enviar.',
  filteredResults: 'Resultados Filtrados',
  financialTransactionUpdatedSuccessMsg: 'Transação financeira atualizada com sucesso!',
  firstName: 'Primeiro nome',
  firstSampleArrived: 'Amostra chegou pela primeira vez',
  foliar: 'Doenças Foliares',
  foliarFungicide: 'Fungicida foliar',
  foliarMissing:
    'As opções de proteção foliar não foram adicionadas ao plano de cultivo. Clique para revisar',
  foliarProducts: 'Produtos foliares',
  forgotPassword: 'Esqueceu sua senha?',
  formula: 'Fórmula',
  formulaDetails: 'Detalhes da Fórmula',
  forwardIndexSequence: 'Sequência de índice a diante',
  forwardPrimer: 'Primer para frente',
  fourOFourMsg:
    'Parece que a página que você está procurando está vazia. Experimente um dos links acima ...',
  freezer: 'Congelador',
  from: 'De',
  full: 'Completo',
  fullyAssigned: 'Totalmente atribuído',
  fungicide: 'Fungicida',
  fungicideInsecticide: 'Fungicida e inseticida',
  fungicideNematicide: 'Fungicida + nematicida',
  gate: 'Portão',
  gateCreatedMsg: 'Portão(s) criado(s) com sucesso',
  gates: 'Portões',
  generateCsvResults: 'Gerar resultados de CSV',
  generateFrom: 'Gerar de',
  generateMapbookMsg:
    'Gerando mapa de mapas... Isso levará alguns minutos. O download aparecerá no menu suspenso.',
  generateMapbookResults: 'Gerar resultados de mapa',
  generatePoints: 'Gerar Pontos',
  generateProCsvResults: 'Gerar resultados do Pro CSV',
  generateProShapefileResults: 'Gerar resultados do Pro Shapefile',
  generateRxFrom: 'Gerar médio de',
  generateSamplingPlan: 'Gerar plano de amostragem',
  generateShapefileResults: 'Gerar resultados de shapefile',
  generateZones: 'Gerar zonas',
  generating: 'Gerando',
  generatingPrescription: 'Gerando Prescrição',
  generic: 'Genérico',
  genomic: 'Genômico',
  genomicPlates: 'Placas Genômicas',
  genomicProcessing: 'Processamento genômico',
  genomicSamples: 'Amostras Genômicas',
  getStartedCreateAccount: 'Para começar, crie sua conta agrícola',
  goBack: 'Voltar',
  goToPlan: 'Vá para planejar',
  gold: 'Ouro',
  good: 'Bom',
  gossWilt: 'Wilt de Goss',
  grayLeafSpot: 'Ponto de folha cinza',
  grid_points: 'Pontos de grade',
  grid_zones: 'Zonas de grade',
  groupBy: 'Agrupar por',
  grower: 'Agricultor',
  harmfulBiomarkers: 'Biomarcadores nocivos (mais baixo é melhor)',
  hasGates: 'Tem portões',
  haveReadAndAgreeToPatternAg:
    'Ao clicar em “Concordo”, reconheço que li e compreendi o Contrato de Licença de Usuário Final e a Política de Privacidade da EarthOptics, e aceito e concordo em ficar vinculado a todos os termos e condições do Usuário Final da EarthOptics Contrato de licença:',
  haveReadAndAgreeToPatternAgIncludingTerms:
    'Ao clicar em “Concordo”, reconheço que li e compreendi o Termos e Condiçõess e Contrato de Licença de Usuário Final e a Política de Privacidade da EarthOptics, e aceito e concordo em ficar vinculado a todos os termos e condições do Usuário Final da EarthOptics Contrato de licença:',
  height: 'Altura',
  hideDetails: 'Detalhes ocultos',
  high: 'Alto',
  highContrast: 'Alto Contraste',
  highResNutrientScriptMsg: 'Prescrição de nutrientes em alta resolução',
  highResSoilFertMsg:
    'análise de fertilidade do solo em alta resolução com pixels de resolução de 10x10M',
  highResVariableRateScriptMsg: 'Prescrição de taxa variável de alta resolução',
  highRisk: 'Alto risco',
  highRiskAcres: 'Acres de alto risco',
  highRiskAreas: 'Áreas de alto risco',
  highRiskFields: 'Campos de Alto Risco',
  highRiskRwFieldTooltip:
    'A detecção de mais de 6 ovos indica alto risco de alimentação com raízes. Provavelmente varia de 0,5-2,5 lesão de nós sem proteção.',
  history: 'Histórico',
  homogen: 'Homogênio',
  homogenBarcode: 'Homogen Barcode',
  homogenization: 'Homogeneização',
  homogenizationComplete: 'Homogeneização completa',
  howItWorks: 'Como funciona',
  howWouldyouLikeToSignIn: 'Como você gostaria de entrar?',
  hybrid: 'Híbrido',
  hybridVariety: 'Híbrido/Variedade',
  imageLabel: 'Rótulo da imagem',
  imagery: 'Imagens',
  imageryMap: 'Mapa de Imagenes',
  imageryNotFound: 'Imagens indisponíveis',
  imageryNotFoundDescription:
    'As imagens não estão disponíveis para este campo. Utilize os dados de rendimento para criar uma prescrição de remoção. Se necessário, abaixo estão algumas opções para trazer dados de rendimento:',
  imageryZones: 'Zona de Imagens',
  imminentDueDateHeader: 'Data de vencimento iminente',
  imminentDueDateMessage: 'Esta amostra tem uma data de vencimento iminente em ',
  importData: 'Importar dados',
  inFurrow: 'Em sulco',
  inFurrowProducts: 'Produtos no Sulco',
  inPayment: 'Em pagamento',
  inProgress: 'Em andamento',
  inShipment: 'Em remessa',
  in_furrow: 'No sulco',
  in_furrowMissing:
    'As opções de proteção no sulco não foram adicionadas ao plano de cultivo. Clique para revisar',
  incentive: 'Incentivo',
  include: 'Incluir',
  incomplete: 'Incompleto',
  incorrectImageDimensions: 'As dimensões da imagem devem ser pelo menos 1x1.',
  incorrectPassword: 'A senha antiga que você inseriu está incorreta.',
  indexSet: 'Conjunto de índice',
  indexSets: 'Conjuntos de índice',
  indicatePlateStorageMsg: 'Indique onde e como a placa será armazenada',
  ingested: 'Ingerido',
  initiatedExportFailMsg: 'Falha ao iniciar a exportação. Atualize e tente novamente.',
  input: 'Parâmetro',
  inputSavings: 'Economia de entrada',
  insecticide: 'Inseticida',
  insufficientDataFound: 'Dados insuficientes encontrados',
  insufficientDataWarning:
    'Mais de 25% do seu campo tem dados insuficientes e você receberá um reembolso. As zonas marcadas como N/A não possuem dados suficientes.',
  insufficient_data: 'Dados insuficientes',
  insufficient_data_performance: 'Dados insuficientes (desempenho)',
  insufficient_layers_tillage: 'Camadas Insuficientes Para o Preparo',
  interLabShipping: 'Envio entre laboratórios',
  invalidCellPhoneMsg: 'Por favor, insira um número de celular válido.',
  invalidCornPriceMsg: 'Certifique -se de que seu preço do milho esteja entre US $ 2 e US $ 10.',
  invalidCornYieldMsg: 'Certifique -se de que seu rendimento de milho esteja entre 100 e 600.',
  invalidFile: 'Arquivo inválido enviado',
  invalidSoybeanPriceMsg: 'Verifique se o preço da sua soja está entre US $ 5 e US $ 20.',
  invalidSoybeanYieldMsg: 'Verifique se o seu rendimento de soja está entre 20 e 100.',
  invalidZipcode: 'CEP inválido',
  invalidZoneComboError: 'Combinação de zona inválida.',
  invite: 'Convidar',
  inviteAgentCreateAcctMsg:
    'Convide um agente para criar uma conta e trabalhar com os clientes da sua agência',
  inviteByEmail: 'Convide usuários por e -mail',
  inviteNewUser: 'Convidar novo usuário',
  inviteSampler: 'Convide Sampler',
  inviteUserMsg:
    'O usuário que você está convidando ainda não possui uma conta no EarthOptics. Forneça mais algumas informações sobre eles para completar o convite',
  invoice: 'Fatura',
  invoices: 'Faturas',
  isExistingAgency: 'É uma agência existente?',
  isThisPartOfExisting: 'Isso faz parte de um Cluster existente?',
  is_field_nd: 'É Campo ND',
  is_field_result_binary: 'É Resultado de Campo Binário',
  is_overview_nd: 'É Visão Geral ND',
  is_sample_nd: 'É Amostra ND',
  isolates: 'Isolados',
  isuLimeTo6: 'ISU Calcário para 6',
  isuLimeTo65: 'ISU Calcário para 6.5',
  isuLimeTo69: 'ISU Calcário para 6.9',
  isuPhosphorus: 'ISU Fósforo',
  isuPotassium: 'ISU Potássio',
  john_deere: 'John Deere',
  kclAnalysis: 'Análise de KCl',
  kclRerun: 'KCL Renum',
  keepTotalFarm: 'Manter o total da fazenda',
  keyOutcomeMsg: 'Chave para o sucesso do seu negócio',
  lab: 'Laboratório',
  labApp: 'Aplicativo de laboratório',
  labId: 'ID do laboratório',
  labPackage: 'Pacote de laboratório',
  labShipmentId: 'ID de remessa de laboratório',
  label: 'Rótulo',
  labelCount: 'Contagem de etiquetas',
  labelDeletedSuccessMsg: 'Etiquetas excluídas com sucesso!',
  labelDownloads: 'Downloads de etiquetas',
  labels: 'Etiquetas',
  labs: 'Laboratórios',
  lane: 'Pista',
  language: 'Linguagem',
  lastEdited: 'Última edição',
  lastName: 'Sobrenome',
  lastSync: 'Última sincronização',
  lastUpdated: 'Ultima atualização',
  latLon: 'Lat/Lon',
  latitude: 'Latitude',
  lbs: 'lbs',
  leaveOperation: 'Deixar operação',
  legend: 'Legenda',
  length: 'Comprimento',
  letsSetYourPassword: 'Vamos definir sua senha',
  level: 'Nível',
  levels: 'Níveis',
  levelsIndicateHighRisk: 'Os níveis indicam maior risco',
  levelsIndicateModerateRisk: 'Os níveis indicam risco moderado',
  levelsIndicateYieldLoss: 'Os níveis indicam risco de perda de rendimento',
  lime: 'Calcário',
  lineItemDetail: 'Detalhes do item de linha',
  list: 'Lista',
  listPrice: 'Preço de tabela',
  loadClus: 'Carregar CLUs',
  loading: 'Carregando',
  location: 'Localização',
  locations: 'Localizações',
  lockInAcresNow: 'Lock in acres now to ensure timely sampling',
  lockInOfferErrorMsg: 'Houve um erro de travamento em sua oferta. Por favor, tente novamente.',
  lockInOfferToday: 'Trava hoje',
  lockPoints: 'Pontos de bloqueio',
  lockZones: 'Zonas de bloqueio',
  locked: 'Trancado',
  lockedInAcres: 'Trancado em acres',
  login: 'Conecte-se',
  loginToQuickbooks: 'Faça login no QuickBooks',
  logout: 'Sair',
  longitude: 'Longitude',
  lossOnIgnition: 'Perda de Ignição',
  low: 'Baixo',
  lowRisk: 'Baixo risco',
  lowRiskAcres: 'Acres de baixo risco',
  lowRiskAreas: 'Áreas de baixo risco',
  lowRiskFields: 'Campos de Baixo Risco',
  lowRiskRwFieldTooltip:
    'A detecção de menos de 0,2 ovos indica baixo risco de danos econômicos. Provavelmente menos de 0,25 lesão de nós.',
  lowRiskRwZoneTooltip:
    'Nenhuma detecção de ovos indica baixo risco de danos econômicos. Provavelmente menos de 0,25 lesão de nós.',
  lowScanCoverageWarning: 'Aviso: baixa cobertura de digitalização',
  lowScanCoverageWarningMsg:
    'As varreduras selecionadas estão abaixo do limite recomendado de 75% de cobertura de campo. Isso pode impactar a qualidade do mapa resultante. Considere refazer a varredura ou enviar os arquivos ausentes.',
  m3Analysis: 'Análise M3',
  m3Rerun: 'M3 Renum',
  macronutrients: 'Macronutrientes',
  manage: 'Gerenciar',
  manageAccount: 'Gerenciar conta',
  manageAgencies: 'Gerenciar Clusters',
  manageAgency: 'Gerenciar Cluster',
  manageAgencyLocations: 'Gerenciar locais da agência',
  manageAgents: 'Gerenciar agentes',
  manageAnalytics: 'Gerenciar Analíticos',
  manageCatalogs: 'Gerenciar Catálogos',
  manageCommercialUsers: 'Gerenciar usuários comerciais',
  manageControls: 'Gerenciar controles',
  manageFeatures: 'Gerenciar recursos',
  manageFields: 'Gerenciar campos',
  manageGates: 'Gerenciar portões',
  manageLocations: 'Gerenciar locais',
  manageOrders: 'Gerenciar pedidos',
  manageProjects: 'Gerenciar Projetos',
  manageParentAgency: 'Gerenciar agência principal',
  manageUsers: 'Gerenciar usuários',
  manual: 'Manual',
  manualRecord: 'Registro manual',
  manualUpload: 'Carregar dados de rendimento manualmente',
  manufacturer: 'Fabricante',
  mapFields: 'Campos de mapa',
  mapFieldsToOrder: 'Mapear campos para fazer um pedido',
  mapSearchPlaceholder: 'Pesquisar cidade, condado etc.',
  mapYourPressure:
    '<b> Mapeie sua pressão: </b> O padrão mede sua biologia do solo para criar <b> mapas de subfield de pragas e da doença. </b>',
  mapbookConfiguration: 'Configuração do livro de mapas',
  mapbookDownloads: 'Downloads de mapas',
  mapbookGenerationFailedMsg: 'Falha na geração do livro de mapas. Por favor, tente novamente.',
  maps: 'Mapas',
  margin: 'Margem',
  markArrived: 'Mark chegou',
  markAsDelivered: 'Mark conforme entregue',
  markBatchConfirmMsg: 'Tem certeza de que deseja marcar este lote como enviado?',
  markBatchSent: 'Mark Batch Sent',
  markBatchWarningMsg:
    'Observe que este lote não será mais editável e as folhas de amostra serão trancadas!',
  markNotArrived: 'Marca Não Chegou',
  markNotReady: 'Não está pronto',
  markNotReadyFail:
    'Houve um erro marcando o plano como não está pronto. Por favor, atualize e tente novamente.',
  markNotReadySuccess: 'O plano foi marcado com sucesso como não está pronto.',
  markPlanDelivered: 'Marcos planos entregues',
  markReady: 'Mark pronto',
  markReadyMsg: 'Clicar neste botão marcará este campo como Pronto para amostra',
  markRecorded: 'Mark gravado',
  markSettled: 'Mark se estabeleceu',
  markSettledMsg:
    'Ao marcar esse pagamento como liquidado, você sabe que esse pagamento foi inserido nos registros contábeis da EarthOptics e foi tratado de acordo com os detalhes descritos abaixo:',
  markSterilized: 'Marca esterilizada',
  markedReadyAssignedMsg: 'Quando marcado pronto, um amostrador será atribuído',
  markedReadyFailureMsg:
    'Houve um erro marcando o plano pronto. Por favor, atualize e tente novamente.',
  markedReadySuccessMsg: 'O plano foi marcado com sucesso pronto.',
  marked_not_ready: 'Marcado não pronto',
  marked_ready: 'Marcado pronto',
  marked_ready_days: 'Marcado pronto (dias)',
  mass: 'Massa',
  max: 'Máximo',
  maxAcres: 'Acres máximos',
  maxAcresForEnrollment: 'Acres máximos para inscrição',
  maxDensity: 'Densidade Máxima',
  maxDepth: 'Profundidade Máxima',
  maxPsi: 'PSI Máx',
  maxScanDensity: 'Densidade de digitalização máxima',
  maxYield: 'Rendimento Máximo',
  maximumRate: 'Dosagem Máxima',
  measurementTypes: 'Tipos de medição',
  mehlich: 'Mehlich',
  merge: 'Mesclar',
  mergeFields: 'Mesclar campos',
  mergePlates: 'Mesclar Placas',
  mergeZones: 'Zonas de mesclagem',
  mergeZonesSuccess: 'Zonas mescladas com sucesso!',
  meterCoreSampleDetails: 'Detalhes da amostra de núcleo de 1m',
  method: 'Método',
  micronutrients: 'Micronutrientes',
  min: 'Min',
  minDensity: 'Densidade Mínima',
  minDepth: 'Profundidade Mínima',
  minScanDensity: 'Densidade de digitalização mínima',
  minYield: 'Rendimento Mínimo',
  minimalNumberPointsMsg: 'Adicione mais pontos para enviar o plano.',
  minimalProPointsMsg:
    'São necessários dos pontos de digitalização por campo - aumente a contagem da amostra.',
  minimalProPointsWarningMsg:
    'Advertencia: la calidad del escaneo puede verse afectada cuando los planes contienen menos de 4 puntos de muestra',
  minimumMeterCoreSamples: 'Mínimo de 1m de amostras centrais por estrato',
  minimumRate: 'Doagem Mínima',
  minutesAbbreviated: 'min',
  missingCountyMsg: 'Selecione um município.',
  missingCropDetailsMsg: 'Por favor, preencha todas as informações da colheita.',
  missingInfoMsg: 'Por favor, preencha todas as informações antes de salvar.',
  missingOperationIdMsg: 'Crie ou escolha uma operação existente.',
  missingSelectionOverlapMsg: 'Falta sobreposição entre campo e seleção.',
  missingUrlMsg: 'Falta upload_url',
  mod: 'Médio',
  modRisk: 'Risco Mod.',
  moderate: 'Moderado',
  moderateRisk: 'Risco moderado',
  moderateRiskFields: 'Campos de Risco Moderado',
  moderateRiskRwFieldTooltip:
    'A detecção de 0,2-6 ovos indica risco moderado de danos econômicos. Provavelmente varia de 0,25-0,7 lesão de nós sem proteção.',
  moderateRiskRwZoneTooltip:
    'A detecção de 0-6 ovos indica um risco moderado de danos econômicos. Provavelmente varia de 0,25-0,7 lesão de nós sem proteção.',
  modifiedMehlich: 'Mehlich Modificado',
  moisture: 'Umidade',
  moveSample: 'Mover amostra',
  msrp: 'MSRP',
  myAccounts: 'Minhas contas',
  myAgencies: 'Minhas agências',
  myAlerts: 'Meus Alertas',
  myCatalogs: 'Meus Catálogos',
  myFarmPlan: 'Meu plano de fazenda',
  myReport: 'Meu relatório',
  name: 'Nome',
  nameAtoZ: 'Nome A-Z',
  nameZtoA: 'Nome Z-A',
  naptControls: 'Controles NAPT',
  ndvi: 'NDVI',
  ndviMap: 'Mapa NDVI',
  ndviZones: 'Zonas NDVI',
  nematicide: 'Nematicida',
  netPayment: 'Pagamento líquido',
  netValue: 'Valor líquido',
  neutralization: 'Neutralização',
  new: 'Novo',
  newAgencyName: 'Novo nome da agência',
  newAnalytic: 'Novo Analítico',
  newBatch: 'Novo lote',
  newFieldName: 'Novo nome do campo',
  newOrParentContract: 'Novo ou contrato principal?',
  newPassConf: 'Nova confirmação de senha',
  newPassConfMissing: 'Falta nova confirmação de senha.',
  newPassMissing: 'Nova senha está faltando.',
  newPassword: 'Nova Senha',
  newPlate: 'Nova placa',
  newPlateBarcode: 'Novo código de barras de placa',
  newRiskEstimate: 'Nova estimativa de risco',
  newTerritory: 'Novo territorio',
  newestFirst: 'Mais novo primeiro',
  next: 'Próximo',
  nextStep: 'Próximo passo',
  nitrate: 'Nitrato',
  nitrateAnalysis: 'Análise de nitrato',
  nitrateAnalysisMsg: 'Add nitrate testing to each zone where nutrient analysis will be completed',
  nitratePanel: 'Painel de nitratos',
  nitrate_panel: 'Painel de nitrato',
  no: 'Não',
  noAgenciesExist: 'Nenhuma agência existe',
  noAnalysis: 'Sem análise',
  noBatchesFound: 'Nenhum lotes encontrados',
  noBillingUserMsg: 'Adicione uma responsabilidade de cobrança antes de fazer pedidos',
  noChemistryResultsMsg: 'Nenhum resultado de química para esta amostra UUID',
  noContractsExistMsg: 'Não existem contratos para esta agência.',
  noData: 'Sem dados',
  noDataForFieldAnalytic: 'Não há dados para este campo/análise.',
  noDataToReview: 'Nenhum Dado Para Revisar',
  noDealer: 'Sem revendedor',
  noDiscount: 'Sem desconto',
  noFileChosen: 'Nenhum arquivo selecionado',
  noFilesListMsg: 'Nenhum arquivo a ser exibido.',
  noIndexSetsFound: 'Nenhum conjunto de índice encontrado',
  noInstructionsAvailable: 'Sem instruções disponíveis.',
  noLabelsListMsg: 'Nenhuma etiqueta de envio encontrada',
  noLogMeOut: 'Não, desconecte-me',
  noNitrate: 'Sem nitrato',
  noOrderHistoryCompletedMsg:
    'Nenhum pedido concluído encontrado para o ano selecionado. Pedidos ainda em andamento serão exibidos na aba "Ativos e Concluídos".',
  noOrderHistoryMsg: 'Nenhum histórico de pedidos encontrado para o ano selecionado',
  noPlatesFoundMsg: 'Nenhuma placa encontrada',
  noPlatesListMsg: 'Sem placas para exibir.',
  noProductsToDisplay: 'Nenhum produto para exibir',
  noResultsExistForField: 'Não existem resultados para esta campo.',
  noResultsExistForOperation: 'Não existem resultados para esta operação.',
  noResultsForCategory: 'Nenhum resultado para esta categoria.',
  noRiskCancel: 'Sem risco, cancele a qualquer momento antes de amostragem',
  noSamplesInBatch: 'Sem amostras em lote.',
  noSamplingHistoryMsg: 'Nenhum histórico de amostragem encontrado para o ano atual',
  noSeedToDisplay: 'Nenhuma semente para exibir',
  noShipmentsFoundMsg: 'Nenhuma remessa encontrada',
  noUnits: 'Sem unidades',
  noUserFoundMsg: 'Nenhum usuário encontrado com esse e-mail.',
  noUserWithBillingResMsg:
    'Não há nenhum usuário com responsabilidade de cobrança para esta conta. Você poderá criar planos de amostra para esta conta, mas não poderá coletar amostras em campo até que um usuário seja adicionado aqui.',
  noWeNeedToCreate: 'Não, precisamos criar uma nova agência para eles.',
  noZonesSelected: 'Sem zonas selecionadas para análise',
  nonPrimary: 'Não primário',
  non_pro_till_rx: 'Não Pro/TillMapper',
  none: 'Nenhum',
  normDna: 'DNA norma',
  normalizedDna: 'DNA normalizado',
  northCornLeafBlight: 'Praga de folha de milho norte',
  notAnalyzed: 'Não analisado',
  notAssignedNotReady: 'Não atribuído + não pronto',
  notDetected: 'Não detectado',
  notInvoiced: 'Não faturado',
  notOrdered: 'Não encomendado',
  notReady: 'Não pronto',
  notSentToNetsuiteMsg: 'Os planos de amostragem de operação não serão cobrados no NetSuite.',
  notStarted: 'não foi iniciado',
  notSyncedYet: 'Ainda não está sincronizado',
  notTerritoryOwner:
    'Seu território designado não foi criado por você. Pressione Limpar para redefinir o mapa se quiser desenhar o seu.',
  notes: 'Notas',
  numberOfLabels: 'Número de etiquetas',
  nutrient: 'Nutriente',
  nutrientDetails: 'Detalhes dos nutrientes',
  nutrientPanel: 'Painel de nutrientes',
  nutrientPoints: 'Pontos de Nutrientes',
  nutrientProcessing: 'Processamento de nutrientes',
  nutrientRx: 'Médio de nutrientes',
  nutrientSamples: 'Amostras de nutrientes',
  nutrient_analysis: 'Análise de Nutrientes',
  nutrient_panel: 'Painel de nutrientes',
  nutrient_panel_description:
    'Painel Completo de Macro e Micronutrientes: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Matéria Orgânica',
  nutrient_panel_description_plus_nitrate:
    'Full Macro & Micronutrient panel: P, K, Ca, Mg, B, Cu, Fe, Mn, S, Zn, pH, BpH, Organic Matter, além de testes de Nitrato',
  nutrient_panel_list_high_msg: 'O preço de tabela do Padrão 360 é muito alto.',
  nutrient_panel_list_low_msg: 'O preço de tabela do Padrão 360 é muito baixo.',
  nutrients: 'Nutrientes',
  oats: 'Aveia',
  of: 'de',
  off: 'Desligado',
  offer: 'Oferta',
  oldPassMissing: 'A senha antiga está faltando.',
  oldPassword: 'Senha Antiga',
  oldestFirst: 'Mais antigo primeiro',
  olsen: 'Olsen',
  omAnalysis: 'Análise de OM',
  omRerun: 'OM Renum',
  omniRevvityDescription: 'omni lise e magbead com método TBD para quelar íons metálicos',
  omniRevvityPbWashDescription: 'omni lise e magbead com lavagem PB',
  on: 'Sobre',
  only: 'Apenas',
  open: 'Abrir',
  openPlate: 'Placa aberta',
  openRisks: 'Riscos abertos',
  openTransactions: 'Transações abertas',
  operation: 'Operação',
  operationCountryMsg: 'Este é o país onde a operação está localizada.',
  operationDetails: 'Detalhes da operação',
  operationName: 'Nome da Operação',
  operationNoDiscountsMsg: 'Esta operação não possui programas de descontos.',
  operationSavedMsg: 'Operação salva com sucesso!',
  operationSummary: 'Resumo da operação',
  operations: 'Operações',
  operator: 'Operador',
  operatorEmail: 'Email do operador',
  operatorName: 'Nome do operador',
  optOutText:
    'Você optou por não receber notificações da EarthOptics por mensagem de texto. Aguarde 24 horas para que as alterações entrem em vigor.',
  optimal: 'Ótimo',
  optimizeInputs: "Otimize as entradas com base no relatório do padrão 360 sobre campos 'em risco'",
  optimizePlans: 'Otimizar planos',
  optimizeSpend: 'Otimizar gastos',
  optimizeTillage:
    'Otimize as decisões de preparo do solo e entenda a compactação do subcampo com o TillMapper',
  optimizedPlansErrorMsg: 'Erro ao otimizar planos. Atualize e tente novamente.',
  optimizedPlansSuccessMsg: 'Planos otimizados com sucesso!',
  optimizingPlans: 'Otimizando planos',
  optional: 'opcional',
  options: 'Opções',
  or: 'Ou',
  orYouCan: 'Ou você pode',
  order: 'Ordem',
  orderAdditionalSamplePlan: 'Adicionar plano de amostra adicional',
  orderHistory: 'Histórico de Pedidos',
  orderSample: 'Requisitar amostra',
  orderSamplePlan: 'Plano de amostra de pedido',
  orderSamplingPlan: 'Plano de amostragem de pedidos',
  orderTillMapperNow: 'Encomende o TillMapper agora',
  ordered: 'Ordenado',
  orders: 'Ordens',
  other: 'Outro',
  otherFields: 'Outros campos',
  outputFormula: 'Formato de saída',
  overrideInputsMsg:
    'Todas as seleções de entrada serão substituídas em todos os planos de cultivo!',
  overview: 'Visão geral',
  overviewOnly: 'Visão geral apenas',
  owner: 'Proprietário',
  package: 'Pacote',
  'pagi-2024': 'PAGI',
  panel: 'Painel',
  panelsOrdered: 'Painéis Encomendados',
  parcelDimensions: 'Dimensões de encomendas',
  parentAgency: 'Agência principal',
  parentPlates: 'Placas parentais',
  parent_agency: 'Agência principal',
  partial: 'Parcial',
  partialResults: 'Resultados parciais',
  'partially received': 'Recebido parcialmente',
  'partially sampled': 'Parcialmente amostrado',
  'partially shipped': 'enviado parcialmente',
  partiallyFilled: 'Parcialmente preenchido',
  partiallyShipped: 'enviado parcialmente',
  partially_sampled_long_duration: 'Parcialmente amostrado (longa duração)',
  partner: 'Parceiro',
  password: 'Senha',
  passwordReqMsg: 'Deve conter 8 caracteres, incluindo uma letra em maiúsculas e um número',
  passwordRules: 'Nota: deve conter uma letra de maiúsculas, letra minúscula e um número',
  pastDueDateHeader: 'Data de vencimento vencida',
  pastDueDateMessage: 'Esta amostra expirou a data de vencimento em',
  pastFileUpload: 'Upload de arquivos anteriores',
  pattern360Msg:
    'Combine a biografia completa com nossa análise abrangente de macro e micronutrientes para identificar deficiências de nutrientes e equilibrar seu solo (não disponível para solo regulado neste momento)',
  patternAgDSCANumber: 'Nota de remessa com Número DSCA da EarthOptics',
  patternAgPays: 'EarthOptics paga',
  patternAgPersonalizedReport:
    'Seu relatório Padrão 360 fornecerá análises e recomendações personalizadas para sua fazenda.',
  patternIsSyncing:
    'O EarthOptics está sincronizando com sua conta. (Isso pode levar alguns minutos)',
  patternReportAnalyzes:
    'O relatório Padrão 360 analisa seu solo para <b> prever o risco do próximo ano </b> de prejudicar pragas e doenças.',
  pattern_360: 'Padrão 360',
  payAsYouGo: 'Pague conforme o uso',
  payAsYouGoMsg: 'Pague somente quando a análise de campo for concluída',
  payment: 'Pagamento',
  paymentTerms: 'Termos de pagamento',
  payment_due: 'Pagamento devido',
  payments: 'Pagamentos',
  payor: 'Pagador',
  payorPayee: 'Pagador / beneficiário',
  paysPatternAg: 'Paga o EarthOptics',
  pcrDnaMultiplier: 'Multiplicador de DNA de PCR',
  pdfRegenerationInitiatedErrorMsg:
    'Erro ao iniciar a regeneração de PDF. Atualize e tente novamente.',
  pdfRegenerationInitiatedMsg: 'Regeneração de PDF iniciada!',
  pending: 'Pendente',
  pendingDelivery: 'Entrega pendente',
  per: 'Por',
  perAcreSkus: 'Por Acre (SKUs)',
  perSampleIndicates: 'por amostra indica',
  perSampleSkus: 'Por Amostra (SKUs)',
  percentCompacted: '% Compactado',
  percentCompactedTooltip: '% > 300 PSI (0"-12")',
  percentOfFarmAnalyzedPerYear: 'Porcentagem de fazenda analisada por ano',
  percentOfField: '% de Campo',
  percentOfFieldsAtModerateOrHighRisk: 'Porcentagem De Campos Com Risco Moderado Ou Alto',
  percentTargetApply: 'Porcentagem da meta para aplicar',
  perfPanel: 'Painel de perf',
  performAnalysisSingleDensity: 'Realizar todas as análises em uma única densidade',
  performance: 'Desempenho',
  performancePanel: 'Painel de desempenho',
  performance_panel: 'Painel de desempenho',
  performance_panel_description:
    'Mapeie o risco de doença do solo e a oportunidade de biofertilidade, para otimizar os gastos com insumos',
  performance_panel_list_high_msg: 'O preço da lista Biografia Completa é muito alto',
  performance_panel_list_low_msg: 'O preço da lista Biografia Completa é muito baixo',
  perYear: 'Por ano',
  phAnalysis: 'Análise de pH',
  phRerun: 'pH Renum',
  phone: 'Telefone',
  phosphate: 'Fosfato',
  phosphateAnalysis: 'Análise de fosfato',
  phosphateRerun: 'Fosfato Renum',
  phosphorus: 'Fósforo',
  pipeline: 'Gasoduto',
  planConfMsg:
    'Ao clicar em "Confirmar Ordem", você concorda em comprar os serviços descritos acima para esta operação. O pagamento será devido 2 semanas após a disponibilização dos resultados.',
  planDetails: 'Detalhes do Plano',
  planMarkedDeliveredSuccess: 'Planos marcados como entregues com sucesso!',
  planName: 'Nome do plano',
  planNames: 'Nome(s) do Plano',
  planStatus: 'Status do plano',
  planSummary: 'Resumo do plano',
  planZonesErrorDiffMsg: 'Você pode ter enviado um campo diferente.',
  planZonesErrorNoZipMsg:
    'Você deve fazer upload de um arquivo zippeado contendo um arquivo ".shp" ssurgo',
  planZonesErrorNoZonesMsg: 'Este upload não contém zonas.',
  planZonesSuccessMsg: 'As zonas foram atualizadas com sucesso!',
  plannedCrop: 'Colheita planejada',
  plannedSamples: 'Amostras planejadas',
  planning: 'Planejamento',
  plantingRate: 'Taxa de Plantio',
  plate: 'Placa',
  plateAddedToBatch: 'Placa adicionada ao lote',
  plateArrived: 'Placa chegou',
  plateBarcode: 'Código de barras da placa',
  plateBarcodeId: 'Código de barras de placa / id',
  plateDetails: 'Detalhes da placa',
  plateHasBatchErrorMsg:
    'A placa já existe em outro lote. Adicionar a placa a este lote o removerá do lote anterior',
  plateNotAddedToBatch: 'Placa não adicionada ao lote',
  plateType: 'Tipo de placa',
  plated: 'Banhado',
  plates: 'Pratos',
  platinum: 'Platina',
  pleaseRefreshUnableToConnect:
    'Por favor, atualize e tente novamente. Não conseguimos conectá -lo a',
  pointResults: 'Resultados de Pontos',
  points: 'Pontos',
  poolBarcode: 'Código de barras da piscina',
  portuguese: 'Português',
  postOperationCropInfoErrorMsg:
    'Houve um erro atualizando as informações da colheita. Por favor, atualize e tente novamente.',
  potassium: 'Potássio',
  preTransit: 'Pré-trânsito',
  predictYourRisk:
    '<b>Preveja seus riscos: </b> Mapas de pragas e doenças ajudam a <b>prever os riscos de perda de produtividade</b> para a próxima safra.',
  predicted_ready: 'Previsto pronto',
  premiumFeatures: 'Recursos premium',
  prep: 'Preparação',
  preparedBy: 'Preparado por',
  preplant: 'Pré-planta',
  prescriptionCreatedFailMsg: 'Falha ao criar a prescrição. Atualize e tente novamente.',
  prescriptionCreatedMsg: 'Prescrição criada!',
  prescriptionDownloadFailMsg: 'Falha ao baixar a prescrição. Atualize e tente novamente.',
  prescriptionDownloadSuccessMsg: 'Receita baixada com sucesso!',
  prescriptionSaved: 'Prescrição salva',
  prescriptions: 'Prescrições',
  pressure: 'Pressão',
  pressureAndPerformancePanelsZones: 'Zonas de painéis de pressão e desempenho',
  pressureNutrientPanel: 'Painel de pressão + Painel de nutrientes',
  pressureNutrientPanelsDescription:
    'Nosso Painel de Pressão mais nossa análise abrangente de macro e micronutrientes',
  pressurePanel: 'Painel de pressão',
  pressure_nutrient_panels: 'Painel de Pressão + Nutrientes',
  pressure_panel: 'Painel de pressão',
  pressure_panel_description:
    'Preveja e planeje o nematóide do nó da raiz e o nematodo do cisto de soja, medindo sua pressão antes de plantar',
  pressure_panel_description_rkn:
    'Preveja e planeje o Nematóide das Galhas, o Nematóide do Cisto da Soja e a Síndrome da Morte Súbita medindo sua pressão antes do plantio',
  pressure_panel_list_high_msg: 'O preço de lista del Panel de Presión é muito alto.',
  pressure_panel_list_low_msg: 'O preço de lista del Panel de Presión é muito baixo.',
  pressure_panel_only: 'Painel de pressão',
  previousCrop: 'Safra Anterior',
  price: 'Preço',
  priceList: 'Lista de preços',
  pricePerAcre: 'Preço por acre',
  pricePerTon: 'Preço por tonelada',
  pricePerZone: 'Preço por zona',
  priceType: 'Tipo de preço',
  primary: 'Primário',
  primaryAgent: 'Agente primário',
  primaryCounty: 'Condado primário',
  print: 'Imprimir',
  printAll: 'Imprimir Todas',
  printHeader: 'Seu solo está falando. É hora de ouvir.',
  printLabel: 'Etiqueta de impressão',
  printOptions: 'Opções de impressão',
  printQRCode: 'Imprimir código QR',
  printSplitDensityBarcode: 'Imprimir código de barras de densidade dividida',
  printSummary: 'Resumo da impressão',
  priority: 'Prioridade',
  prioritySampleHeader: 'Amostra Prioritária',
  privacyPolicy: 'Política de Privacidade',
  pro: 'Pro',
  pro10Res: 'Pro (Resolução de 0x10 metros)',
  proResults: 'Resultados Pro',
  pro_map_generation: 'Geração de Mapa Pro',
  pro_only: 'Apenas Pro',
  pro_sensor_scan: 'Pro Sensor Scan',
  process: 'Processo',
  processed: 'Processado',
  processing: 'Em processamento',
  processingData: 'Processando dados',
  processingSummary: 'Resumo do processamento',
  product: 'Produto',
  productAdmin: 'Administrador de Produto',
  productDesc360Complete:
    'O Pacote Completo: TruNutrient, TruBio e TruTill oferecem a análise mais abrangente do ambiente do seu solo disponível hoje. Planeje a fertilidade, sementes, proteção de cultivos, biofertilidade e atividades de preparo do solo para a próxima temporada, otimizando custos e rentabilidade.',
  productDesc360ProTnTb:
    'O Original 360: Uma visão abrangente de 360° do seu solo, incluindo análise de fertilidade em alta resolução e o perfil biológico mais completo das pragas, patógenos e indicadores de biofertilidade que você precisa para planejar.',
  productDescCustom:
    'Opções à la carte para a análise do seu solo (alterar qualquer uma das opções abaixo definirá automaticamente o produto como "Custom").',
  productDescTbTt:
    'Planeje sementes, proteção de cultivos, biofertilidade e preparo do solo usando as análises mais avançadas para mapear os componentes físicos e biológicos do solo.',
  productDescTnTt:
    'Planeje atividades de fertilidade e preparo do solo usando a visão mais aprofundada e de alta resolução sobre os níveis de nutrientes e compactação.',
  productDescTruBio:
    'Análise biológica aprofundada do solo para entender riscos e oportunidades. Planeje sementes e proteção de cultivos com base nos riscos de patógenos e aplique produtos de biofertilidade com confiança para aumentar a produtividade.',
  productDescTruNutrient:
    'Análise de nutrientes em alta resolução em grades de 10x10m, permitindo criar os planos e prescrições de aplicação de fertilidade mais precisos e exatos.',
  productDescTruTill:
    'Monitore a compactação de cada centímetro do seu solo até 45 cm de profundidade, em grades de 10x10m. A única análise desse tipo, combinada com uma prescrição de preparo do solo para corrigir a compactação com precisão.',
  productName: 'Nome do produto',
  productOrdered: 'Produto ordenado',
  profileSettings: 'Configurações de perfil',
  programToEnrollMsg: 'Qual programa você deseja se inscrever?',
  project: 'Projeto',
  projectBuilder: 'Construtor de projetos',
  projectCreatedMsg: 'Projeto criado com sucesso',
  projectName: 'Nome do projeto',
  projectUpdatedMsg: 'Projeto atualizado com sucesso',
  projects: 'Projetos',
  protection: 'Proteção',
  provideNotesMsg: 'Forneça notas para o documento enviado',
  puppeteerRunningMsg: 'Gerando Resultados do Mapbook (Nem todos os campos estão prontos)',
  purchase: 'Compras',
  purchases: 'Compras',
  qpcrAnalysis: 'Análise QPCR',
  qpcrCycles: 'Ciclos QPCR',
  quadrant: 'Quadrante',
  rack: 'Prateleira',
  range: 'Amplitude',
  ranges: 'Faixas',
  ranging: 'variando',
  rate: 'Taxa',
  rating: 'Avaliação',
  rdAttributes: 'Atribuições de P&D',
  rdProtocols: 'Protocolos de P&D',
  readThresholdUpdateFailureMsg: 'A atualização do limite de leitura falhou',
  readThresholdUpdateSuccessMsg: 'Leia a atualização do limite bem -sucedido',
  readiness: 'Prontidão',
  reads: 'Lê',
  ready: 'Preparar',
  readyToSample: 'Pronto para provar',
  readyToScan: 'Pronto para digitalizar',
  reasonForAdjustments: 'Qual é o motivo do ajuste?',
  reassign: 'Reatribuição',
  recalculate: 'Recalcular',
  received: 'Recebido',
  receivingDataEntry: 'Recebimento e entrada de dados',
  recenter: 'Recente',
  recommendation: 'Recomendação',
  recommendationDefaults: 'Padrões de Médio',
  recommendationSets: 'Conjuntos de Médio',
  recordedBy: 'Gravado por',
  reducedNoTill: 'Reduzido / sem plantão',
  referenceLink: 'Link de referência',
  referencesAndResources: 'Referências e Recursos',
  regenerateMapbookPDFsMsg: 'Regenerar PDFs do Mapbook (Exibição de resultados desatualizados)',
  region: 'Região',
  registry: 'Registro',
  regulated: 'Regulamentado',
  regulatedMaterial: 'Material regulamentado',
  regulatedMaterialMsg:
    'Se estiver enviando material regulamentado, certifique-se de que APENAS materiais regulamentados estejam na remessa',
  regulatedSoil: 'Solo regulado',
  regulatedSoilMsg:
    'Se este plano de amostra contiver solo regulado, revise os protocolos necessários e verifique se você está concluindo cada etapa antes do envio',
  regulatedSoilsSop: 'POP de envio de solos regulamentados',
  rejected: 'Rejeitado',
  relatedAgencies: 'Agências relacionadas',
  relatedDocuments: 'Documentos relacionados',
  relatedRsms: 'RSMS relacionados',
  relativeNeutralization: 'Neutralização Relativa',
  reloadClus: 'Recarregar CLUs',
  removal: 'Remoção',
  removalKIsu: 'Remoção do Estado de Iowa - K',
  removalKTriState: 'Remoção de Três Estados - K',
  removalPIsu: 'Remoção do Estado de Iowa - F',
  removalPSdsu: 'Remoção do SDSU - F',
  removalKSdsu: 'Remoção do SDSU - K',
  removalPTriState: 'Remoção de Três Estados - F',
  removalRate: 'Taxa de Remoção',
  remove: 'Remover',
  removeAccess: 'Remova o acesso',
  removeAgencyConnectionMsg:
    'Isso removerá o acesso da agência a esta operação. No entanto, quaisquer agentes diretamente ligados a esta operação continuarão a ter acesso.',
  removeAgencyLocation: 'Remover Agência/Localização?',
  removeAgencyLocationWarning: 'Isso removerá a conexão Agência/Localização. Tem certeza?',
  removeAnalyticFromView: 'Remover Analítico da Visualização?',
  removeBillingPartyMsg:
    'Tem certeza de que deseja remover esse usuário da responsabilidade de cobrança por esta conta? Sem um usuário responsável pelo faturamento na conta, você não poderá coletar amostras no campo.',
  removeBillingResponsibility: 'Remova a responsabilidade de cobrança',
  removeDueDate: 'Remover data de vencimento',
  removeFieldMsg:
    'Remover esse campo da sua operação significa que o campo e seus dados associados não estão mais disponíveis para você. Isto não pode ser desfeito.',
  removeFieldsFromProject: 'Remover campos do projeto',
  removePriority: 'Remover prioridade',
  removeSample: 'Remova a amostra',
  removeSearchBounds: 'Remover limites de pesquisa',
  removeSelected: 'Remover Selecionado',
  removeSelectedFields: 'Remover campos selecionados',
  removed: 'Removido',
  removedFromPlan: 'Removido do plano',
  replicaType: 'Tipo de réplica',
  report: 'Relatório',
  requestFailed: 'Solicitação falhou. Por favor, tente novamente mais tarde.',
  requiredField: 'Campo obrigatório',
  rerun: 'Reencontrar',
  reselectDensity: 'Reselecionar a densidade',
  resendCode: 'Reenviar código',
  reset: 'Redefinir',
  resetMap: 'Redefinir mapa',
  resetPoints: 'Redefinir Pontos',
  residue: 'Resíduo',
  resistanceOptions: 'Opções de resistência',
  restrictedGeography: 'Geografia restrita',
  restrictedGeographyFromMsg: 'Amostra é de',
  restrictedGeogrpahyProtocolsMsg: 'Siga protocolos de manuseio adequados',
  restricted_panel_description:
    'A análise de nutrientes não está disponível para solo restrito neste momento',
  resultInPatternInvoiceMsg: 'Isso resultará no faturamento do EarthOptics',
  resultInPatternPaymentMsg: 'Isso resultará em EarthOptics fazendo o pagamento para',
  results: 'Resultados',
  resultsDownloadFailure: 'Erro ao baixar resultados. Tente mais tarde.',
  resultsDownloadSuccess: 'O download dos resultados foi concluído com sucesso.',
  resultsUploaded: 'Resultados enviados',
  retry: 'Tentar novamente',
  return: 'Retornar',
  returned: 'Devolvido',
  revenue: 'Receita',
  reverseIndexSequence: 'Sequência de índice reverso',
  reversePrimer: 'Primer reverso',
  reviewOrder: 'Revisar pedido',
  rhizosphere: 'Rizosfera',
  risk: 'Risco',
  riskEstimate: 'Estimativa de risco',
  riskMap: 'Mapa De Risco',
  rndAttributes: 'Atributos de P&D',
  rndProtocols: 'Protocolos de P&D',
  rndSample: 'Amostra de P&D',
  role: 'Papel',
  rootBall: 'Bola da raiz',
  rootTissue: 'Tecido radicular',
  rootworm: 'ROOTWORM',
  rotate: 'Girar',
  runPlanned: 'Executar planejado',
  runSheetDownloadFailed: 'Falha ao baixar a folha de execução. Tente mais tarde.',
  rwSingle: 'Rw único',
  rwStack: 'RW Stack',
  rx: 'Recomendação',
  rxBase: 'Base Rx',
  rxBaseLayer: 'Camada Base Rx',
  rxDetails: 'Detalhes da recomendação',
  rxName: 'Nome da médio',
  salesOrderCreatedMsg: '¡Creación de orden de venta iniciada!',
  sample: 'Amostra',
  sampleAlreadyExistsMsg:
    "Esse código de barras está associado a uma amostra existente. Se este for para uma amostra replicada, vá para 'Adicionar amostra'. Se você pretende criar uma nova amostra no sistema, escolha um código de barras diferente.",
  sampleAlreadyInWellMsg: 'A amostra já existe em outro poço, use uma replicação',
  sampleBarcode: 'Amostra de código de barras',
  sampleCancelledFailureMsg: 'Cancelar a amostra falhou',
  sampleCancelledSuccessMsg: 'A amostra foi cancelada com sucesso',
  sampled_not_shipped: 'Amostrado, não enviado',
  sampled: 'Amostrado',
  sampleDate: 'Data da Amostra',
  sampledBy: 'Amostrado por',
  sampleDeletedMsg: 'Amostra excluída com sucesso',
  sampleExecution: 'Execução de amostra',
  sampleIdMap: 'Mapa de ID de Amostra',
  sampleMass: 'Massa de amostra',
  sampleMustBeOnField: 'A amostra deve estar no campo',
  sampleNotFound: 'Amostra não encontrada',
  sampleNotFoundMsg:
    'Nenhuma instância de amostra que corresponda este código de barras foi encontrada',
  sampleOnly: 'Apenas Amostra',
  samplePlan: 'Plano de amostra',
  samplePlanCancelledSuccess: 'Plano de amostragem cancelado!',
  samplePlanDetails: 'Detalhes do plano de amostra',
  samplePlanReadThreshold: 'Limite de leitura do plano de amostra',
  samplePlans: 'Planos de amostra',
  samplePlansAwaiting: 'Planos de amostra aguardando remessa',
  samplePlanStatus: 'Status do plano de amostra',
  samplePlanTracking: 'Rastreamento de plano de amostra',
  samplePlusScan: 'Amostra + Digitalização',
  samplePricing: 'Preço de amostra',
  sampleRisk: 'Risco de amostra',
  sampleSheet: 'Folha de amostra',
  sampleStatus: 'Status da amostra',
  sampleSummary: 'Resumo da amostra',
  sampleTiming: 'Timing de amostra',
  sampleType: 'Tipo de amostra',
  sampleUpdated: 'Amostra atualizada',
  sampledByExternalLabMsg:
    'EarthOptics no puede muestrear los planos con muestras de laboratorio externas',
  sampler: 'Amostrador',
  samplerInstructions: 'Instruções de amostrador',
  samplerNotes: 'Notas do amostrador',
  samplerOnly: 'Apenas amostrador',
  samples: 'Amostras',
  samplesInPool: 'Amostras na piscina',
  samplesTaken: 'Amostras tomadas',
  samples_not_complete: 'Amostras não completas',
  sampling: 'Amostragem',
  samplingAt: 'Amostragem Em',
  samplingHistory: 'Histórico de Amostragens',
  samplingPlan: 'Plano de amostragem',
  samplingPlanCreated: 'Plano de amostragem criado',
  samplingPlanErrors: 'Erros no plano de amostragem',
  samplingPlanInProgress: 'Criação do plano de amostragem em andamento',
  samplingPlanUpdatedFailureMsg:
    'Falha ao atualizar o plano de amostragem. Atualize e tente novamente.',
  samplingPlanUpdatedMsg: 'Plano de amostragem atualizado com sucesso!',
  samplingPoints: 'Pontos de amostragem',
  samplingTiming: 'Timing de amostragem',
  samplingZones: 'Zonas de amostragem',
  sampling_at_risk: 'Amostragem em risco',
  sampling_overdue: 'Amostragem vencida',
  save: 'Salvar',
  saveBillingInfo: 'Salvar informações de cobrança',
  saveChanges: 'Salvar alterações',
  saveContactInfo: 'Salvar informações de contato',
  saveContractChanges: 'Salvar alterações de contrato',
  saveCostsByTilling: 'Economize custos cultivando apenas onde for necessário',
  saveZoneConfig: 'Salvar configuração da zona?',
  saved: 'Salvo',
  savedZoneConfigs: 'Configurações de Zona Salvas',
  scan: 'Digitalização',
  scanBarcodeMsg: 'Digitalize o código de barras da placa',
  scanDataConfirmed: 'Dados de digitalização confirmados',
  scanDataReceived: 'Dados de digitalização recebidos',
  scanNewBarcode: 'Digitalizar novo código de barras',
  scanNewBarcodeMsg: 'Digitalize o novo código de barras de placa',
  scanOnly: 'Apenas Digitalização',
  scanTimeframe:
    'Depois que as varreduras forem confirmadas, os resultados deverão estar disponíveis dentro de uma hora',
  scanned: 'Digitalizado',
  scannedBy: 'Digitalizado por',
  scanner: 'Scanner',
  scanningAt: 'Digitalizando Em',
  scanningTime: 'Durée de Digitalização',
  scn: 'Scn',
  score: 'Placar',
  sds: 'SDS',
  sdsFungicide: 'SDS + fungicida',
  sdsFungicideNematicide: 'SDS + fungicida + nematicida',
  sdsNematicide: 'SDS + nematicida',
  sdsuLime: 'SDSU Calcário',
  sdsuPhosphorusBray: 'SDSU/MN Fósforo (Bray)',
  sdsuPhosphorusOlsen: 'SDSU/MN Fósforo (OLSEN)',
  sdsuPotassium: 'SDSU/MN Potássio',
  search: 'Pesquisar',
  searchAccount: 'Procure por uma conta',
  searchAccountOrViewAll: 'Procure uma conta ou veja todas',
  searchAgency: 'Agência de pesquisa',
  searchAgencyName: 'Nome da agência de pesquisa',
  searchAnalytic: 'Pesquisar Analítico',
  searchByAccount: 'Pesquise por conta',
  searchByBarcode: 'Pesquise por código de barras',
  searchByName: 'Pesquisar por nome',
  searchByUser: 'Pesquise por usuário',
  searchByUserAgency: 'Pesquise por usuário/agência',
  searchCounty: 'Search County',
  searchCountyError: 'Ocorreu um erro ao procurar o município especificado. Por favor atualize.',
  searchCurrentBounds: 'Pesquisar limites atuais',
  searchForDealer: 'Procurar por um revendedor',
  searchForGrower: 'Procurar por um agricultor',
  searchOperation: 'Operação de pesquisa',
  searchOperationOrAgency: 'Digite uma operação ou agência',
  searchPayments: 'Pagamentos de pesquisa',
  searchSampleMsg: 'Procure uma amostra por código de barras ou UUID',
  searchSampler: 'Pesquise amostrador',
  searchSamplerScanner: 'Pesquisar amostrador/Scanner',
  searchUsers: 'Pesquise usuários',
  searching: 'Procurando',
  season: 'Safra',
  secondaryAnalytic: 'Analítico Secundário',
  secondaryAnalyticMsg: 'Esta análise será exibida se a análise atual não tiver resultados.',
  seeHowItWorks: 'Veja como funciona',
  seed: 'Semente',
  seedCatalog: 'Catálogo de sementes',
  seeDetailsBelow: 'Veja os detalhes abaixo',
  seedMissing: 'As opções híbridas não foram adicionadas ao plano de cultivo. Clique para revisar',
  seedResistance: 'Resistência de sementes',
  seedRisks: 'Riscos de sementes',
  seedRx: 'Rx de sementes',
  seedTreatment: 'Tratamento de sementes',
  seedTreatments: 'Tratamentos de Sementes',
  seed_treatment: 'Tratamento de sementes',
  seeDealerPrice: 'Veja o preço do revendedor',
  seeds: 'sementes',
  select: 'Selecione',
  selectAccount: 'Selecione uma conta',
  selectAgencyMsg: 'Selecione Uma Agência Para Visualizar',
  selectAgencyReassignMsg: 'Selecione a agência à qual eles devem pertencer ou criar um novo',
  selectAgencyToBeginMsg: 'Para Começar, Selecione Uma Agência No Menu Suspenso Acima',
  selectAlertsToDismiss: 'Selecione Alertas para Dispensar',
  selectAlertsToSnooze: 'Selecione Alertas para Adiar',
  selectAll: 'Selecionar todas',
  selectAllOrNone: 'Selecionar todas ou nenhum',
  selectClus: 'Selecione CLUs',
  selectDiscountProgram: 'Selecione um programa de desconto',
  selected: 'selecionado',
  selectedField: 'Campo selecionado',
  selectExternalLab: 'Selecione um laboratório externo',
  selectField: 'Selecione um campo',
  selectedForAssignment: 'Selecionado para atribuição',
  selectFieldsToMerge:
    'Selecione limites de campo para mesclar que não tiveram planos de amostra anteriores.',
  selectFieldsToSplit:
    'Selecione limites de campo para dividir que não tiveram planos de amostra anteriores.',
  selectFileToUploadMsg: 'Selecione um arquivo para fazer upload',
  selectOne: 'Selecione um',
  selectProductCatalog: 'Selecionar catálogo de produtos',
  selectZoneDensity: 'Selecionar Densidade da Zona',
  selectZones: 'Selecione Zonas',
  selectZonesToMerge: 'Selecione zonas para mesclar.',
  selfSampling: 'Autoamostragem',
  selfScanning: 'Auto Digitalização',
  seller: 'Vendedor',
  sellingPrice: 'Preço de venda',
  sendCodeTo: 'Enviar código para',
  sendRequestInstructions: 'Envie instruções de redefinição',
  sendTo: 'Enviar para',
  sequencedData: 'Dados sequenciados',
  serverErrorMsg: 'Ocorreu um erro com o servidor, atualize e tente novamente.',
  setEqualAreaSplit: 'Definir Divisão de Area Igual',
  setEqualRanges: 'Definir Intervalos Iguais',
  setPassword: 'Configurar senha',
  setSamplingPoints: 'Definir pontos de amostragem',
  setSamplingZones: 'Definir zonas de amostragem',
  setSplitDensity: 'Definir densidade dividida',
  settings: 'Configurações',
  settled: 'Assentou',
  setupASamplePlan:
    'Para configurar um plano de amostra, você precisa mapear os limites do campo. Você pode fazer isso de várias maneiras:',
  setupZonesInstructionsMsg:
    'Configure zonas para análise biológica e gere pontos para análise de nutrientes e digitalização profissional',
  shapefile: 'Shapefile',
  shapefileDownloadMsg:
    'Isso pode levar 20 minutos ou mais. Sinta -se à vontade para sair da página. (O link aparecerá no download suspenso, quando disponível.)',
  shapefileDownloadSuccess: 'O download do Shapefile foi concluído com sucesso',
  shelf: 'Prateleira',
  shipmentCreatedMsg: 'Remessa criada com sucesso',
  shipmentUpdatedMsg: 'Remessa atualizada com sucesso',
  shipped: 'Enviado',
  shippedDate: 'Data de envio',
  shippedLabels: 'Etiquetas enviadas',
  shipping: 'Envio',
  shippingFrom: 'Frete from',
  shippingLabel: 'Etiqueta de remessa',
  shippingLabels: 'Etiquetas de remessa',
  shippingLabelsInProgress: 'Rótulos de remessa em andamento',
  shippingRegulatedSoil: 'Enviando solo regulamentado? Visualize ou baixe estes documentos',
  shippingTo: 'Enviando para',
  shotgun: 'Espingarda',
  shotgunAnalysis: 'Análise de espingarda',
  shotgunQpcr: 'Espingarda + qPCR',
  show: 'Mostre',
  showDcpPoints: 'Mostrar pontos DCP',
  showInUI: 'Mostrar na IU',
  showMe: 'Mostre-me',
  showPastPlans: 'Mostrar planos de amostra passados',
  showing: 'Mostrando',
  sidedress: 'Cobertura',
  signIn: 'Entrar',
  signInDifferentWay: 'Entrar de maneira diferente',
  signInWithPassword: 'Entrar com senha',
  signed: 'Assinado',
  signedAt: 'Assinado em',
  sikora: 'Sikora',
  silver: 'Prata',
  sla_at_risk: 'SLA em risco',
  sla_overdue: 'SLA vencido',
  smallDepositTodayMsg: 'Pequeno depósito hoje, restante conforme você conclui a análise de campo',
  snoozeAlerts: 'Suspender Alertas',
  soil: 'Solo',
  soilAnalysis: 'Análise de solo',
  soilAppliedProducts: 'Produtos Solo Aplicado',
  soilCollection: 'Coleta de Solo',
  soilCollectorOutput: 'Saída do SoilCollector',
  soilDataNotFound: 'Resultados de Teste Indisponíveis',
  soilDataNotFoundDescription:
    'A análise não foi concluída para este campo. Prescrições de remoção podem ser criadas com dados de rendimento ou imagens.',
  soilDilution: 'Diluição do solo',
  soilProcessing: 'Processamento de Solo',
  soilScan: 'Varredura do Solo',
  soilTest: 'Teste do solo',
  soilTexture: 'Textura do solo',
  soilTestValue: 'Valor do Teste de Solo',
  soilTextureAnalysis: 'Análise de textura do solo',
  soil_applied: 'Solo Aplicado',
  soil_appliedMissing:
    'As opções de proteção solo aplicado não foram adicionadas ao plano de cultivo. Clique para revisar',
  sorghum: 'Sorgo',
  sort: 'Ordenar',
  source: 'Fonte',
  sourcePlateBarcode: 'Código de barras da placa de origem',
  sourcePlates: 'Placas de origem',
  sourcePools: 'Pools de origem',
  soybeanFoliar: 'Soja foliar',
  soybeanPathogens: 'Pragas e doenças da soja',
  soybeanPlan: 'Plano de soja',
  soybeans: 'Soja',
  soybeansPlan: 'Plano de soja',
  split: 'Dividir',
  splitCore: 'Núcleo dividido',
  splitDensity: 'Densidade dividida',
  splitDensityBarcode: 'Código de barras de densidade dividida',
  splitDensityDesc:
    'A densidade dividida permite executar a análise biológica em uma densidade mais baixa do que a análise de nutrientes. O painel de pressão e o painel de desempenho serão incluídos para quaisquer zonas biológicas selecionadas.',
  splitDensityForBio: 'Densidade dividida para análise biológica + de nutrientes',
  splitDensityGroup: 'Grupo de Densidade Dividida',
  splitEveryZoneError: 'Não foi possível dividir todas as zonas.',
  splitField: 'Dividir campo',
  splitFields: 'Dividir campos',
  splitParentFieldAgain: 'Dividir campo pai novamente',
  splitZones: 'Zonas divididas',
  splitZonesSuccess: 'Zonas divididas com sucesso!',
  splits: 'Divisões',
  ssurgo: 'Tipo de solo (SSURGO)',
  ssurgoOmDisclaimer:
    'No caso de uma zona estar faltando um valor para a OM, será usada uma média ponderada do survório de Ssurgo Soil.',
  startedWhichOperation: 'Para começar, com qual operação estamos trabalhando?',
  starter: 'Iniciante',
  state: 'Estado',
  stateCounty: 'Estado / País',
  status: 'Status',
  sterilized: 'Esterilizado',
  storage: 'Armazenar',
  storePlate: 'Placa da loja',
  stratification: 'Estratificação',
  stratify: 'Estratificar',
  street: 'Rua',
  submit: 'Enviar',
  submitting: 'Envio',
  subscriptionCoversAnalysisMsg:
    'A assinatura cobre a análise da porcentagem desejada de fazenda solicitada. Se acres adicionais forem adicionados à conta, eles serão incluídos no Total de Acres da Fazenda e nos Acres de Análise correspondentes. Quaisquer acres ou análises adicionais solicitados acima e além da análise subscrita nos Acres de Análise incorrerão em encargos adicionais aos preços associados à Assinatura.',
  subscriptionRenewal: 'Renovação da assinatura',
  success: 'Sucesso',
  successCreatedShippingLabelBatch:
    'Criou com sucesso o lote de etiquetas de remessa. Seu download estará pronto em alguns minutos.',
  successfullyCreatedInvoice: 'Criou com sucesso a fatura no QuickBooks.',
  successfullyCreatedNoQB: 'Criou com sucesso a fatura sem enviá -la para o QuickBooks.',
  successfullyRemovedPrescription: 'Removido com sucesso prescrição',
  'sugar-beets': 'Beterraba',
  sugarBeetPestsDiseases: 'Pragas e doenças da beterraba',
  sugarBeets: 'Beterraba',
  sugar_beets: 'Beterraba',
  summary: 'Resumo',
  summaryOfSamplePlanMsg:
    'Este é um resumo do plano de amostra, conforme solicitado. A fatura final refletirá quaisquer ajustes em potencial.',
  summarySheet: 'Folha de resumo',
  superadmin: 'Super Admin',
  support: 'Apoiar',
  supportUnderTwoAcres:
    'Nosso sistema não suporta campos com menos de 0,9 hectares. Por favor, redimensione.',
  sureCancelPlan: 'Tem certeza de que deseja cancelar este plano?',
  sureMarkNotReady: "Tem certeza de que deseja marcar o plano 'como não concluído'?",
  surface: 'Superfície',
  switchUserToAgent: 'Mudar o usuário para agente',
  tags: 'Tag',
  tarSpot: 'Alcatrão',
  target: 'Alvo',
  targetBaseSaturation: 'Saturação Base Alvo',
  targetDilution: 'Diluição alvo',
  targetPhosphorus: 'Saturação Fósforo',
  targetPotassium: 'Saturação Potássio',
  targetYield: 'Produtividade alvo',
  tbd: 'TBD',
  tcRerun: 'TC Renum',
  technical: 'Técnico',
  temp: 'Temp',
  temperature: 'Temperatura',
  termsAndConditions: 'Termos e Condiçõess',
  territory: 'Território',
  territoryCreated: 'Território comercial salvo!',
  territoryDeletedMsg: 'Território excluído com sucesso!',
  territoryName: 'Nome do território',
  territorySavingErrMsg:
    'Houve um erro salvando o território. Por favor, atualize e tente novamente.',
  territoryUsers: 'Usuários de território',
  testResults: 'Resultados de teste',
  texture: 'Textura',
  textureAndPh: 'Textura e pH',
  texturePhSampleDetails: 'Detalhes da amostra de textura e pH',
  thanksForJoiningPatter: 'Obrigado por ingressar no padrão!',
  thatEmailHasAcctMsg:
    'Esse email já tem uma conta com o EarthOptics. Use um email diferente ou suporte de contato para que esse usuário mova para esta agência.',
  theTypicalMidwestFarm:
    'A fazenda típica <b> cidwest </b> trabalhando com padrão pode aumentar os lucros por </b>',
  thisFieldIsRequired: 'Este campo é obrigatório.',
  thisMayTakeSeconds: 'Isso pode levar vários segundos.',
  tillMapper: 'TillMapper',
  tillMapperActivated: 'TillMapper ativado com sucesso',
  tillMapperAvailable: 'Este campo tem o TillMapper disponível - ative-o abaixo para',
  tillMapperAvailableForField: 'TillMapper está disponível para este campo!',
  tillMapperDensityMsg: 'A densidade do TillMapper deve ser maior que 8 e menor que 10,5.',
  tillMapperIsLoading:
    'O TillMapper está carregando - volte em breve para ver seus resultados personalizados',
  tillMapperOrdered: 'Um pedido do TillMapper foi feito para este campo',
  tillMapperPoints: 'Pontos de TillMapper',
  till_rx_only: 'Apenas TillMapper',
  tillage: 'Lavoura',
  tillageCosts: 'O cultivo custa',
  tillage_map_generation: 'Geração de Mapa de Preparo',
  tillage_measurement: 'Medição de Preparo',
  timing: 'Tempo',
  to: 'Para',
  toBeSampledBy: 'Para ser amostrado por',
  toBeScannedBy: 'Para ser digitalizado por',
  toCollection: 'para coleção',
  toOrderFirstPlan: 'Para encomendar seu primeiro plano de amostra, clique',
  todaysDate: 'Data de hoje',
  toggleFieldSelection: 'Alternar seleção de campo',
  tooHighMsg: 'Muito alto',
  tooLowMsg: 'Muito baixo',
  tooManyLabelsMsg: 'Por favor, gerar menos de 80 rótulos de cada vez.',
  total: 'Total',
  totalAcres: 'Total de Hectares',
  totalAdjustments: 'Total de ajustes',
  totalApplied: 'Total aplicado',
  totalCarbon: 'Carbono Total',
  totalCarbonAnalysis: 'Análise de Carbono Total',
  totalCompletedSamples: 'Total de amostras concluídas',
  totalDna: 'DNA total',
  totalDnaMultiplier: 'Multiplicador total de DNA',
  totalFarm: 'Fazenda total',
  totalFarmAcres: 'Total de hectares da fazenda',
  totalFarmActivatedMsg: 'Total Farm ativado com sucesso!',
  totalFarmDismissedMsg: 'Total Farm dispensado com sucesso!',
  totalFarmCancelledMsg: 'Total Farm cancelado com sucesso!',
  totalFarmOperationMsg:
    'Fertility Rx, Seed Rx e planejamento de colheita em cada acre, todos os anos.',
  totalInorganicCarbon: 'Carbono Inorgânico Total',
  totalInorganicCarbonAnalysis: 'Análise de Carbono Inorgânico Total',
  totalOrders: 'Total de pedidos',
  totalOrganicCarbon: 'Carbono Orgânico Total',
  totalPcrDna: 'DNA total de PCR',
  totalPlannedSamples: 'Total de amostras planejadas',
  totalPrice: 'Preço Total',
  totalReads: 'Leituras totais',
  totalReturn: 'Retorno total',
  totalSamplesTaken: 'Total de amostras tomadas',
  totalScanCoverage: 'Cobertura Total de Digitalização',
  totals: 'Totais',
  trackPackage: 'Pacote de rastreamento',
  traits: 'Características',
  transactionApprovalMsg:
    'Ao aprovar essas transações, eles serão enviados para contabilizar o processamento.',
  transit: 'Transito',
  treatment: 'Tratamento',
  treatmentMissing:
    'As opções de tratamento de sementes não foram adicionadas ao plano de cultivo. Clique para revisar',
  triStateLimeTo6: 'Tri State Calcário para 6.0',
  triStateLimeTo65: 'Tri State Cal para 6.5',
  triStateLimeTo68: 'Tri State Calcário para 6.8',
  triStateOhLimeTo6: 'Tri State (OH) Calcário para 6.0',
  triStateOhLimeTo65: 'Tri State (OH) Calcário para 6.5',
  triStateOhLimeTo68: 'Tri State (OH) Calcário para 6.8',
  triStatePhosphorus: 'Tri State Fósforo',
  triStatePotassium: 'Tri State Potássio',
  turnOnTillMapper:
    "Ative o TillMapper hoje mesmo - visualize seus mapas de compactação e gere Rx's de cultivo personalizados",
  twoDayCollnWarnMsg:
    'As coletas para este campo foram feitas com mais de 48 horas de diferença. Isso pode impactar negativamente a qualidade do mapa se as condições de varredura foram inconsistentes. Considere refazer a varredura.',
  twoDayCollnWarnTitle: 'Período de coleta longo',
  twoWaysToUpload: 'Duas maneiras de fazer upload de limites e dados de campo',
  type: 'Tipo',
  unableGenerateZoneGroupings: 'Não é possível gerar agrupamentos de zona. Por favor atualize.',
  unableToConnectAgency: 'Não é possível conectar a agência. Por favor, tente novamente.',
  unableToDisconnectAgency: 'Não é possível desconectar a agência. Por favor, tente novamente.',
  unableToDisplayZoneGroupingError: 'Não é possível exibir agrupamentos de zona.',
  unableToLoadZonesMsg: 'Unable to load zones. Try again later.',
  unableToSavePlanMsg: 'Incapaz de salvar o plano. Por favor, atualize e tente novamente.',
  unassigned: 'Não atribuído',
  unassignedPlans: 'Planos não atribuídos',
  unassigned_all: 'Não atribuído - Todas',
  unassigned_eo: 'Não atribuído - EO',
  unassigned_pattern: 'Não atribuído - Pattern',
  unassigned_ready_to_sample: 'Não atribuído, pronto para provar',
  unavailable: 'Indisponível',
  uncheckAll: 'Desmarcar todas',
  unconfirmed: 'Não confirmado',
  understandCompaction: 'Entenda a compactação em nível de subcampo',
  understandCompactionPitch: "Gerar mapas de 10 milhões e Rx's personalizados",
  unit: 'Unidade',
  units: 'Unidades',
  unknown: 'desconhecido',
  upcomingCrop: 'Próxima Colheita',
  update: 'Atualizar',
  updateAddressFailMsg: 'Falha ao atualizar o endereço. Atualize e tente novamente.',
  updateConcentrationDataMsg:
    'Já existem dados de concentração associados a esta placa. Se você enviar um novo arquivo, ele substituirá os dados de concentração associados a cada poço. Você tem certeza que quer continuar?',
  updateData: 'Atualizar dados',
  updateFieldCropErrorMsg: 'Erro ao atualizar o plano de cultivo. Atualize e tente novamente.',
  updateFieldCropSuccessMsg: 'Plano de cultivo atualizado com sucesso!',
  updatePrescriptionErrorMsg: 'Erro ao atualizar prescrição. Atualize e tente novamente.',
  updatePrescriptionZoneErrorMsg:
    'Erro ao atualizar a zona de prescrição. Atualize e tente novamente.',
  updatePrescriptionZoneSuccessMsg: 'Zona de prescrição atualizada com sucesso!',
  updateUserInfoFailMsg:
    'Falha ao atualizar as informações de usuário. Atualize e tente novamente.',
  updated: 'Atualizada',
  updatedUserRole: 'Função de usuário atualizada!',
  updatingAgencyErrorMsg:
    'Houve um erro atualizando a agência. Por favor, atualize e tente novamente.',
  updatingAgencySuccessMsg: 'A agência foi atualizada com sucesso!',
  updatingAlertError: 'Ocorreu um erro ao atualizar o alerta',
  updatingUserRoleErrorMsg:
    'Houve um erro atualizando a função do usuário. Por favor, atualize e tente novamente.',
  upload: 'Carregar',
  uploadAbortedMsg: 'O upload do arquivo foi abortado.',
  uploadAnalyticImagery: 'Carregar Imagens Analíticas',
  uploadBoundaries: 'Carregar limites',
  uploadBulkPlate: 'Carregue a placa a granel',
  uploadCsv: 'Carregue o arquivo CSV',
  uploadDocument: 'Documento de upload',
  uploadErrorMsg: 'Houve um erro de carregamento de erro.',
  uploadFailedMsg: 'Falha no upload.',
  uploadFile: 'Carregar arquivo',
  uploadFileInstrs: 'Tipos de arquivos compatíveis: .json, .geojson, .zip',
  uploadLogo: 'Carregar Logotipo',
  uploadPoints: 'Carregar pontos',
  uploadProgress: 'Carregar progresso',
  uploadResults: 'Upload resultados',
  uploadSamples: 'Carregar amostras',
  uploadSamplesCsvMsg: 'Carregue poços de amostra do arquivo CSV',
  uploadShapefiles: 'Carreguefiles de Shapes',
  uploadShapefilesData: 'Carregar shapfiles ou dados de plantio / colheita',
  uploadShapefilesOfYourFields:
    'Faça o upload de shapfiles de seus campos aqui - certifique -se de incluir todos os arquivos (.shp, .shx, .dbf, etc/) e agrupe esses arquivos em um único arquivo zip',
  uploadSuccessMsg: 'Carregue bem -sucedido!',
  uploadZipFile: 'Faça o upload do arquivo zip',
  uploadZipTip: 'O arquivo carregado deve estar em um formato .zip ou .gz.',
  upload_zones: 'Zonas de upload',
  uploadedResults: 'Resultados enviados',
  uploading: 'Enviando',
  upsLabels: 'Etiquetas UPS',
  useImagery: 'Crie uma prescrição a partir de imagens',
  user: 'Do utilizador',
  userAgreements: 'Acordos de Usuário',
  userCreatedMsg: 'Usuário criado com sucesso!',
  userLocaleUpdated: 'Local do usuário atualizado!',
  userRoleUpdated: 'Função do usuário atualizada!',
  userScanningUpdated: 'Capacidade de digitalização do usuário atualizada!',
  userWillBeAssignedTip:
    "Nota: O usuário criado será atribuído como o 'agente principal' para esta agência, o que significa que suas informações de contato serão usadas para fins de cobrança.",
  users: 'Usuários',
  value: 'Valor',
  viewAdditionalResults: 'Ver resultados adicionais',
  viewAllAccounts: 'Veja todas as contas',
  viewBenefits: 'Ver benefícios',
  viewDemo: 'Demonstração',
  viewDemoAccount: 'Visualize conta de demonstração',
  viewDetails: 'Ver detalhes',
  viewEdit: 'Ver/Editar',
  viewEditLabel: 'Rótulo de visualização/edição',
  viewField: 'Ver campo',
  viewGates: 'Ver portões',
  viewMyAccounts: 'Veja minhas contas',
  viewMyReport: 'Ver meu relatório',
  viewNewField: 'Ver novo campo',
  viewOffer: 'Ver oferta',
  viewOrderSummary: 'Visualize o resumo do pedido',
  viewProject: 'Ver projeto',
  viewResults: 'Ver resultados',
  viewSampleReport: 'Ver relatório de amostra',
  viewSampleStatus: 'Ver status da amostra',
  viewSamples: 'Ver amostras',
  viewScanCoverage: 'Ver Cobertura da Digitalização',
  waiting: 'Esperando',
  'waiting to sample': 'Esperando para provar',
  waitingToSample: 'Esperando para provar',
  waitingToScan: 'Aguardando digitalização',
  webApp: 'aplicativo web',
  weighedAndGround: 'Pesado e terreno',
  weight: 'Peso',
  welcomeBack: 'Bem-vindo de volta',
  well: 'Bem',
  wells: 'Poços',
  wet: 'Molhada',
  whatAfterSplit: 'O que você gostaria de fazer depois de dividir o campo?',
  whatsTheNameOfAccount: 'Qual é o nome da conta agrícola?',
  whatsTheNameOfAccountTooltip: 'Isso pode ser um nome de fazenda, ou um indivíduo.',
  wheat: 'Trigo',
  whereSendInvoiceMsg: 'Informe-nos para onde devemos enviar as faturas pendentes desta conta',
  width: 'Largura',
  willReceiveLinkMsg: 'Você receberá um e -mail com um link para redefinir sua senha',
  with: 'Com',
  wontSample: 'Não vai amostrar',
  worms: 'Vermes',
  year: 'Ano',
  yes: 'Sim',
  yield: 'Rendimento',
  yieldData: 'Dados de Rendimento',
  yieldDataNotFound: 'Dados de Rendimento Não Encontrados',
  yieldDataNotFoundDescription: 'Aqui estão algumas opções:',
  yieldImpact: 'Rendimento de impacto',
  yieldMap: 'Mapa de Rendimento',
  yieldZones: 'Zonas de Rendimento',
  youHaveNoApprovedMsg:
    "Você não tem pagamentos 'aprovados'. Para visualizar pagamentos 'abertos', clique no seletor 'aberto' acima.",
  yourPasswordChanged: 'Sua senha foi atualizada!',
  zipcode: 'CEP',
  zone: 'Zona',
  zoneAnalysisDefaultBioMsg:
    'Defina as zonas que serão usadas para análise TruBio. Densidade alvo de 8 ha.',
  zoneAnalysisDefaultScanPointMsg:
    'Defina os pontos que serão utilizados para análise do TruNutrient. Densidade alvo de 2 ha.',
  zoneAnalysisTNTTScanPointMsg:
    'Defina os pontos que serão usados ​​para análise TruNutrient e TruTill. Densidade alvo de 2 ha.',
  zoneAnalysisTruTillScanPointMsg:
    'Defina os pontos que serão utilizados para análise TruTill. Densidade alvo de 2 ha.',
  zoneDetails: 'Detalhes da zona',
  zones: 'Zonas',
  zonesAtRisk: 'zonas em risco',
  zymo: 'Zymo',
  zymoDilution: 'Diluição de Zymo',
  zymoRevvityDescription: 'zymo lise e magbead com método TBD para quelar íons metálicos',
  zymoSilicaDescription: 'lise zymo e isolamento de filtro de sílica',
  zymoSoil: 'Solo zimo',
} as const;

export default PORTUGUESE_STRINGS;
